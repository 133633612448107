import React, { useEffect, useState, useMemo } from 'react';
import { Table, Button, Row, Col, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import TableHeader from '../components/TableHeader';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import {
  listMyOrders,
  listOrders,
  cancelOrder,
  cancelProductsOrderByEntrepreneur,
  deleteOrder,
} from '../actions/orderActions';
import { addDecimals } from '../utils/utils';

const ITEMS_PER_PAGE = 10;

const headersUser = [
  { name: 'Nº', field: 'orderNumber', sortable: true },
  { name: 'FECHA', field: 'createdAt', sortable: true },
  { name: 'TOTAL (Bs.)', field: 'totalPrice', sortable: true },
  { name: 'PAGADO', field: 'isPaid', sortable: false },
  { name: 'ENTREGADO', field: 'deliveredAt', sortable: false },
  { name: 'PROVEEDOR', field: 'provider', sortable: true },
  { name: 'ESTADO', field: 'state', sortable: false },
];

const headersEntrepreneur = [
  { name: 'Nº', field: 'orderNumber', sortable: true },
  { name: 'FECHA', field: 'createdAt', sortable: true },
  { name: 'TOTAL (Bs.)', field: 'totalPrice', sortable: true },
  { name: 'PAGADO', field: 'isPaid', sortable: false },
  { name: 'ENTREGADO', field: 'deliveredAt', sortable: false },
  { name: 'ESTADO', field: 'state', sortable: false },
  { name: 'CLIENTE', field: 'user', sortable: true },
];

const headersAdmin = [
  { name: 'Nº', field: 'orderNumber', sortable: true },
  { name: 'FECHA', field: 'createdAt', sortable: true },
  { name: 'TOTAL (Bs.)', field: 'totalPrice', sortable: true },
  { name: 'PAGADO', field: 'isPaid', sortable: false },
  { name: 'ENTREGADO', field: 'deliveredAt', sortable: false },
  { name: 'ESTADO', field: 'state', sortable: false },
  { name: 'CLIENTE', field: 'user', sortable: true },
  { name: 'PROVEEDOR', field: 'provider', sortable: true },
];

const styles = {
  isCanceled: {
    color: "red"
  },
  isCompleted: {
    color: "green"
  }
}

const OrderUserListScreen = ({ location, history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // Admin
  const orderList = useSelector((state) => state.orderList);
  const {
    loading: loadingOrderAdmin,
    error: errorOrderAdmin,
    orders: ordersAdmin,
  } = orderList;

  // User and Entrepreneur
  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  const orderCancel = useSelector((state) => state.orderCancel);
  const { loading: loadingCancel, success: successCancel } = orderCancel;

  const orderDelete = useSelector((state) => state.orderDelete);
  const { loading: loadingDelete, success: successDelete } = orderDelete;

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!userInfo) {
      return history.push('/login');
    }

    // Admin
    if (userInfo.isCoAdmin || userInfo.isAdmin) {
      dispatch(listOrders());
    } else {
      // Entrepreneur and user
      dispatch(listMyOrders());
    }
    // eslint-disable-next-line
  }, [dispatch, history, userInfo, successCancel, successDelete]);

  // Entrepreneur
  const itemsData = useMemo(() => {
    if (userInfo || userInfo?.isEntrepreneur) {
      let computedItems =
        (orders &&
          orders.map((o) => ({
            ...o,
            provider: o.provider?.name || '',
            user: o.user?.name || '',
            createdAt: moment(o.createdAt).format('DD/MM/YYYY') || '',
          }))) ||
        [];

      if (search) {
        computedItems = computedItems.filter(
          (comment) =>
            comment.orderNumber.toString().toLowerCase().includes(search.toLowerCase()) ||
            comment.createdAt.toLowerCase().includes(search.toLowerCase()) ||
            comment.user.toLowerCase().includes(search.toLowerCase()) ||
            comment.orderItems
              .map((item) => item.provider?.name)
              .join(' ').toLowerCase().includes(search.toLowerCase())
        );
      }

      setTotalItems(computedItems.length);

      // Sorting items
      if (
        sorting.field &&
        (sorting.field !== 'totalPrice' && sorting.field !== 'orderNumber' && sorting.field !== 'state')
      ) {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        computedItems = computedItems.sort(
          (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
      }

      // Sorting items numbers field
      if (
        sorting.field &&
        (sorting.field === 'totalPrice' || sorting.field === 'orderNumber')
      ) {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        if (reversed > 0) {
          computedItems = computedItems.sort((a, b) => {
            return a[sorting.field] - b[sorting.field];
          });
        } else {
          computedItems = computedItems.sort((a, b) => {
            return b[sorting.field] - a[sorting.field];
          });
        }
      }

      // Current Page slice
      return computedItems.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }
    // eslint-disable-next-line
  }, [orders, currentPage, search, sorting]);

  // Admin
  const itemsDataAdmin = useMemo(() => {
    if (userInfo?.isCoAdmin || userInfo?.isAdmin) {
      let computedItems =
        (ordersAdmin &&
          ordersAdmin.map((o) => ({
            ...o,
            user: o.user?.name || '',
            provider: o.provider?.name || '',
            createdAt: moment(o.createdAt).format('DD/MM/YYYY') || '',
          }))) || [];

      if (search) {
        computedItems = computedItems.filter(
          (comment) =>
            comment.orderNumber.toString().toLowerCase().includes(search.toLowerCase()) ||
            comment.createdAt.toLowerCase().includes(search.toLowerCase()) ||
            comment.user.toLowerCase().includes(search.toLowerCase()) ||
            comment.orderItems
              .map((item) => item.provider?.name)
              .join(' ').toLowerCase().includes(search.toLowerCase())
        );
      }

      setTotalItems(computedItems.length);

      // Sorting items
      if (
        sorting.field &&
        (sorting.field !== 'totalPrice' && sorting.field !== 'orderNumber' && sorting.field !== 'state')
      ) {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        computedItems = computedItems.sort(
          (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
      }

      // Sorting items numbers field
      if (
        sorting.field &&
        (sorting.field === 'totalPrice' || sorting.field === 'orderNumber')
      ) {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        if (reversed > 0) {
          computedItems = computedItems.sort((a, b) => {
            return a[sorting.field] - b[sorting.field];
          });
        } else {
          computedItems = computedItems.sort((a, b) => {
            return b[sorting.field] - a[sorting.field];
          });
        }
      }

      // Current Page slice
      return computedItems.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }
    // eslint-disable-next-line
  }, [ordersAdmin, currentPage, search, sorting]);

  const cancelOrderHandler = async (id) => {
    // Entrepreneur
    if (userInfo && !userInfo.isEntrepreneur && !userInfo.isAdmin) {
      if (
        window.confirm(
          'Esta acción cancelará todos los productos pendientes de entrega del pedido. ¿Esta seguro de cancelar?'
        )
      ) {
        dispatch(cancelOrder(id));
      }
    }

    // Entrepreneur
    if (userInfo.isEntrepreneur) {
      if (
        window.confirm(
          'Esta acción cancelará todos mis productos del pedido. ¿Esta seguro de cancelar?'
        )
      ) {
        dispatch(cancelProductsOrderByEntrepreneur(id));
      }
    }

    // Admin
    if (userInfo.isAdmin) {
      if (
        window.confirm(
          'Esta acción cancelará todos los productos pendientes de entrega del pedido. ¿Esta seguro de cancelar?'
        )
      ) {
        dispatch(cancelOrder(id));
      }
    }
  };

  const deleteOrderHandler = async (id) => {
    if (
      window.confirm(
        'Esta acción eliminará el registro de la base de datos. ¿Esta seguro de continuar?'
      )
    ) {
      await dispatch(deleteOrder(id));
    }
  };

  return (
    <Row>
      {/* User */}
      {userInfo &&
        !userInfo?.isAdmin &&
        !userInfo?.isCoAdmin &&
        !userInfo?.isEntrepreneur && (
          <Col md={12}>
            <h2>Mis Pedidos</h2>
            {loadingOrders || loadingCancel ? (
              <Loader />
            ) : errorOrders ? (
              <Message variant="danger">{errorOrders}</Message>
            ) : (
              <>
                <Row>
                  <Col>
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                      placeholder="Buscar Nº Orden, Fecha, Cliente, Proveedor"
                    />
                  </Col>
                </Row>
                <Table
                  striped
                  bordered
                  hover
                  responsive="sm"
                  className="table-sm mt-2"
                >
                  <TableHeader
                    headers={headersUser}
                    onSorting={(field, order) => {
                      setSorting({ field, order });
                    }}
                  />
                  <tbody>
                    {itemsData.map((order) => {
                      const provider = order.orderItems
                        .map(function (item) {
                          return item.provider?.name;
                        })
                        .join(',  ');

                      const qtyItems = order.orderItems.length

                      // Calculate total items delivery
                      const totalItemPaid = order.orderItems.reduce(
                        (prev, cur) => {
                          if (cur.isPaid) {
                            return prev + 1;
                          }
                          return prev;
                        },
                        0
                      );
                      const totalPaid = `${totalItemPaid}/${qtyItems}`;

                      // Calculate total items delivery
                      const totalItemDelivered = order.orderItems.reduce(
                        (prev, cur) => {
                          if (cur.isDelivered) {
                            return prev + 1;
                          }
                          return prev;
                        },
                        0
                      );
                      const totalDelivery = `${totalItemDelivered}/${qtyItems}`;

                      return (
                        <tr key={order._id}>
                          <td>{order.orderNumber}</td>
                          <td>{order.createdAt}</td>
                          <td>{addDecimals(order.totalPrice)}</td>
                          <td>{totalPaid}</td>
                          <td>{totalDelivery}</td>
                          <td>
                            {order.isCanceled ? (
                              <p style={styles.isCanceled}>
                                Cancelado
                              </p>
                            ) : order.isCompleted ? (
                              <p style={styles.isCompleted}>
                                Completado
                              </p>
                            ) : (
                              <p>En Proceso</p>
                            )}
                          </td>
                          <td>{provider || 'n/a'}</td>
                          <td>
                            {/* Details */}
                            <LinkContainer to={`/order/${order._id}`}>
                              <Button className="btn-sm" variant="light">
                                Detalles
                              </Button>
                            </LinkContainer>
                            {/* Cancel */}
                            <Button
                              disabled={order.isCompleted || order.isCanceled}
                              variant="danger"
                              className="btn-sm"
                              onClick={() => cancelOrderHandler(order._id)}
                            >
                              <i className="fas fa-ban"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Row>
                  <Col>
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        )}

      {/* Entrepreneur */}
      {userInfo?.isEntrepreneur && (
        <Col md={12}>
          <h2>Mis Pedidos</h2>
          {loadingOrders || loadingCancel || loadingDelete ? (
            <Loader />
          ) : errorOrders ? (
            <Message variant="danger">{errorOrders}</Message>
          ) : (
            <>
              <Row>
                <Col>
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                    placeholder="Buscar Nº Orden, Fecha, Cliente, Proveedor"
                  />
                </Col>
              </Row>
              <Table
                striped
                bordered
                hover
                responsive="sm"
                className="table-sm mt-2"
              >
                <TableHeader
                  headers={headersEntrepreneur}
                  onSorting={(field, order) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {itemsData.map((order) => {
                    // Calculate total items delivery
                    const qtyItems = order.orderItems.length

                    const totalItemPaid = order.orderItems.reduce(
                      (prev, cur) => {
                        if (cur.isPaid) {
                          return prev + 1;
                        }
                        return prev;
                      },
                      0
                    );
                    const totalPaid = `${totalItemPaid}/${qtyItems}`;

                    // Calculate total items delivery
                    const totalItemDelivered = order.orderItems.reduce(
                      (prev, cur) => {
                        if (cur.isDelivered) {
                          return prev + 1;
                        }
                        return prev;
                      },
                      0
                    );
                    const totalDelivery = `${totalItemDelivered}/${qtyItems}`;

                    return (
                      <tr key={order._id}>
                        <td>{order.orderNumber}</td>
                        <td>{order.createdAt}</td>
                        <td>{addDecimals(order.totalPrice)}</td>
                        <td>{totalPaid}</td>
                        <td>{totalDelivery}</td>
                        <td>
                          {order.isCanceled ? (
                            <p style={styles.isCanceled}>
                              Cancelado
                            </p>
                          ) : order.isCompleted ? (
                            <p style={styles.isCompleted}>
                              Completado
                            </p>
                          ) : (
                            <p>En Proceso</p>
                          )}
                        </td>
                        <td>{order.user || 'n/a'}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              variant="secondary"
                            >
                              Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {/* Details */}
                              <LinkContainer to={`/order/${order._id}`}>
                                <Dropdown.Item>
                                  <i className="fas fa-eye"></i> Detalles
                                </Dropdown.Item>
                              </LinkContainer>
                              {/* Cancel */}
                              <Dropdown.Item
                                onClick={() => cancelOrderHandler(order._id)}
                              >
                                <i className="fas fa-ban"></i> Cancelar
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Row>
                <Col>
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      )}

      {/* Admin */}
      {(userInfo?.isCoAdmin || userInfo?.isAdmin) && (
        <Col md={12}>
          <h2>Pedidos</h2>
          {loadingOrderAdmin || loadingCancel || loadingDelete ? (
            <Loader />
          ) : errorOrderAdmin ? (
            <Message variant="danger">{errorOrderAdmin}</Message>
          ) : (
            <>
              <Row>
                <Col>
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                    placeholder="Buscar Nº orden, Fecha, Cliente, Proveedor"
                  />
                </Col>
              </Row>
              <Table
                striped
                bordered
                hover
                responsive="sm"
                className="table-sm mt-2"
              >
                <TableHeader
                  headers={headersAdmin}
                  onSorting={(field, order) => {
                    setSorting({ field, order });
                  }}
                />
                <tbody>
                  {itemsDataAdmin.map((order, i) => {
                    const provider = order.orderItems
                      .map(function (item) {
                        return item.provider?.name;
                      })
                      .join(', ');

                    const qtyItems = order.orderItems.length

                    // Calculate total items delivery
                    const totalItemPaid = order.orderItems.reduce(
                      (prev, cur) => {
                        if (cur.isPaid) {
                          return prev + 1;
                        }
                        return prev;
                      },
                      0
                    );
                    const totalPaid = `${totalItemPaid}/${qtyItems}`;

                    // Calculate total items delivery
                    const totalItemDelivered = order.orderItems.reduce(
                      (prev, cur) => {
                        if (cur.isDelivered) {
                          return prev + 1;
                        }
                        return prev;
                      },
                      0
                    );
                    const totalDelivery = `${totalItemDelivered}/${qtyItems}`;
                    return (
                      <tr key={order._id}>
                        <td>{order.orderNumber}</td>
                        <td>{order.createdAt}</td>
                        <td>{addDecimals(order.totalPrice)}</td>
                        <td>{totalPaid}</td>
                        <td>{totalDelivery}</td>
                        <td>
                          {order.isCanceled ? (
                            <p style={styles.isCanceled}>
                              Cancelado
                            </p>
                          ) : order.isCompleted ? (
                            <p style={styles.isCompleted}>
                              Completado
                            </p>
                          ) : (
                            <p>En Proceso</p>
                          )}
                        </td>
                        <td>{order.user || 'n/a'}</td>
                        <td>{provider || 'n/a'}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              variant="secondary"
                            >
                              Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {/* Details */}
                              <LinkContainer to={`/order/${order._id}`}>
                                <Dropdown.Item>
                                  <i className="fas fa-eye"></i> Detalles
                                </Dropdown.Item>
                              </LinkContainer>
                              {/* Cancel */}
                              <Dropdown.Item
                                onClick={() => cancelOrderHandler(order._id)}
                              >
                                <i className="fas fa-ban"></i> Cancelar
                              </Dropdown.Item>
                              {/* Delete */}
                              {userInfo.isAdmin && (
                                <Dropdown.Item
                                  onClick={() => deleteOrderHandler(order._id)}
                                >
                                  <i className="fas fa-trash"></i> Eliminar
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Row>
                <Col>
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      )}
    </Row>
  );
};

export default OrderUserListScreen;
