import axios from 'axios';
import {
  PLACE_LIST_REQUEST,
  PLACE_LIST_SUCCESS,
  PLACE_LIST_FAIL,
  PLACE_DETAILS_REQUEST,
  PLACE_DETAILS_SUCCESS,
  PLACE_DETAILS_FAIL,
  PLACE_DELETE_REQUEST,
  PLACE_DELETE_SUCCESS,
  PLACE_DELETE_FAIL,
  PLACE_CREATE_REQUEST,
  PLACE_CREATE_SUCCESS,
  PLACE_CREATE_FAIL,
  PLACE_UPDATE_REQUEST,
  PLACE_UPDATE_SUCCESS,
  PLACE_UPDATE_FAIL,
} from '../constants/placeConstants';
import { handleErrorResponse } from './utils';

export const listPlaces = () => async (dispatch) => {
  try {
    dispatch({ type: PLACE_LIST_REQUEST });

    const { data } = await axios.get('/api/places');

    dispatch({ type: PLACE_LIST_SUCCESS, payload: data });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PLACE_LIST_FAIL,
      payload: error,
    });
  }
};

export const listPlaceDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PLACE_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/places/${id}`, config);

    dispatch({
      type: PLACE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PLACE_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const deletePlace = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PLACE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/places/${id}`, config);

    dispatch({
      type: PLACE_DELETE_SUCCESS,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PLACE_DELETE_FAIL,
      payload: error,
    });
  }
};

export const createPlace = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PLACE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/places`, formData, config);

    dispatch({
      type: PLACE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PLACE_CREATE_FAIL,
      payload: error,
    });
  }
};

export const updatePlace =
  (placeId, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PLACE_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/places/${placeId}`,
        formData,
        config
      );

      dispatch({
        type: PLACE_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: PLACE_DETAILS_SUCCESS, payload: data });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: PLACE_UPDATE_FAIL,
        payload: error,
      });
    }
  };
