import React, { useEffect, useState, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import TableHeader from '../components/TableHeader';
import { deleteTransport, listTransports } from '../actions/transportActions';

const ITEMS_PER_PAGE = 10;

const headers = [
  { name: 'NOMBRE', field: 'name', sortable: true },
  { name: 'TELEFONO', field: 'phone', sortable: false },
  { name: 'REGISTRO', field: 'createdAt', sortable: true },
];

const TransportListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const transportList = useSelector((state) => state.transportList);
  const { loading, error, transports } = transportList;

  const transportDelete = useSelector((state) => state.transportDelete);
  const { success: successDelete, error: errorDelete } = transportDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (userInfo && (userInfo.isCoAdmin || userInfo.isAdmin)) {
      dispatch(listTransports());
    } else {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [dispatch, history, successDelete, userInfo]);

  const itemsData = useMemo(() => {
    let computedItems = (transports && transports.map(t => ({
      ...t,
      createdAt: moment(t.createdAt).format('DD/MM/YYYY') || '',
    }))) || [];

    if (search) {
      computedItems = computedItems.filter(
        (comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.phone.toLowerCase().includes(search.toLowerCase()) ||
          comment.createdAt.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedItems.length);

    // Sorting items
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedItems = computedItems.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // Current Page slice
    return computedItems.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [transports, currentPage, search, sorting]);

  const deleteHandler = (transportId) => {
    if (
      window.confirm(
        'Esta acción eliminará las zonas y direcciones del servicio de transporte. ¿Esta seguro de continuar?'
      )
    ) {
      dispatch(deleteTransport(transportId));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error?.error ? (
        <Message className="mt-3" variant="danger">
          {error.error}
        </Message>
      ) : (
        <React.Fragment>
          <Row className="align-items-center">
            <Col>
              <h1>Servicio de Transporte</h1>
            </Col>
            {!error?.error && userInfo?.isAdmin && (
              <Col className="text-right">
                <Button
                  className="ms-auto"
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    history.push('/admin/transportlist/create');
                  }}
                >
                  <i className="fas fa-plus"></i> Crear Transporte
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Buscar Nombre, Teléfono, Fecha"
              />
            </Col>
          </Row>
          {errorDelete?.error && (
            <Message variant="danger" className="mt-3">
              {errorDelete?.error}
            </Message>
          )}
          <Table
            striped
            bordered
            hover
            responsive="sm"
            className="table-sm mt-2"
          >
            <TableHeader
              headers={headers}
              onSorting={(field, order) => {
                setSorting({ field, order });
              }}
            />
            <tbody>
              {itemsData.map((transport) => (
                <tr key={transport._id}>
                  <td>{transport.name}</td>
                  <td>{transport.phone}</td>
                  <td>{transport.createdAt}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" variant="secondary">
                        Opciones
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* Edit */}
                        {userInfo?.isAdmin && (
                          <LinkContainer
                            to={`/admin/transport/${transport._id}/edit`}
                            className="link a"
                          >
                            <Dropdown.Item>
                              <i className="fas fa-edit"></i> Editar
                            </Dropdown.Item>
                          </LinkContainer>
                        )}
                        {/* Zones */}
                        <LinkContainer
                          to={`/admin/transport/${transport._id}/zonelist`}
                        >
                          <Dropdown.Item>
                            <i className="fas fa-map"></i> Zonas
                          </Dropdown.Item>
                        </LinkContainer>
                        {/* Details */}
                        <LinkContainer
                          to={`/admin/transport/${transport._id}/details`}
                        >
                          <Dropdown.Item>
                            <i className="fas fa-eye"></i> Detalles
                          </Dropdown.Item>
                        </LinkContainer>
                        {/* Delete */}
                        {userInfo?.isAdmin && (
                          <Dropdown.Item
                            to={`#`}
                            onClick={() => deleteHandler(transport._id)}
                          >
                            <i className="fas fa-trash"></i> Eliminar
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
};

export default TransportListScreen;
