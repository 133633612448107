import React from 'react';
import { Form } from 'react-bootstrap';

const FormSelect = ({
  controlId,
  className,
  name,
  label,
  value,
  onChange,
  isInvalid,
  errorInvalid,
  disabled,
  options,
}) => {
  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        disabled={disabled}
        isInvalid={isInvalid}
        name={name}
        value={value}
        onChange={onChange}
      >
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
      {isInvalid && (
        <Form.Control.Feedback type="invalid">
          {errorInvalid}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

FormSelect.defaultProps = {
  type: 'text',
  className: 'mt-2',
  options: [],
};

export default FormSelect;
