import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroupPassword from '../components/FormGroupPassword';
import { login } from '../actions/userActions';

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormContainer>
      <h1>Iniciar Sesión</h1>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email" className="mt-2">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Ingrese email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <FormGroupPassword
          controlId="password"
          name="password"
          label="Contraseña"
          placeholder="Ingrese contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          showPassword={showPassword}
          handleShowPassword={handleShowPassword}
        />

        <Button type="submit" variant="primary" className="mt-3">
          Iniciar Sesión
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          No tienes cuenta?{' '}
          <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
            Registraté
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default LoginScreen;
