import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { createZone } from '../actions/transportActions';
import { ZONE_CREATE_RESET } from '../constants/transportConstants';

const CreateZoneScreen = ({ location, match, history }) => {
  const transportId = match.params.id;

  const [name, setName] = useState('');

  const dispatch = useDispatch();

  const zoneCreate = useSelector((state) => state.zoneCreate);
  const { loading, error: errorCreate, success: successCreate } = zoneCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if ((!userInfo || !userInfo?.isAdmin) && !transportId) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: ZONE_CREATE_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (successCreate) {
      history.push(`/admin/transport/${transportId}/zonelist`);
    }

    // eslint-disable-next-line
  }, [successCreate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm('¿Esta seguro de crear una nueva zona?')) {
      dispatch(
        createZone(transportId, {
          name,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Link
        to={`/admin/transport/${transportId}/zonelist`}
        className="btn btn-light"
      >
        Regresar
      </Link>
      <FormContainer>
        <h1>Crear Zona</h1>
        {errorCreate?.error && (
          <Message variant="danger">{errorCreate.error}</Message>
        )}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <p>Los campos * son requeridos</p>
          <FormGroup
            controlId="name"
            name="name"
            label="Nombre *"
            placeholder="Ingrese nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={Boolean(errorCreate?.name)}
            errorInvalid={errorCreate?.name}
            required
          />

          <Button type="submit" variant="primary" className="mt-3">
            Crear
          </Button>
        </Form>
      </FormContainer>
    </React.Fragment>
  );
};

export default CreateZoneScreen;
