export const TRANSPORT_LIST_REQUEST = 'TRANSPORT_LIST_REQUEST';
export const TRANSPORT_LIST_SUCCESS = 'TRANSPORT_LIST_SUCCESS';
export const TRANSPORT_LIST_FAIL = 'TRANSPORT_LIST_FAIL';
export const TRANSPORT_LIST_RESET = 'TRANSPORT_LIST_RESET';

export const TRANSPORT_DETAILS_REQUEST = 'TRANSPORT_DETAILS_REQUEST';
export const TRANSPORT_DETAILS_SUCCESS = 'TRANSPORT_DETAILS_SUCCESS';
export const TRANSPORT_DETAILS_FAIL = 'TRANSPORT_DETAILS_FAIL';
export const TRANSPORT_DETAILS_RESET = 'TRANSPORT_DETAILS_RESET';

export const TRANSPORT_DELETE_REQUEST = 'TRANSPORT_DELETE_REQUEST';
export const TRANSPORT_DELETE_SUCCESS = 'TRANSPORT_DELETE_SUCCESS';
export const TRANSPORT_DELETE_FAIL = 'TRANSPORT_DELETE_FAIL';
export const TRANSPORT_DELETE_RESET = 'TRANSPORT_DELETE_RESET';

export const TRANSPORT_CREATE_REQUEST = 'TRANSPORT_CREATE_REQUEST';
export const TRANSPORT_CREATE_SUCCESS = 'TRANSPORT_CREATE_SUCCESS';
export const TRANSPORT_CREATE_FAIL = 'TRANSPORT_CREATE_FAIL';
export const TRANSPORT_CREATE_RESET = 'TRANSPORT_CREATE_RESET';

export const TRANSPORT_UPDATE_REQUEST = 'TRANSPORT_UPDATE_REQUEST';
export const TRANSPORT_UPDATE_SUCCESS = 'TRANSPORT_UPDATE_SUCCESS';
export const TRANSPORT_UPDATE_FAIL = 'TRANSPORT_UPDATE_FAIL';
export const TRANSPORT_UPDATE_RESET = 'TRANSPORT_UPDATE_RESET';

export const ZONE_LIST_REQUEST = 'ZONE_LIST_REQUEST';
export const ZONE_LIST_SUCCESS = 'ZONE_LIST_SUCCESS';
export const ZONE_LIST_FAIL = 'ZONE_LIST_FAIL';
export const ZONE_LIST_RESET = 'ZONE_LIST_RESET';

export const ZONE_DETAILS_REQUEST = 'ZONE_DETAILS_REQUEST';
export const ZONE_DETAILS_SUCCESS = 'ZONE_DETAILS_SUCCESS';
export const ZONE_DETAILS_FAIL = 'ZONE_DETAILS_FAIL';
export const ZONE_DETAILS_RESET = 'ZONE_DETAILS_RESET';

export const ZONE_CREATE_REQUEST = 'ZONE_CREATE_REQUEST';
export const ZONE_CREATE_SUCCESS = 'ZONE_CREATE_SUCCESS';
export const ZONE_CREATE_FAIL = 'ZONE_CREATE_FAIL';
export const ZONE_CREATE_RESET = 'ZONE_CREATE_RESET';

export const ZONE_DELETE_REQUEST = 'ZONE_DELETE_REQUEST';
export const ZONE_DELETE_SUCCESS = 'ZONE_DELETE_SUCCESS';
export const ZONE_DELETE_FAIL = 'ZONE_DELETE_FAIL';
export const ZONE_DELETE_RESET = 'ZONE_DELETE_RESET';

export const ZONE_UPDATE_REQUEST = 'ZONE_UPDATE_REQUEST';
export const ZONE_UPDATE_SUCCESS = 'ZONE_UPDATE_SUCCESS';
export const ZONE_UPDATE_FAIL = 'ZONE_UPDATE_FAIL';
export const ZONE_UPDATE_RESET = 'ZONE_UPDATE_RESET';

export const ADDRESS_LIST_REQUEST = 'ADDRESS_LIST_REQUEST';
export const ADDRESS_LIST_SUCCESS = 'ADDRESS_LIST_SUCCESS';
export const ADDRESS_LIST_FAIL = 'ADDRESS_LIST_FAIL';
export const ADDRESS_LIST_RESET = 'ADDRESS_LIST_RESET';

export const ADDRESS_DETAILS_REQUEST = 'ADDRESS_DETAILS_REQUEST';
export const ADDRESS_DETAILS_SUCCESS = 'ADDRESS_DETAILS_SUCCESS';
export const ADDRESS_DETAILS_FAIL = 'ADDRESS_DETAILS_FAIL';
export const ADDRESS_DETAILS_RESET = 'ADDRESS_DETAILS_RESET';

export const ADDRESS_CREATE_REQUEST = 'ADDRESS_CREATE_REQUEST';
export const ADDRESS_CREATE_SUCCESS = 'ADDRESS_CREATE_SUCCESS';
export const ADDRESS_CREATE_FAIL = 'ADDRESS_CREATE_FAIL';
export const ADDRESS_CREATE_RESET = 'ADDRESS_CREATE_RESET';

export const ADDRESS_DELETE_REQUEST = 'ADDRESS_DELETE_REQUEST';
export const ADDRESS_DELETE_SUCCESS = 'ADDRESS_DELETE_SUCCESS';
export const ADDRESS_DELETE_FAIL = 'ADDRESS_DELETE_FAIL';
export const ADDRESS_DELETE_RESET = 'ADDRESS_DELETE_RESET';

export const ADDRESS_UPDATE_REQUEST = 'ADDRESS_UPDATE_REQUEST';
export const ADDRESS_UPDATE_SUCCESS = 'ADDRESS_UPDATE_SUCCESS';
export const ADDRESS_UPDATE_FAIL = 'ADDRESS_UPDATE_FAIL';
export const ADDRESS_UPDATE_RESET = 'ADDRESS_UPDATE_RESET';
