import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, InputGroup, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import FormFileGroup from '../components/FormFileGroup';
import { imageUpload } from '../config/constants';
import isImageInput from '../validations/imageInput';

import { getUserDetails, updateUser } from '../actions/userActions';
import {
  USER_UPDATE_RESET,
  USER_DETAILS_RESET,
} from '../constants/userConstants';

const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCoAdmin, setIsCoAdmin] = useState(false);
  const [isEntrepreneur, setIsEntrepreneur] = useState(false);
  const [phone, setPhone] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tikTok, setTikTok] = useState('');
  const [file, setFile] = useState('');
  const [filePreview, setFilePreview] = useState(false);
  const [image, setImage] = useState('');
  const [errorImage, setErrorImage] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  useEffect(() => {
    if (!userInfo || !(userInfo?.isCoAdmin || userInfo?.isAdmin)) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: USER_UPDATE_RESET });
      dispatch({ type: USER_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isCoAdmin || userInfo?.isAdmin) {
      if (successUpdate) {
        history.push('/admin/userlist');
      } else {
        if (user?._id !== userId) {
          dispatch(getUserDetails(userId));
        } else {
          setName(user.name);
          setEmail(user.email);
          setIsAdmin(user.isAdmin);
          setIsCoAdmin(user.isCoAdmin);
          setIsEntrepreneur(user.isEntrepreneur);
          setPhone(user.phone);
          setFacebook(user.facebook);
          setInstagram(user.instagram);
          setTikTok(user.tikTok);
          setImage(user.image);
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, userId, user, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    const formFile = new FormData();
    formFile.append('image', file);

    if (window.confirm('¿Esta seguro de actualizar los datos?')) {
      dispatch(
        updateUser({
          _id: userId,
          name,
          email,
          isAdmin,
          isCoAdmin,
          isEntrepreneur,
          phone,
          facebook,
          instagram,
          tikTok,
        }, formFile)
      );
    }
  };

  const handlePhone = (e) => {
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (pattern.test(e.target.value)) {
      setPhone(e.target.value);
    } else if (e.target.value !== '') {
      setPhone(phone);
    } else {
      setPhone('');
    }
  };

  const uploadFileHandler = async (e) => {
    const fileLoaded = e.target.files[0];

    // Validate input image
    if (fileLoaded) {
      // Compress image
      var options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      };

      imageCompression(fileLoaded, options)
        .then(function (compressedFile) {
          const { success, message } = isImageInput(compressedFile);
          if (!success) {
            setFilePreview(false);
            setFile('');
            return setErrorImage(message);
          }

          setFilePreview(URL.createObjectURL(compressedFile));
          setFile(compressedFile);
          setErrorImage('');
        })
        .catch(function (error) {
          setFilePreview(false);
          setFile('');
        });
    } else {
      setFilePreview(false);
      setFile('');
    }
  };

  const imgSrc = filePreview ? filePreview : image ? image : imageUpload;

  return (
    <>
      <Link to="/admin/userlist" className="btn btn-light my-3">
        Regresar
      </Link>
      <FormContainer>
        <h1>Editar Usuario</h1>
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate.error}</Message>
        )}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre *"
              placeholder="Ingrese nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />

            <FormGroup
              controlId="email"
              name="email"
              label="Email *"
              type="email"
              placeholder="Ingrese email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={Boolean(errorUpdate?.email)}
              errorInvalid={errorUpdate?.email}
              required
            />

            <Form.Group controlId="phone" className="mt-2">
              <Form.Label>Teléfono WhatsApp *</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={Boolean(errorUpdate?.phone)}
                  placeholder={'Ingrese teléfono'}
                  value={phone}
                  required
                  onChange={handlePhone}
                />
                {Boolean(errorUpdate?.phone) && (
                  <Form.Control.Feedback type="invalid">
                    {errorUpdate.phone}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>

            <FormGroup
              controlId="facebook"
              name="facebook"
              label="Facebook"
              placeholder="Ingrese url Facebook"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              isInvalid={Boolean(errorUpdate?.facebook)}
              errorInvalid={errorUpdate?.facebook}
            />

            <FormGroup
              controlId="instagram"
              name="instagram"
              label="Instagram"
              placeholder="Ingrese url Instagram"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              isInvalid={Boolean(errorUpdate?.instagram)}
              errorInvalid={errorUpdate?.instagram}
            />

            <FormGroup
              controlId="tikTok"
              name="tikTok"
              label="TikTok"
              placeholder="Ingrese url tikTok"
              value={tikTok}
              onChange={(e) => setTikTok(e.target.value)}
              isInvalid={Boolean(errorUpdate?.tikTok)}
              errorInvalid={errorUpdate?.tikTok}
            />

            <Form.Group controlId="isadmin" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Es Administrador"
                checked={isAdmin}
                disabled={!userInfo.isAdmin}
                onChange={(e) => {
                  if (userInfo.isAdmin) {
                    setIsAdmin(e.target.checked);
                  }
                }}
              ></Form.Check>
            </Form.Group>
            <Form.Group controlId="iscoadmin" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Es Coadministrador"
                checked={isCoAdmin}
                onChange={(e) => setIsCoAdmin(e.target.checked)}
              ></Form.Check>
            </Form.Group>
            <Form.Group controlId="isentrepreneur" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Es Emprendedor"
                checked={isEntrepreneur}
                onChange={(e) => setIsEntrepreneur(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Col md={12} className="d-flex justify-content-center mt-2">
              <Image src={imgSrc} alt={name} fluid width={230} height={230} />
            </Col>
            <div className="text-center">
              <small>Las dimensiones recomendadas son 500x500 px</small>
            </div>
            <FormFileGroup
              controlId="image"
              name="image"
              label="Logo"
              fileId="image-file"
              fileLabel="Seleccionar Archivo"
              onChangeFile={uploadFileHandler}
              isInvalid={Boolean(errorUpdate?.file) || Boolean(errorImage)}
              errorInvalid={errorUpdate?.file || errorImage}
              accept="image/*"
            />

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default UserEditScreen;
