import {
  MODAL_DISPLAY_WELCOME,
  MODAL_HIDE_WELCOME,
  RESET_MODAL_WELCOME,
} from '../constants/configConstants';

export const configModalReducer = (state = { modalWelcome: true }, action) => {
  switch (action.type) {
    case MODAL_DISPLAY_WELCOME:
    case MODAL_HIDE_WELCOME:
      return {
        ...state,
        modalWelcome: action.payload,
      };
    case RESET_MODAL_WELCOME:
      return {
        modalWelcome: true,
      };
    default:
      return state;
  }
};
