import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, ListGroup, Card, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Product from '../components/Product';
import Paginate from '../components/Paginate';
import { getUserProfileDetails } from '../actions/userActions';
import { USER_DETAILS_RESET } from '../constants/userConstants';
import { listProducts } from '../actions/productActions';

const ProfileUserScreen = ({ match, history }) => {
  const userId = match.params.id;

  const pageNumber = match.params.pageNumber || 1;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tikTok, setTikTok] = useState('');
  const [image, setImage] = useState('');

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const productList = useSelector((state) => state.productList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
    page,
    pages,
  } = productList;

  useEffect(() => {
    if (user?._id !== userId) {
      dispatch(getUserProfileDetails(userId));
    } else {
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
      setFacebook(user.facebook);
      setInstagram(user.instagram);
      setTikTok(user.tikTok);
      setImage(user.image);
    }

    // eslint-disable-next-line
  }, [dispatch, history, user]);

  useEffect(() => {
    if (userId && user?.isEntrepreneur) {
      dispatch(listProducts('', pageNumber, userId));
    }
    // eslint-disable-next-line
  }, [user, dispatch, pageNumber]);

  useEffect(() => {
    return () => {
      dispatch({
        type: USER_DETAILS_RESET,
      });
    };
    // eslint-disable-next-line
  }, []);

  const handleGoBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <>
      <Link to="#" className="btn btn-light my-3" onClick={handleGoBack}>
        Regresar
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message className="mt-3" variant="danger">
          {error}
        </Message>
      ) : (
        <>
          <Row>
            <Col md={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2>Información del Usuario</h2>
                  <strong>Nombre: </strong>
                  {name}
                </ListGroup.Item>

                <ListGroup.Item>
                  <strong>Email: </strong>
                  <a href={`mailto:${email}`}>{email}</a>
                </ListGroup.Item>

                <ListGroup.Item>
                  <strong>Teléfono de Contacto: </strong>
                  <a href={`tel:${phone}`}>{phone}</a>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Row>
                    <Col>
                      <Button
                        type="button"
                        variant="primary"
                        href={`tel:${phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-phone"></i> Llamar
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="button"
                        variant="success"
                        href={`https://wa.me/${phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-whatsapp"></i> Mensaje WhatsApp
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Logo</h2>
                    <div className='d-flex justify-content-center'>
                      <Image
                        src={image}
                        alt={name}
                        fluid
                        width={250}
                        height={250}
                        onError={(ev) => {
                          ev.target.src = '/images/sample.jpg';
                        }}
                      />
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                {(facebook || instagram || tikTok) && (
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>Redes Sociales</h2>
                    </ListGroup.Item>
                    {facebook && (
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <i className="fab fa-facebook"></i>{' '}
                            <a
                              href={facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Facebook
                            </a>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )}
                    {instagram && (
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <i className="fab fa-instagram"></i>{' '}
                            <a
                              href={instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Instagram
                            </a>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )}
                    {tikTok && (
                      <ListGroup.Item>
                        <Row>
                          <Col>
                            <i className="fab fa-tiktok"></i>{' '}
                            <a
                              href={tikTok}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              TikTok
                            </a>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                )}
              </Card>
            </Col>
          </Row>

          {/* Products */}
          {user?.isEntrepreneur && (
            <>
              <h1>Productos</h1>
              {loadingProduct ? (
                <Loader />
              ) : errorProduct ? (
                <Message variant="danger">{error}</Message>
              ) : (
                <>
                  <Row>
                    {products.map((product) => (
                      <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                        <Product product={product} />
                      </Col>
                    ))}
                  </Row>
                  <Paginate pages={pages} page={page} keyword={''} />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProfileUserScreen;
