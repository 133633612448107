import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { getUserDetails } from '../actions/userActions';
import { USER_DETAILS_RESET } from '../constants/userConstants';

const UserDetailsScreen = ({ match, history }) => {
  const userId = match.params.id;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCoAdmin, setIsCoAdmin] = useState(false);
  const [isEntrepreneur, setIsEntrepreneur] = useState(false);
  const [phone, setPhone] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tikTok, setTikTok] = useState('');
  const [image, setImage] = useState('');

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !(userInfo?.isCoAdmin || userInfo?.isAdmin)) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: USER_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isCoAdmin || userInfo?.isAdmin) {
      if (user?._id !== userId) {
        dispatch(getUserDetails(userId));
      } else {
        setName(user.name);
        setEmail(user.email);
        setCreatedAt(user.createdAt);
        setUpdatedAt(user.updatedAt);
        setIsAdmin(user.isAdmin || false);
        setIsCoAdmin(user.isCoAdmin || false);
        setIsEntrepreneur(user.isEntrepreneur || false);
        setPhone(user.phone);
        setFacebook(user.facebook);
        setInstagram(user.instagram);
        setTikTok(user.tikTok);
        setImage(user.image);
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, userId, user]);

  const handleGoBack = (e) => {
    e.preventDefault();
    history.push('/admin/userlist');
  };

  return (
    <>
      <Link to="#" className="btn btn-light my-3" onClick={handleGoBack}>
        Regresar
      </Link>
      <FormContainer>
        <h1>Detalles del Usuario</h1>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <React.Fragment>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre"
              value={name}
              readOnly
            />

            <FormGroup
              controlId="email"
              name="email"
              label="Email"
              value={email}
              readOnly
            />

            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de creación"
              value={moment(createdAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />

            <FormGroup
              controlId="updatedAt"
              name="updatedAt"
              label="Fecha de actualización"
              value={moment(updatedAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />

            <FormGroup
              controlId="phone"
              name="phone"
              label="Teléfono de Contacto WhatsApp"
              value={phone}
              readOnly
            />

            <FormGroup
              controlId="facebook"
              name="facebook"
              label="Facebook"
              value={facebook}
              readOnly
            />

            <FormGroup
              controlId="instagram"
              name="instagram"
              label="Instagram"
              value={instagram}
              readOnly
            />

            <FormGroup
              controlId="tikTok"
              name="tikTok"
              label="TikTok"
              value={tikTok}
              readOnly
            />

            <Form.Group controlId="isadmin" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Es Administrador"
                checked={isAdmin}
                readOnly
              ></Form.Check>
            </Form.Group>
            <Form.Group controlId="iscoadmin" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Es Coadministrador"
                checked={isCoAdmin}
                readOnly
              ></Form.Check>
            </Form.Group>
            <Form.Group controlId="isentrepreneur" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Es Emprendedor"
                checked={isEntrepreneur}
                readOnly
              ></Form.Check>
            </Form.Group>

            <Col md={12} className="d-flex mt-2 flex-column">
              <Form.Label>Logo</Form.Label>
              <div className='d-flex justify-content-center'>
                <Image
                  src={image}
                  alt={name}
                  fluid
                  width={250}
                  height={250}
                  onError={(ev) => {
                    ev.target.src = '/images/sample.jpg';
                  }}
                />
              </div>
            </Col>

            <Button
              type="button"
              variant="secondary"
              className="mt-3"
              onClick={handleGoBack}
            >
              Regresar
            </Button>
          </React.Fragment>
        )}
      </FormContainer>
    </>
  );
};

export default UserDetailsScreen;
