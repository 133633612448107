import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormGroup from '../components/FormGroup';
import FormSelect from '../components/FormSelect';
import FormFileGroup from '../components/FormFileGroup';
import FormTextAreaGroup from '../components/FormTextAreaGroup';
import FormContainer from '../components/FormContainer';
import isImageInput from '../validations/imageInput';
import { imageUpload } from '../config/constants';
import { listCategories } from '../actions/categoryActions';
import { listProductDetails, updateProduct } from '../actions/productActions';
import { CATEGORY_LIST_RESET } from '../constants/categoryConstants';
import {
  PRODUCT_UPDATE_RESET,
  PRODUCT_DETAILS_RESET,
} from '../constants/productConstants';

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id;

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [brand, setBrand] = useState('');
  const [image, setImage] = useState('');
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState('');
  const [dateOfProduction, setDateOfProduction] = useState('');
  const [benefit, setBenefit] = useState('');
  const [file, setFile] = useState('');
  const [filePreview, setFilePreview] = useState(false);
  const [categoryListOptions, setCategoryListOptions] = useState([
    {
      label: 'Seleccione una opción',
      value: '',
    },
  ]);
  const [errorImage, setErrorImage] = useState('');

  const dispatch = useDispatch();

  const categoryList = useSelector((state) => state.categoryList);
  const {
    loading: loadingCategory,
    categories,
    error: errorCategory,
  } = categoryList;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: CATEGORY_LIST_RESET });
      dispatch({ type: PRODUCT_UPDATE_RESET });
      dispatch({ type: PRODUCT_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (successUpdate) {
        history.push('/productlist');
      } else {
        if (product?._id !== productId) {
          dispatch(listCategories());
          dispatch(listProductDetails(productId));
        } else if (
          product.user?._id === userInfo._id ||
          userInfo.isCoAdmin ||
          userInfo.isAdmin
        ) {
          setName(product.name);
          setPrice(product.price);
          setHasDiscount(product.hasDiscount);
          setDiscountPercentage(product.discountPercentage);
          setImage(product.image);
          setBrand(product.brand);
          setCategory(product.category?._id || '');
          setDateOfProduction(product.dateOfProduction || '');
          setCountInStock(product.countInStock);
          setDescription(product.description);
          setBenefit(product.benefit || '');
        } else {
          return history.push('/login');
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, productId, product, successUpdate]);

  useEffect(() => {
    if (!loadingCategory && categories) {
      const items = categories.map((category) => ({
        label: category.name,
        value: category._id,
      }));

      setCategoryListOptions([
        {
          label: 'Seleccione una opción',
          value: '',
        },
        ...items,
      ]);
    }

    // eslint-enable-next-line
  }, [loadingCategory, categories]);

  const uploadFileHandler = async (e) => {
    const fileLoaded = e.target.files[0];

    // Validate input image
    if (fileLoaded) {
      // Compress image
      var options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      };

      imageCompression(fileLoaded, options)
        .then(function (compressedFile) {
          const { success, message } = isImageInput(compressedFile);
          if (!success) {
            setFilePreview(false);
            setFile('');
            return setErrorImage(message);
          }

          setFilePreview(URL.createObjectURL(compressedFile));
          setFile(compressedFile);
          setErrorImage('');
        })
        .catch(function (error) {
          setFilePreview(false);
          setFile('');
        });
    } else {
      setFilePreview(false);
      setFile('');
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formFile = new FormData();
    formFile.append('image', file);

    if (window.confirm('¿Esta seguro de actualizar los datos del producto?')) {
      dispatch(
        updateProduct(
          productId,
          {
            name,
            price,
            hasDiscount,
            discountPercentage,
            brand,
            category,
            description,
            countInStock,
            dateOfProduction,
            benefit
          },
          formFile
        )
      );
    }
  };

  const imgSrc = filePreview ? filePreview : image ? image : imageUpload;

  return (
    <>
      <Link to="/productlist" className="btn btn-light my-3">
        Regresar
      </Link>
      <FormContainer>
        <h1>Editar Producto</h1>
        {errorCategory?.error && (
          <Message variant="danger">{errorCategory.error}</Message>
        )}
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate.error}</Message>
        )}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre *"
              placeholder="Ingrese nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />

            <FormGroup
              controlId="price"
              name="price"
              label="Precio (Bs.) *"
              type="number"
              placeholder="Ingrese precio en bolivianos"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              isInvalid={Boolean(errorUpdate?.price)}
              errorInvalid={errorUpdate?.price}
              required
            />

            <Form.Group controlId="hasDiscount" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Precio con descuento aplicado"
                checked={hasDiscount}
                onChange={(e) => {
                  setHasDiscount(e.target.checked)
                  setDiscountPercentage(0)
                }}
              ></Form.Check>
            </Form.Group>

            <FormGroup
              type="number"
              disabled={!hasDiscount}
              controlId="discountPercentage"
              label="Porcentaje (%)"
              name="discountPercentage"
              placeholder="Ingrese número porcentaje de descuento"
              value={discountPercentage}
              onChange={(e) => setDiscountPercentage(e.target.value)}
              isInvalid={Boolean(error?.discountPercentage)}
              errorInvalid={error?.discountPercentage}
            />

            <FormGroup
              controlId="brand"
              label="Marca *"
              name="brand"
              placeholder="Ingrese marca"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              isInvalid={Boolean(errorUpdate?.brand)}
              errorInvalid={errorUpdate?.brand}
              required
            />

            <FormGroup
              controlId="countInStock"
              name="countInStock"
              type="number"
              label="Unidades de existencia *"
              placeholder="Ingrese unidades de existencia"
              value={countInStock}
              onChange={(e) => setCountInStock(e.target.value)}
              isInvalid={Boolean(errorUpdate?.countInStock)}
              errorInvalid={errorUpdate?.countInStock}
              required
            />

            <FormSelect
              controlId="category"
              name="category"
              label="Categoría *"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              isInvalid={Boolean(error?.category)}
              errorInvalid={error?.category}
              options={categoryListOptions}
              required
            />

            <FormTextAreaGroup
              controlId="description"
              label="Descripción *"
              name="description"
              placeholder="Ingrese descripción, fechas de producción, etc."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              isInvalid={Boolean(errorUpdate?.description)}
              errorInvalid={errorUpdate?.description}
              required
            />

            <FormGroup
              controlId="dateOfProduction"
              label="Meses de disponibilidad"
              name="dateOfProduction"
              placeholder="Ingrese meses de disponibilidad"
              value={dateOfProduction}
              onChange={(e) => setDateOfProduction(e.target.value)}
              isInvalid={Boolean(error?.dateOfProduction)}
              errorInvalid={error?.dateOfProduction}
            />

            <FormTextAreaGroup
              controlId="benefit"
              label="Beneficios"
              name="benefit"
              placeholder="Ej. Ayuda contra el cáncer, presion alta, colesterol, diabetis, etc"
              value={benefit}
              onChange={(e) => setBenefit(e.target.value)}
              isInvalid={Boolean(error?.benefit)}
              errorInvalid={error?.benefit}
            />

            <Col md={12} className="d-flex justify-content-center mt-2">
              <Image src={imgSrc} alt={name} fluid width={230} height={230} />
            </Col>
            <div className="text-center">
              <small>Las dimensiones recomendadas son 500x500 px</small>
            </div>
            <FormFileGroup
              controlId="image"
              name="image"
              label="Imagen"
              fileId="image-file"
              fileLabel="Seleccionar Archivo"
              onChangeFile={uploadFileHandler}
              isInvalid={Boolean(errorUpdate?.file) || Boolean(errorImage)}
              errorInvalid={errorUpdate?.file || errorImage}
              accept="image/*"
            />

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
