import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { updatePlace, listPlaceDetails } from '../actions/placeActions';
import {
  PLACE_DETAILS_RESET,
  PLACE_UPDATE_RESET,
} from '../constants/placeConstants';

const PlaceEditScreen = ({ match, history }) => {
  const placeId = match.params.id;

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [coords, setCoords] = useState({
    latitude: '',
    longitude: '',
  });

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const placeDetails = useSelector((state) => state.placeDetails);
  const { loading, error, place } = placeDetails;

  const placeUpdate = useSelector((state) => state.placeUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = placeUpdate;

  useEffect(() => {
    if (!userInfo || !userInfo?.isAdmin) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: PLACE_UPDATE_RESET });
      dispatch({ type: PLACE_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isAdmin) {
      if (successUpdate) {
        history.push('/admin/placelist');
      } else {
        if (place?._id !== placeId) {
          dispatch(listPlaceDetails(placeId));
        } else {
          setName(place.name);
          setAddress(place.address);
          setCoords({
            latitude: place.coords.latitude,
            longitude: place.coords.longitude,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, placeId, place, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm('¿Esta seguro de actualizar los datos?')) {
      dispatch(
        updatePlace(placeId, {
          name,
          address,
          coords,
        })
      );
    }
  };

  const handleLatitude = (e) => {
    const value = e.target.value;
    const pattern = new RegExp(/^[-0-9.\b]+$/);

    if (pattern.test(value)) {
      setCoords({ ...coords, latitude: e.target.value });
    } else if (e.target.value !== '') {
      setCoords({ ...coords, latitude: coords.latitude });
    } else {
      setCoords({ ...coords, latitude: '' });
    }
  };

  const handleLongitude = (e) => {
    const value = e.target.value;
    const pattern = new RegExp(/^[-0-9.\b]+$/);

    if (pattern.test(value)) {
      setCoords({ ...coords, longitude: e.target.value });
    } else if (e.target.value !== '') {
      setCoords({ ...coords, longitude: coords.longitude });
    } else {
      setCoords({ ...coords, longitude: '' });
    }
  };

  return (
    <React.Fragment>
      <Link to="/admin/placelist" className="btn btn-light">
        Regresar
      </Link>
      <FormContainer>
        <h1>Actualizar Lugar de Entrega</h1>
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate.error}</Message>
        )}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error?.error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre *"
              placeholder="Ingrese nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />
            <FormGroup
              controlId="address"
              name="address"
              label="Dirección *"
              placeholder="Ingrese dirección Barrio, Av, Nº"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              isInvalid={Boolean(errorUpdate?.address)}
              errorInvalid={errorUpdate?.address}
              required
            />
            <FormGroup
              controlId="latitude"
              name="latitude"
              label="Latitud *"
              placeholder="Ingrese latitud"
              value={coords.latitude}
              onChange={handleLatitude}
              isInvalid={Boolean(errorUpdate?.coords?.latitude)}
              errorInvalid={errorUpdate?.coords?.latitude}
              required
            />
            <FormGroup
              controlId="address"
              name="address"
              label="Longitud *"
              placeholder="Ingrese longitud"
              value={coords.longitude}
              onChange={handleLongitude}
              isInvalid={Boolean(errorUpdate?.coords?.longitude)}
              errorInvalid={errorUpdate?.coords?.longitude}
              required
            />

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default PlaceEditScreen;
