import React, { useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import { savePaymentMethod } from '../actions/cartActions';

const PaymentScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  // Validate if Step 2 complete
  if (
    (shippingAddress.deliveryMethod ===
      process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1 &&
      (!shippingAddress.transport?._id ||
        !shippingAddress.transport?.zone?._id ||
        !shippingAddress.transport?.zone?.address?._id)) ||
    (shippingAddress.deliveryMethod ===
      process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2 &&
      !shippingAddress.place?._id)
  ) {
    history.push('/shipping');
  }

  const [paymentMethod, setPaymentMethod] = useState('Pago en efectivo');

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    history.push('/placeorder');
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />
      <h1>Método de Pago</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className="mt-2">
          <Form.Label as="legend">Seleccione Método</Form.Label>
          <Col>
            <Form.Check
              type="radio"
              label="Pago en efectivo"
              id="cashPayment"
              name="paymentMethod"
              value="Pago en efectivo"
              checked
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Button type="submit" variant="primary" className="mt-3">
          Continuar
        </Button>
      </Form>
    </FormContainer>
  );
};

export default PaymentScreen;
