import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import {
  getOrderDetails,
  payProductOrder,
  deliverProductOrder,
  cancelProductOrder,
} from '../actions/orderActions';
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
  ORDER_CANCEL_RESET,
} from '../constants/orderConstants';
import { addDecimals } from '../utils/utils';

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id;

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const orderCancel = useSelector((state) => state.orderCancel);
  const { loading: loadingCancel, success: successCancel } = orderCancel;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    if (order) {
      order.itemsPrice = addDecimals(
        order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
      );
      order.itemsPrice = addDecimals(order.itemsPrice);
      order.shippingPrice = addDecimals(order.shippingPrice);
      order.totalPrice = (
        Number(order.itemsPrice) + Number(order.shippingPrice)
      ).toFixed(2);
    }
  }

  // Get order initial when products update delivered and paid
  useEffect(() => {
    dispatch(getOrderDetails(orderId));

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }

    if (
      !order ||
      successPay ||
      successDeliver ||
      successCancel ||
      order._id !== orderId
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch({ type: ORDER_CANCEL_RESET });
      dispatch(getOrderDetails(orderId));
    }

    // eslint-disable-next-line
  }, [dispatch, orderId, successPay, successDeliver, successCancel, order]);

  const deliverProductHandler = (productId) => {
    if (
      window.confirm(
        '¿Esta seguro de marcar como entregado?. Esta acción no se puede deshacer'
      )
    ) {
      dispatch(deliverProductOrder(order, productId));
    }
  };

  const paymentProductHandler = (productId) => {
    if (
      window.confirm(
        '¿Esta seguro de marcar como pagado?. Esta acción no se puede deshacer'
      )
    ) {
      const paymentResult = {
        id: order._id,
        status: true,
        update_time: Date.now(),
      };
      dispatch(payProductOrder(orderId, productId, paymentResult));
    }
  };

  const cancelProductOrderHandler = (productId) => {
    if (window.confirm('¿Esta seguro de cancelar el producto?')) {
      dispatch(cancelProductOrder(orderId, productId));
    }
  };

  const navigateProfileUser = (e, userId) => {
    e.preventDefault();
    history.push(`/user/${userId}/profile`, {
      id: userId,
    });
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <Link to="#" className="btn btn-light" onClick={() => history.goBack()}>
        Regresar
      </Link>
      <Link to="#" className="btn btn-light ms-3" onClick={() => history.push('/')}>
        <i className="fas fa-home"></i> Ir a Inicio
      </Link>
      <h1>Pedido Nº {order.orderNumber}</h1>
      {order.isCanceled && (
        <Message variant="danger" className="mt-2">
          La orden se encuentra cancelado
        </Message>
      )}
      {order.isCompleted && (
        <Message variant="success" className="mt-2">
          La orden se encuentra completada
        </Message>
      )}
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Envio</h2>
              <p>
                <strong>Nombre: </strong>
                {order.user ? (
                  <a
                    href="/#"
                    onClick={(e) => navigateProfileUser(e, order.user._id)}
                  >
                    {order.user.name}
                  </a>
                ) : (
                  'n/a'
                )}
              </p>
              <p>
                <strong>Email: </strong>{' '}
                {order.user ? (
                  <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                ) : (
                  'n/a'
                )}
              </p>
              {order.shippingAddress.deliveryMethod ===
                process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1 && (
                  <>
                    <p>
                      <strong>Dirección: </strong>
                      {order.shippingAddress.transportDelivery.address}
                      {order.shippingAddress.transportDelivery.addressAdditional
                        ? `, ${order.shippingAddress.transportDelivery.addressAdditional}, `
                        : ', '}
                      {order.shippingAddress.transportDelivery.zone}
                    </p>
                    <p>
                      <strong>Servicio de Delivery: </strong>
                      {order.shippingAddress.transportDelivery.transport?.name ||
                        'n/a'}
                    </p>
                  </>
                )}
              {order.shippingAddress.deliveryMethod ===
                process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2 && (
                  <>
                    {order.shippingAddress.place ? (
                      <p>
                        <strong>Lugar de entrega: </strong>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${order.shippingAddress.place?.coords?.latitude},${order.shippingAddress.place?.coords?.longitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {order.shippingAddress.place?.name}
                        </a>
                      </p>
                    ) : (
                      <p>
                        <strong>Lugar de entrega: </strong>
                        {order.shippingAddress.place?.name || 'n/a'}
                      </p>
                    )}
                    <p>
                      <strong>Dirección: </strong>
                      {order.shippingAddress.place?.address || 'n/a'}
                    </p>
                  </>
                )}
              <p>
                <strong>Teléfono: </strong>{' '}
                {order.user ? (
                  <a href={`tel:${order.user.phone}`}>{order.user.phone}</a>
                ) : (
                  'n/a'
                )}
              </p>
              {(userInfo?.isAdmin ||
                userInfo?.isCoAdmin ||
                userInfo?.isEntrepreneur) &&
                order.user && (
                  <Row>
                    <Col>
                      <Button
                        type="button"
                        variant="primary"
                        href={`tel:${order.user?.phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-phone"></i> Llamar Cliente
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="button"
                        variant="success"
                        href={`https://wa.me/${order.user?.phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-whatsapp"></i> WhatsApp Cliente
                      </Button>
                    </Col>
                  </Row>
                )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Método de Pago</h2>
              <p>
                <strong>Método: </strong>
                {order.paymentMethod}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Pedidos de Productos</h2>
              {order.orderItems.length === 0 ? (
                <Message>Pedido está vacío</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            onError={(ev) => {
                              ev.target.src = '/images/sample.jpg';
                            }}
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          {item.product ? (
                            <Link to={`/product/${item.product?._id}`}>
                              {item.name}
                            </Link>
                          ) : (
                            <p>{item.name}</p>
                          )}
                        </Col>
                        <Col md={4}>
                          {item.qty} x Bs. {addDecimals(item.price)} = Bs.{' '}
                          {addDecimals(item.qty * item.price)}
                        </Col>
                      </Row>
                      {item.product?.user?._id && (
                        <Row>
                          <Col className="mt-2">
                            Proveedor:{' '}
                            <a
                              href="/#"
                              onClick={(e) =>
                                navigateProfileUser(e, item.product.user._id)
                              }
                            >
                              {item.product.user.name}
                            </a>
                          </Col>
                        </Row>
                      )}

                      {(userInfo?.isAdmin ||
                        userInfo?.isCoAdmin ||
                        userInfo?._id === order.user?._id) &&
                        item.provider && (
                          <Row>
                            <Col xs={12} md={6} className="mt-2">
                              <Button
                                type="button"
                                variant="primary"
                                href={`tel:${item.provider?.phone}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-phone"></i> Llamar
                                Proveedor
                              </Button>
                            </Col>
                            <Col xs={12} md={6} className="mt-2">
                              <Button
                                type="button"
                                variant="success"
                                href={`https://wa.me/${item.provider?.phone}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fab fa-whatsapp"></i> WhatsApp
                                Proveedor
                              </Button>
                            </Col>
                          </Row>
                        )}

                      {item.product && (
                        <Row className="mt-2">
                          {(userInfo?.isAdmin ||
                            userInfo?.isCoAdmin ||
                            userInfo?._id === item.provider?._id) &&
                            !item.isDelivered &&
                            !item.isCanceled && (
                              <Col xs={12} md={4} className="mt-2">
                                <Button
                                  type="button"
                                  className="btn d-sm-btn-sm"
                                  onClick={(e) =>
                                    deliverProductHandler(item.product?._id)
                                  }
                                >
                                  Marcar como entregado
                                </Button>
                              </Col>
                            )}
                          {(userInfo?.isAdmin ||
                            userInfo?.isCoAdmin ||
                            userInfo?._id === item.provider?._id) &&
                            !item.isCanceled &&
                            !item.isPaid && (
                              <Col xs={12} md={4} className="mt-2">
                                <Button
                                  type="button"
                                  className="btn d-sm-btn-sm"
                                  onClick={(e) =>
                                    paymentProductHandler(item.product?._id)
                                  }
                                >
                                  Marcar como pagado
                                </Button>
                              </Col>
                            )}
                          {(userInfo?._id === item.user?._id ||
                            userInfo?._id === item.provider?._id ||
                            userInfo?.isAdmin ||
                            userInfo?.isCoAdmin) &&
                            // user can cancel item
                            ((!item.isDelivered &&
                              !item.isPaid &&
                              !item.isCanceled) ||
                              // if is admin, coAdmin, provider and item deliver or paid not are true
                              (((!item.isDelivered && item.isPaid) ||
                                (item.isDelivered && !item.isPaid)) &&
                                !item.isCanceled &&
                                (userInfo?.isAdmin ||
                                  userInfo?.isCoAdmin ||
                                  userInfo?._id === item.provider?._id))) && (
                              <Col xs={12} md={4} className="mt-2">
                                <Button
                                  variant="danger"
                                  type="button"
                                  className="btn d-sm-btn-sm"
                                  onClick={(e) =>
                                    cancelProductOrderHandler(item.product?._id)
                                  }
                                >
                                  Cancelar Pedido
                                </Button>
                              </Col>
                            )}
                        </Row>
                      )}
                      {item.isCanceled && (
                        <Row>
                          <Message variant="danger" className="mt-2">
                            El producto se encuentra cancelado
                          </Message>
                        </Row>
                      )}
                      <Row>
                        {item.isDelivered ? (
                          <Message variant="success" className="mt-2">
                            Entregado el{' '}
                            {moment(item.deliveredAt).format(
                              'DD [de] MMMM [de] YYYY'
                            )}
                          </Message>
                        ) : (
                          <Message variant="danger" className="mt-2">
                            No Entregado
                          </Message>
                        )}
                      </Row>
                      <Row>
                        {item.isPaid ? (
                          <Message variant="success">
                            Pagado el{' '}
                            {moment(item.paidAt).format(
                              'DD [de] MMMM [de] YYYY'
                            )}
                          </Message>
                        ) : (
                          <Message variant="danger">No Pagado</Message>
                        )}
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Resumen del Pedido</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Productos</Col>
                  <Col>Bs. {order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Envio</Col>
                  <Col>Bs. {order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>Bs. {order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {(loadingDeliver || loadingCancel) && <Loader />}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
