import React from 'react';
import FormContainer from '../components/FormContainer';

const styles = {
  logoLeftHeader: {
    height: 30,
  },
  logoRightHeader: {
    height: 50,
  },
  text: {
    textAlign: 'justify',
    textJustify: 'inter-word',
  },
  title: {
    textAlign: 'center',
  },
  logo: {
    cursor: 'pointer'
  },
  logoContainer: {
    width: 150,
    height: "100%"
  }
};

const AboutScreen = ({ location, history }) => {
  return (
    <FormContainer>
      <h2>Acerca de </h2>

      <div className="d-flex justify-content-between">
        <div style={styles.logoLeftHeader}>
          <img
            src="/images/logo-misereor.jpeg"
            className="img-fluid mh-100"
            alt="misereor"
          ></img>
        </div>
        <div style={styles.logoRightHeader}>
          <img
            src="/images/logo-cetha.jpeg"
            className="img-fluid mh-100"
            alt="cetha"
          ></img>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div
          style={{
            height: 200,
          }}
        >
          <img
            src="/images/logo.png"
            className="img-fluid mh-100"
            alt="logo-emborozu-ecologico"
          ></img>
        </div>
      </div>

      <h2 style={styles.title} className="text-primary">
        EMBOROZÚ ECOLÓGICO - ECOVENTAS
      </h2>
      <p style={styles.text}>
        Es una herramienta de comercialización del proyecto "dinamización del
        emprendimiento productivo agroecológico con equidad de género vinculado
        al consumidor" desarrollada por el CETHA Emborozú financiado por MISEREOR
        con la finalidad apoyar los emprendimientos agroecológicos de
        productores de comunidades del municipio de Padcaya y de otros
        municipios a través de alianzas con organizaciones de productores
        ecológicos que son jóvenes y sus familias que están relacionadas con el
        Instituto Tecnológico Emborozú, Educación de Personas Jóvenes y Adultas, EDUPER,
        así también asociaciones como: Asociación Bien te fue, SPG-APAT;
        espacios de comercialización Tienda Alma Tierra, vinculado a
        consumidores como Comida Consciente e instituciones de respaldo como son
        los miembros de la Plataforma de suelos regional Tarija y el Movimiento
        Agroecológico Boliviano regional Tarija.
      </p>
      <p style={styles.text}>
        Bienvenido a una alternativa de promoción y difusión de la producción
        ecológica del campo a la mesa!!!
      </p>
      <div className='d-flex justify-content-between'>
        {/* Logo 1 */}
        <div style={styles.logoContainer}>
          <img
            src={'/images/alma-tierra.jpg'}
            alt={"Alma Tierra"}
            width="100%"
            style={styles.logo}
            onClick={e => window.open('https://www.facebook.com/almatierratarija')}
          />
        </div>
        {/* Logo 2 */}
        <div style={styles.logoContainer}>
          <img
            src={'/images/renacc.jpg'}
            alt={"RENACC-Tarija"}
            width="100%"
            style={styles.logo}
            onClick={e => window.open('https://renacctarija.jimdofree.com')}
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default AboutScreen;
