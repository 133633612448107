import React, { useState } from 'react';

const Search = ({ onSearch, placeholder }) => {
  const [search, setSearch] = useState('');

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };

  return (
    <input
      type="text"
      className="form-control"
      style={{ width: '300px' }}
      placeholder={placeholder}
      value={search}
      onChange={(e) => onInputChange(e.target.value)}
    />
  );
};

Search.defaultProps = {
  placeholder: 'Buscar',
};

export default Search;
