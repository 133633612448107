import React from 'react';
import { Form } from 'react-bootstrap';

const FormGroup = ({
  controlId,
  className,
  name,
  label,
  type,
  placeholder,
  value,
  onChange,
  isInvalid,
  errorInvalid,
  readOnly,
  disabled,
  required,
}) => {
  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        disabled={disabled}
        isInvalid={isInvalid}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        required={required}
        onChange={onChange}
        readOnly={readOnly}
      />
      {isInvalid && (
        <Form.Control.Feedback type="invalid">
          {errorInvalid}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

FormGroup.defaultProps = {
  type: 'text',
  className: 'mt-2',
};

export default FormGroup;
