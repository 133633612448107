import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { listZoneDetails } from '../actions/transportActions';
import { ZONE_DETAILS_RESET } from '../constants/transportConstants';

const ZoneDetailsScreen = ({ match, history }) => {
  const transportId = match.params.id;
  const zoneId = match.params.zoneId;

  const [name, setName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [user, setUser] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const zoneDetails = useSelector((state) => state.zoneDetails);
  const { loading, error, zone } = zoneDetails;

  useEffect(() => {
    if (
      !userInfo ||
      (!(userInfo?.isCoAdmin || userInfo?.isAdmin) && !transportId && !zoneId)
    ) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: ZONE_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isCoAdmin || userInfo?.isAdmin) {
      if (zone?._id !== zoneId) {
        dispatch(listZoneDetails(transportId, zoneId));
      } else {
        setName(zone.name);
        setCreatedAt(zone.createdAt);
        setUpdatedAt(zone.updatedAt);
        setUser(zone.user?.name);
      }
    }

    // eslint-disable-next-line
  }, [dispatch, history, transportId, zone, zoneId]);

  const handleGoBack = (e) => {
    e.preventDefault();
    history.push(`/admin/transport/${transportId}/zonelist`);
  };

  return (
    <React.Fragment>
      <Link to="#" className="btn btn-light" onClick={handleGoBack}>
        Regresar
      </Link>
      <FormContainer>
        <h1>Detalles de la Zona</h1>
        {loading ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre"
              value={name}
              readOnly
            />

            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de creación"
              value={moment(createdAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de actualización"
              value={moment(updatedAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="user"
              name="user"
              label="Registro"
              value={user}
              readOnly
            />

            <Button
              type="button"
              variant="secondary"
              className="mt-3"
              onClick={handleGoBack}
            >
              Regresar
            </Button>
          </>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default ZoneDetailsScreen;
