import axios from 'axios';
import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPLOAD_FILE_FAIL
} from '../constants/categoryConstants';
import { handleErrorResponse } from './utils';

export const listCategories = (pageNumber = "", options = {}) => async (dispatch) => {
  const { enablePagination = false } = options
  try {
    dispatch({ type: CATEGORY_LIST_REQUEST });

    const { data } = await axios.get(`/api/categories?pageNumber=${pageNumber}&enablePagination=${enablePagination}`);

    dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload: error,
    });
  }
};

export const listCategoryDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/categories/${id}`, config);

    dispatch({
      type: CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: CATEGORY_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const deleteCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/categories/${id}`, config);

    dispatch({
      type: CATEGORY_DELETE_SUCCESS,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: CATEGORY_DELETE_FAIL,
      payload: error,
    });
  }
};

export const createCategory = (formData, formFile) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/categories`, formData, config);

    if (formFile.get('image') instanceof File && data?._id) {
      await dispatch(uploadFileServer(formFile, data._id));
    }

    dispatch({
      type: CATEGORY_CREATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: CATEGORY_CREATE_FAIL,
      payload: error,
    });
  }
};

export const updateCategory =
  (categoryId, formData, formFile) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CATEGORY_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/categories/${categoryId}`,
        formData,
        config
      );

      if (formFile.get('image') instanceof File && data?._id) {
        await dispatch(uploadFileServer(formFile, data._id));
      }

      dispatch({
        type: CATEGORY_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: CATEGORY_DETAILS_SUCCESS, payload: data });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: CATEGORY_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const uploadFileServer =
  (fileData, categoryId) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `/api/upload/categories/${categoryId}/image`,
        fileData,
        config
      );

      return true;
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: CATEGORY_UPLOAD_FILE_FAIL,
        payload: error,
      });
    }
  };