import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const styles = {
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    height: 150,
    maxWidth: 230,
    display: "flex"
  },
};

const Category = ({ category }) => {
  return (
    <Card className="my-3 p-3 rounded">
      <Link to={`/category/${category._id}`}>
        <div style={styles.imageContainer}>
          <div style={styles.image}>
            <Card.Img
              src={category.image}
              variant="top"
              className="img-responsive mh-100"
              onError={(ev) => {
                ev.target.src = '/images/sample.jpg';
              }}
            />
          </div>
        </div>
      </Link>

      <Card.Body>
        <Link to={`/category/${category._id}`}>
          <Card.Title as="div">
            <strong>{category.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as="div">{category.qtyProducts} Productos</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Category;
