import React, { useEffect, useState, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import TableHeader from '../components/TableHeader';
import { deleteCategory, listCategories } from '../actions/categoryActions';

const ITEMS_PER_PAGE = 10;

const headers = [
  { name: 'NOMBRE', field: 'name', sortable: true },
  { name: 'REGISTRO', field: 'createdAt', sortable: true },
];

const CategoryListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const categoryDelete = useSelector((state) => state.categoryDelete);
  const { success: successDelete, error: errorDelete } = categoryDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (userInfo && (userInfo.isCoAdmin || userInfo.isAdmin)) {
      dispatch(listCategories());
    } else {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [dispatch, history, successDelete, userInfo]);

  const itemsData = useMemo(() => {
    let computedItems = (categories && categories.map(c => ({
      ...c,
      createdAt: moment(c.createdAt).format('DD/MM/YYYY') || '',
    }))) || [];

    if (search) {
      computedItems = computedItems.filter((comment) =>
        comment.name.toLowerCase().includes(search.toLowerCase()) ||
        comment.createdAt.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedItems.length);

    // Sorting items
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedItems = computedItems.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // Current Page slice
    return computedItems.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [categories, currentPage, search, sorting]);

  const deleteHandler = (categoryId) => {
    if (window.confirm('¿Esta seguro de eliminar la categoria?')) {
      dispatch(deleteCategory(categoryId));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error?.error ? (
        <Message className="mt-3" variant="danger">
          {error?.error}
        </Message>
      ) : (
        <React.Fragment>
          <Row className="align-items-center">
            <Col>
              <h1>Categorías</h1>
            </Col>
            {!error?.error && userInfo?.isAdmin && (
              <Col className="text-right">
                <Button
                  className="ms-auto"
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    history.push('/admin/categorylist/create');
                  }}
                >
                  <i className="fas fa-plus"></i> Crear Categoría
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Buscar Nombre, Fecha"
              />
            </Col>
          </Row>
          {errorDelete?.error && (
            <Message variant="danger" className="mt-3">
              {errorDelete?.error}
            </Message>
          )}
          <Table
            striped
            bordered
            hover
            responsive="sm"
            className="table-sm mt-2"
          >
            <TableHeader
              headers={headers}
              onSorting={(field, order) => {
                setSorting({ field, order });
              }}
            />
            <tbody>
              {itemsData.map((category) => (
                <tr key={category._id}>
                  <td>{category.name}</td>
                  <td>{category.createdAt}</td>
                  <td>
                    {/* Edit */}
                    {userInfo?.isAdmin && (
                      <LinkContainer
                        to={`/admin/category/${category._id}/edit`}
                      >
                        <Button variant="light" className="btn-sm">
                          <i className="fas fa-edit"></i>
                        </Button>
                      </LinkContainer>
                    )}
                    {/* Details */}
                    <LinkContainer
                      to={`/admin/category/${category._id}/details`}
                    >
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-eye"></i>
                      </Button>
                    </LinkContainer>
                    {/* Delete */}
                    {userInfo?.isAdmin && (
                      <LinkContainer
                        to={`#`}
                        onClick={() => deleteHandler(category._id)}
                      >
                        <Button variant="danger" className="btn-sm">
                          <i className="fas fa-trash"></i>
                        </Button>
                      </LinkContainer>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
};

export default CategoryListScreen;
