import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col
} from 'react-bootstrap';

import Meta from '../components/Meta';
import Message from '../components/Message';
import Product from '../components/Product';
import Loader from '../components/Loader';
import PaginateCategory from '../components/PaginateCategory';
import {
  listCategoryDetails,
} from '../actions/categoryActions';
import { listProductsByCategory } from '../actions/productActions';

const CategoryScreen = ({ history, match }) => {
  const categoryId = match.params.id;

  const pageNumber = match.params.pageNumber || 1;
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const { category } = categoryDetails;

  useEffect(() => {
    dispatch(listCategoryDetails(categoryId));
    dispatch(listProductsByCategory(pageNumber, categoryId));
    // eslint-disable-next-line
  }, [dispatch, categoryId, pageNumber]);

  return (
    <>
      <Meta title={category?.name} />
      <Link
        className="btn btn-light my-3"
        to="#"
        onClick={() => history.goBack()}
      >
        Regresar
      </Link>
      {loading ? (
        <Loader />
      ) : error?.error ? (
        <Message className="mt-3" variant="danger">
          {error.error}
        </Message>
      ) : (
        <>
          <Row>
            <h1>{category?.name || ''}</h1>
            {loading ? (
              <Loader />
            ) : error?.error ? (
              <Message variant="danger">{error.error}</Message>
            ) : (
              <>
                {products.length === 0 ? (
                  <p>Lo sentimos no encontramos productos para esta categoría, intente buscar en otras categorías.</p>
                ) : (
                  <>
                    <Row>
                      {products.map((product) => (
                        <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                          <Product product={product} />
                        </Col>
                      ))}
                    </Row>
                    <PaginateCategory
                      pages={pages}
                      page={page}
                      id={categoryId}
                    />
                  </>
                )}
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default CategoryScreen;
