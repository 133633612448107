import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import FormGroupPassword from '../components/FormGroupPassword';
import { registerFromAdmin } from '../actions/userActions';
import { USER_REGISTER_FROM_ADMIN_RESET } from '../constants/userConstants';

const CreateUserScreen = ({ location, history }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isCoAdmin, setIsCoAdmin] = useState(false);
  const [isEntrepreneur, setIsEntrepreneur] = useState(false);
  const [phone, setPhone] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tikTok, setTikTok] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error: errorCreate, success: successCreate } = userRegister;

  useEffect(() => {
    if (!userInfo || !(userInfo?.isCoAdmin || userInfo?.isAdmin)) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: USER_REGISTER_FROM_ADMIN_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (successCreate) {
      history.push('/admin/userlist');
    }
    // eslint-disable-next-line
  }, [successCreate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm('¿Esta seguro de crear nuevo usuario?')) {
      dispatch(
        registerFromAdmin({
          name,
          email,
          password,
          isEntrepreneur,
          isCoAdmin,
          phone: `591${phone}`,
          facebook,
          instagram,
          tikTok,
        })
      );
    }
  };

  const handlePhone = (e) => {
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (pattern.test(e.target.value)) {
      setPhone(e.target.value);
    } else if (e.target.value !== '') {
      setPhone(phone);
    } else {
      setPhone('');
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <React.Fragment>
      <Link to="/admin/userlist" className="btn btn-light">
        Regresar
      </Link>
      <FormContainer>
        <h1>Crear Usuario</h1>
        {errorCreate?.error && (
          <Message variant="danger">{errorCreate.error}</Message>
        )}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <p>Los campos * son requeridos</p>
          <FormGroup
            controlId="name"
            name="name"
            label="Nombre *"
            placeholder="Ingrese nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={Boolean(errorCreate?.name)}
            errorInvalid={errorCreate?.name}
            required
          />

          <FormGroup
            controlId="email"
            name="email"
            label="Email *"
            type="email"
            placeholder="Ingrese email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={Boolean(errorCreate?.email)}
            errorInvalid={errorCreate?.email}
            required
          />

          <FormGroupPassword
            controlId="password"
            name="password"
            label="Contraseña *"
            type="password"
            placeholder="Ingrese contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            isInvalid={Boolean(errorCreate?.password)}
            errorInvalid={errorCreate?.password}
            showPassword={showPassword}
            handleShowPassword={handleShowPassword}
            required
          />

          <Form.Group controlId="phone" className="mt-2">
            <Form.Label>Teléfono WhatsApp *</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">591</InputGroup.Text>
              <Form.Control
                isInvalid={Boolean(errorCreate?.phone)}
                placeholder={'Ingrese teléfono'}
                value={phone}
                required
                onChange={handlePhone}
              />
              {Boolean(errorCreate?.phone) && (
                <Form.Control.Feedback type="invalid">
                  {errorCreate.phone}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>

          <FormGroup
            controlId="facebook"
            name="facebook"
            label="Facebook"
            placeholder="Ingrese url Facebook"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
            isInvalid={Boolean(errorCreate?.facebook)}
            errorInvalid={errorCreate?.facebook}
          />

          <FormGroup
            controlId="instagram"
            name="instagram"
            label="Instagram"
            placeholder="Ingrese url Instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
            isInvalid={Boolean(errorCreate?.instagram)}
            errorInvalid={errorCreate?.instagram}
          />

          <FormGroup
            controlId="tikTok"
            name="tikTok"
            label="TikTok"
            placeholder="Ingrese url tikTok"
            value={tikTok}
            onChange={(e) => setTikTok(e.target.value)}
            isInvalid={Boolean(errorCreate?.tikTok)}
            errorInvalid={errorCreate?.tikTok}
          />

          <Form.Group controlId="iscoadmin" className="mt-2">
            <Form.Check
              type="checkbox"
              label="Es Coadministrador"
              checked={isCoAdmin}
              onChange={(e) => setIsCoAdmin(e.target.checked)}
            />
          </Form.Group>
          <Form.Group controlId="isentrepreneur" className="mt-2">
            <Form.Check
              type="checkbox"
              label="Es Emprendedor"
              checked={isEntrepreneur}
              onChange={(e) => setIsEntrepreneur(e.target.checked)}
            />
          </Form.Group>

          <Button type="submit" variant="primary" className="mt-3">
            Crear
          </Button>
        </Form>
      </FormContainer>
    </React.Fragment>
  );
};

export default CreateUserScreen;
