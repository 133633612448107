import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from 'react-bootstrap';

import Message from '../components/Message';
import {
  addToCart,
  removeFromCart,
  removeAllItemsFromCart,
} from '../actions/cartActions';
import { addDecimals } from '../utils/utils'

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id;

  const qty = location.search ? Number(location.search.split('=')[1]) : 1;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const emptyCartHandler = () => {
    if (window.confirm('¿Esta seguro de vaciar el carrito de compras?')) {
      dispatch(removeAllItemsFromCart());
    }
  };

  const checkoutHandler = () => {
    history.push('/login?redirect=shipping');
  };

  const navigateProfileUser = (e, userId) => {
    e.preventDefault();
    history.push(`/user/${userId}/profile`, {
      id: userId,
    });
  };

  return (
    <Row>
      <Col md={8}>
        <h1>Carrito de Compras</h1>
        {cartItems.length === 0 ? (
          <Message>
            Tu carrito esta vacío <Link to="/">Regresar</Link>
          </Message>
        ) : (
          <>
            <Message>
              Puede contactarse con los proveedores para reservar fechas de
              producción de los productos. Acceda al link en el nombre del
              proveedor para ver detalles de contacto.
            </Message>

            <ListGroup variant="flush" className="mt-3">
              {cartItems.map((item, i) => (
                <ListGroup.Item key={i}>
                  <Row>
                    <Col md={2}>
                      <Image
                        src={item.image}
                        alt={item.name}
                        fluid
                        rounded
                        onError={(ev) => {
                          ev.target.src = '/images/sample.jpg';
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <Link to={`/product/${item.product?._id}`}>
                        {item.name}
                      </Link>
                    </Col>
                    <Col md={2}>Bs. {addDecimals(item.price)}</Col>
                    <Col md={2}>
                      <Form.Control
                        as="select"
                        value={item.qty}
                        onChange={(e) =>
                          dispatch(
                            addToCart(item.product?._id, Number(e.target.value))
                          )
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col md={2}>
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeFromCartHandler(item.product?._id)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                  {item.product?.user?._id && (
                    <Row>
                      <Col className="mt-2">
                        Proveedor:{' '}
                        <a
                          href="/#"
                          onClick={(e) =>
                            navigateProfileUser(e, item.product.user._id)
                          }
                        >
                          {item.product.user.name}
                        </a>
                      </Col>
                    </Row>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}

        {cartItems.length > 0 && (
          <ListGroup
            variant="flush"
            className="mt-3 d-flex justify-content-center align-items-center"
          >
            <ListGroup.Item>
              <Col className='d-flex justify-content-center gap-3'>
                <Button
                  type="button"
                  variant="info"
                  className="btn-block"
                  disabled={cartItems.length === 0}
                  onClick={emptyCartHandler}
                >
                  <i className="fas fa-trash"></i> Vaciar carrito
                </Button>
                <Button
                  type="button"
                  variant='secondary'
                  className="btn-block"
                  onClick={() => history.push('/')}
                >
                  <i className="fas fa-shopping-cart"></i> Seguir comprando
                </Button>
              </Col>
            </ListGroup.Item>
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>
                Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                items
              </h2>
              Bs.{' '}
              {cartItems
                .reduce((acc, item) => acc + item.qty * item.price, 0)
                .toFixed(2)}
            </ListGroup.Item>
            <ListGroup.Item>
              <Button
                type="button"
                className="btn-block mt-3"
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                Pasar a caja
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default CartScreen;
