import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { updateZone, listZoneDetails } from '../actions/transportActions';
import {
  ZONE_DETAILS_RESET,
  ZONE_UPDATE_RESET,
} from '../constants/transportConstants';

const ZoneEditScreen = ({ match, history }) => {
  const transportId = match.params.id;
  const zoneId = match.params.zoneId;

  const [name, setName] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const zoneDetails = useSelector((state) => state.zoneDetails);
  const { loading, error, zone } = zoneDetails;

  const zoneUpdate = useSelector((state) => state.zoneUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = zoneUpdate;

  useEffect(() => {
    if ((!userInfo || !userInfo?.isAdmin) && !transportId && !zoneId) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: ZONE_UPDATE_RESET });
      dispatch({ type: ZONE_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isAdmin) {
      if (successUpdate) {
        history.push(`/admin/transport/${transportId}/zonelist`);
      } else {
        if (zone?._id !== zoneId) {
          dispatch(listZoneDetails(transportId, zoneId));
        } else {
          setName(zone.name);
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, transportId, zoneId, zone, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm('¿Esta seguro de actualizar los datos?')) {
      dispatch(
        updateZone(transportId, zoneId, {
          name,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Link
        to={`/admin/transport/${transportId}/zonelist`}
        className="btn btn-light"
      >
        Regresar
      </Link>
      <FormContainer>
        <h1>Actualizar Zona</h1>
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate.error}</Message>
        )}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre *"
              placeholder="Ingrese nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default ZoneEditScreen;
