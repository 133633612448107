import React from 'react';
import { Form } from 'react-bootstrap';

const FormTextAreaGroup = ({
  controlId,
  className,
  name,
  label,
  placeholder,
  value,
  onChange,
  isInvalid,
  errorInvalid,
  rows,
  readOnly,
}) => {
  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        isInvalid={isInvalid}
        name={name}
        as="textarea"
        placeholder={placeholder}
        value={value}
        rows={rows}
        onChange={onChange}
        readOnly={readOnly}
      />
      {isInvalid && (
        <Form.Control.Feedback type="invalid">
          {errorInvalid}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

FormTextAreaGroup.defaultProps = {
  className: 'mt-2',
  rows: '4',
};

export default FormTextAreaGroup;
