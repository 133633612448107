import React, { useEffect, useState, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import TableHeader from '../components/TableHeader';
import { deletePlace, listPlaces } from '../actions/placeActions';

const ITEMS_PER_PAGE = 10;

const headers = [
  { name: 'NOMBRE', field: 'name', sortable: true },
  { name: 'DIRECCIÓN', field: 'address', sortable: true },
  { name: 'COORDENADAS', field: 'coords', sortable: false },
];

const PlaceListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const placeList = useSelector((state) => state.placeList);
  const { loading, error, places } = placeList;

  const placeDelete = useSelector((state) => state.placeDelete);
  const { success: successDelete, error: errorDelete } = placeDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (userInfo && (userInfo.isCoAdmin || userInfo.isAdmin)) {
      dispatch(listPlaces());
    } else {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [dispatch, history, successDelete, userInfo]);

  const itemsData = useMemo(() => {
    let computedItems = places || [];

    if (search) {
      computedItems = computedItems.filter(
        (comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.address.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedItems.length);

    // Sorting items
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedItems = computedItems.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // Current Page slice
    return computedItems.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [places, currentPage, search, sorting]);

  const deleteHandler = (placeId) => {
    if (window.confirm('¿Esta seguro de eliminar el lugar de entrega?')) {
      dispatch(deletePlace(placeId));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <React.Fragment>
          <Row className="align-items-center">
            <Col>
              <h1>Lugares de Entrega</h1>
            </Col>
            {!error && userInfo.isAdmin && (
              <Col className="text-right">
                <Button
                  className="ms-auto"
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    history.push('/admin/placelist/create');
                  }}
                >
                  <i className="fas fa-plus"></i> Crear Lugar
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Buscar Nombre, Dirección"
              />
            </Col>
          </Row>
          {errorDelete?.error && (
            <Message variant="danger" className="mt-3">
              {errorDelete?.error}
            </Message>
          )}
          <Table
            striped
            bordered
            hover
            responsive="sm"
            className="table-sm mt-2"
          >
            <TableHeader
              headers={headers}
              onSorting={(field, order) => {
                setSorting({ field, order });
              }}
            />
            <tbody>
              {itemsData.map((place) => (
                <tr key={place._id}>
                  <td>{place.name}</td>
                  <td>{place.address}</td>
                  <td>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${place.coords.latitude},${place.coords.longitude}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {place.coords.latitude} {place.coords.longitude}
                    </a>
                  </td>
                  <td>
                    {/* Edit */}
                    {userInfo.isAdmin && (
                      <LinkContainer to={`/admin/place/${place._id}/edit`}>
                        <Button variant="light" className="btn-sm">
                          <i className="fas fa-edit"></i>
                        </Button>
                      </LinkContainer>
                    )}
                    {/* Details */}
                    <LinkContainer to={`/admin/place/${place._id}/details`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-eye"></i>
                      </Button>
                    </LinkContainer>
                    {/* Delete */}
                    {userInfo?.isAdmin && (
                      <LinkContainer
                        to={`#`}
                        onClick={() => deleteHandler(place._id)}
                      >
                        <Button variant="danger" className="btn-sm">
                          <i className="fas fa-trash"></i>
                        </Button>
                      </LinkContainer>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
};

export default PlaceListScreen;
