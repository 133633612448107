import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { createAddress } from '../actions/transportActions';
import { ADDRESS_CREATE_RESET } from '../constants/transportConstants';

const CreateAddressScreen = ({ location, match, history }) => {
  const transportId = match.params.id;
  const zoneId = match.params.zoneId;

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);

  const dispatch = useDispatch();

  const addressCreate = useSelector((state) => state.addressCreate);
  const { loading, error: errorCreate, success: successCreate } = addressCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if ((!userInfo || !userInfo?.isAdmin) && !transportId) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: ADDRESS_CREATE_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (successCreate) {
      history.push(
        `/admin/transport/${transportId}/zone/${zoneId}/addresslist`
      );
    }

    // eslint-disable-next-line
  }, [successCreate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm('¿Esta seguro de crear una nueva dirección?')) {
      dispatch(
        createAddress(transportId, zoneId, {
          name,
          price,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Link
        to={`/admin/transport/${transportId}/zone/${zoneId}/addresslist`}
        className="btn btn-light"
      >
        Regresar
      </Link>
      <FormContainer>
        <h1>Crear Dirección</h1>
        {errorCreate?.error && (
          <Message variant="danger">{errorCreate.error}</Message>
        )}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <p>Los campos * son requeridos</p>
          <FormGroup
            controlId="name"
            name="name"
            label="Nombre *"
            placeholder="Ingrese nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={Boolean(errorCreate?.name)}
            errorInvalid={errorCreate?.name}
            required
          />
          <FormGroup
            controlId="price"
            name="price"
            label="Precio *"
            placeholder="Ingrese precio de pasaje"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            isInvalid={Boolean(errorCreate?.price)}
            errorInvalid={errorCreate?.price}
            required
          />

          <Button type="submit" variant="primary" className="mt-3">
            Crear
          </Button>
        </Form>
      </FormContainer>
    </React.Fragment>
  );
};

export default CreateAddressScreen;
