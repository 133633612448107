import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Image, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormGroup from '../components/FormGroup';
import FormTextAreaGroup from '../components/FormTextAreaGroup';
import FormContainer from '../components/FormContainer';
import { listProductDetails } from '../actions/productActions';
import { PRODUCT_DETAILS_RESET } from '../constants/productConstants';
import { addDecimals } from '../utils/utils';

const ProductDetailsScreen = ({ match, history }) => {
  const productId = match.params.id;

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [brand, setBrand] = useState('');
  const [image, setImage] = useState('');
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [dateOfProduction, setDateOfProduction] = useState('');
  const [benefit, setBenefit] = useState('');
  const [user, setUser] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  useEffect(() => {
    if (!userInfo) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: PRODUCT_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (product?._id !== productId) {
        dispatch(listProductDetails(productId));
      } else {
        if (
          product.user?._id === userInfo._id ||
          userInfo.isCoAdmin ||
          userInfo.isAdmin
        ) {
          setName(product.name);
          setPrice(product.price);
          setHasDiscount(product.hasDiscount);
          setDiscountPercentage(product.discountPercentage);
          setImage(product.image);
          setBrand(product.brand);
          setCategory(product.category?.name || 'n/a');
          setCountInStock(product.countInStock);
          setDescription(product.description);
          setCreatedAt(product.createdAt);
          setUpdatedAt(product.updatedAt);
          setDateOfProduction(product.dateOfProduction || '');
          setBenefit(product.benefit || '');
          setUser(product.user?.name);
        } else {
          return history.push('/login');
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, productId, product]);

  const handleGoBack = (e) => {
    e.preventDefault();
    history.push('/productlist');
  };

  return (
    <>
      <Link to="#" className="btn btn-light my-3" onClick={handleGoBack}>
        Regresar
      </Link>
      <FormContainer>
        <h1>Detalles del Producto</h1>
        {loading ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre"
              value={name}
              readOnly
            />

            <FormGroup
              controlId="price"
              name="price"
              label="Precio (Bs.)"
              type="number"
              value={addDecimals(price)}
              readOnly
            />

            <Form.Group controlId="hasDiscount" className="mt-2">
              <Form.Check
                type="checkbox"
                label="Precio con descuento aplicado"
                checked={hasDiscount}
                onChange={() => null}
              ></Form.Check>
            </Form.Group>

            <FormGroup
              disabled={!hasDiscount}
              controlId="discountPercentage"
              name="discountPercentage"
              label="Porcentaje (%)"
              type="number"
              value={discountPercentage}
              readOnly
            />

            <FormGroup
              controlId="brand"
              label="Marca"
              name="brand"
              value={brand}
              readOnly
            />

            <FormGroup
              controlId="countInStock"
              name="countInStock"
              type="number"
              label="Unidades de existencia"
              value={countInStock}
              readOnly
            />

            <FormGroup
              controlId="category"
              name="category"
              label="Categoría"
              value={category}
              readOnly
            />

            <FormTextAreaGroup
              controlId="description"
              label="Descripción"
              name="description"
              value={description}
              readOnly
            />

            <FormGroup
              controlId="dateOfProduction"
              name="dateOfProduction"
              label="Meses de disponibilidad"
              value={dateOfProduction}
              readOnly
            />

            <FormTextAreaGroup
              controlId="benefit"
              label="Beneficios"
              name="benefit"
              value={benefit}
              readOnly
            />

            <Col md={12} className="d-flex mt-2 flex-column">
              <Form.Label>Imagen</Form.Label>
              <div className='d-flex justify-content-center'>
                <Image
                  src={image}
                  alt={name}
                  fluid
                  width={250}
                  height={250}
                  onError={(ev) => {
                    ev.target.src = '/images/sample.jpg';
                  }}
                />
              </div>
            </Col>

            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de creación"
              value={moment(createdAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de actualización"
              value={moment(updatedAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="user"
              name="user"
              label="Registro"
              value={user}
              readOnly
            />

            <Button
              type="button"
              variant="secondary"
              className="mt-3"
              onClick={handleGoBack}
            >
              Regresar
            </Button>
          </>
        )}
      </FormContainer>
    </>
  );
};

export default ProductDetailsScreen;
