import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import Category from '../components/Category';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import ProductCarousel from '../components/ProductCarousel';
import Meta from '../components/Meta';
import WelcomeModal from '../components/WelcomeModal';
import {
  setModalWelcome,
  setRememberModalWelcome,
} from '../actions/configActions';
import { listCategories } from '../actions/categoryActions';

const HomeScreen = ({ match }) => {
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories, page, pages } = categoryList;

  const config = useSelector((state) => state.config);
  const { modalWelcome } = config;

  useEffect(() => {
    dispatch(listCategories(pageNumber, { enablePagination: true }))
  }, [dispatch, pageNumber]);

  function onHideModal(notRememberModal) {
    if (notRememberModal) {
      return dispatch(setRememberModalWelcome(false));
    }

    dispatch(setModalWelcome(false));
  }

  return (
    <>
      <Meta />
      <WelcomeModal show={modalWelcome} onHideModal={onHideModal} />

      <ProductCarousel />
      <h1>Categorías</h1>

      {loading ? (
        <Loader />
      ) : error?.error ? (
        <Message variant="danger">{error.error}</Message>
      ) : (
        <>
          <Row>
            {categories.map((category) => (
              <Col key={category._id} sm={12} md={6} lg={4} xl={3}>
                <Category category={category} />
              </Col>
            ))}
            <Paginate
              pages={pages}
              page={page}
              keyword={''}
            />
          </Row>
        </>
      )}
    </>
  );
};

export default HomeScreen;
