import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormGroup from '../components/FormGroup';
import FormSelect from '../components/FormSelect';
import FormFileGroup from '../components/FormFileGroup';
import FormTextAreaGroup from '../components/FormTextAreaGroup';
import FormContainer from '../components/FormContainer';
import { imageUpload } from '../config/constants';
import { createProduct } from '../actions/productActions';
import { listCategories } from '../actions/categoryActions';
import { CATEGORY_LIST_RESET } from '../constants/categoryConstants';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';
import isImageInput from '../validations/imageInput';

const ProductCreateScreen = ({ history }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState('');
  const [dateOfProduction, setDateOfProduction] = useState('');
  const [benefit, setBenefit] = useState('');
  const [file, setFile] = useState('');
  const [filePreview, setFilePreview] = useState(false);
  const [categoryListOptions, setCategoryListOptions] = useState([
    {
      label: 'Seleccione una opción',
      value: '',
    },
  ]);
  const [errorImage, setErrorImage] = useState('');

  const dispatch = useDispatch();

  const categoryList = useSelector((state) => state.categoryList);
  const {
    loading: loadingCategory,
    categories,
    error: errorCategory,
  } = categoryList;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error,
    success: successCreate,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      dispatch(listCategories());
    } else {
      return history.push('/login');
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loadingCategory && categories) {
      const items = categories.map((category) => ({
        label: category.name,
        value: category._id,
      }));

      setCategoryListOptions([
        {
          label: 'Seleccione una opción',
          value: '',
        },
        ...items,
      ]);
    }

    // eslint-enable-next-line
  }, [loadingCategory, categories]);

  useEffect(() => {
    if (successCreate) {
      return history.push('/productlist');
    }

    return () => {
      dispatch({ type: CATEGORY_LIST_RESET });
      dispatch({ type: PRODUCT_CREATE_RESET });
    };
  }, [dispatch, history, successCreate]);

  const uploadFileHandler = async (e) => {
    const fileLoaded = e.target.files[0];

    // Validate input image
    if (fileLoaded) {
      // Compress image
      var options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
        fileType: 'image/jpeg',
      };

      imageCompression(fileLoaded, options)
        .then(function (compressedFile) {
          const { success, message } = isImageInput(compressedFile);
          if (!success) {
            setFilePreview(false);
            setFile('');
            return setErrorImage(message);
          }

          setFilePreview(URL.createObjectURL(compressedFile));
          setFile(compressedFile);
          setErrorImage('');
        })
        .catch(function (error) {
          setFilePreview(false);
          setFile('');
        });
    } else {
      setFilePreview(false);
      setFile('');
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formFile = new FormData();
    formFile.append('image', file);

    if (window.confirm('¿Esta seguro de crear un nuevo producto?')) {
      dispatch(
        createProduct(
          {
            name,
            price,
            hasDiscount,
            discountPercentage,
            brand,
            category,
            description,
            countInStock,
            dateOfProduction,
            benefit
          },
          formFile
        )
      );
    }
  };

  return (
    <>
      <Link to="/productlist" className="btn btn-light my-3">
        Regresar
      </Link>
      <FormContainer>
        <h1>Crear Producto</h1>
        {loadingCreate && <Loader />}
        {errorCategory?.error && (
          <Message variant="danger">{errorCategory?.error}</Message>
        )}
        {error?.error && <Message variant="danger">{error?.error}</Message>}
        <Form onSubmit={submitHandler}>
          <p>Los campos * son requeridos</p>
          <FormGroup
            controlId="name"
            name="name"
            label="Nombre *"
            placeholder="Ingrese nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={Boolean(error?.name)}
            errorInvalid={error?.name}
            required
          />

          <FormGroup
            controlId="price"
            name="price"
            label="Precio (Bs.) *"
            type="number"
            placeholder="Ingrese precio en bolivianos"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            isInvalid={Boolean(error?.price)}
            errorInvalid={error?.price}
            required
          />

          <Form.Group controlId="hasDiscount" className="mt-2">
            <Form.Check
              type="checkbox"
              label="Precio con descu8ento aplicado"
              checked={hasDiscount}
              onChange={(e) => {
                setHasDiscount(e.target.checked)
                setDiscountPercentage(0)
              }}
            ></Form.Check>
          </Form.Group>

          <FormGroup
            type="number"
            disabled={!hasDiscount}
            controlId="discountPercentage"
            label="Porcentaje (%)"
            name="discountPercentage"
            placeholder="Ingrese número porcentaje de descuento"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
            isInvalid={Boolean(error?.discountPercentage)}
            errorInvalid={error?.discountPercentage}
          />

          <FormGroup
            controlId="brand"
            label="Marca"
            name="brand"
            placeholder="Ingrese marca *"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            isInvalid={Boolean(error?.brand)}
            errorInvalid={error?.brand}
            required
          />

          <FormGroup
            controlId="countInStock"
            name="countInStock"
            type="number"
            label="Unidades de existencia *"
            placeholder="Ingrese unidades de existencia"
            value={countInStock}
            onChange={(e) => setCountInStock(e.target.value)}
            isInvalid={Boolean(error?.countInStock)}
            errorInvalid={error?.countInStock}
            required
          />

          <FormSelect
            controlId="category"
            name="category"
            label="Categoría *"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            isInvalid={Boolean(error?.category)}
            errorInvalid={error?.category}
            options={categoryListOptions}
            required
          />

          <FormTextAreaGroup
            controlId="description"
            label="Descripción *"
            name="description"
            placeholder="Ingrese descripción del producto"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            isInvalid={Boolean(error?.description)}
            errorInvalid={error?.description}
            required
          />

          <FormGroup
            controlId="dateOfProduction"
            label="Meses de disponibilidad"
            name="dateOfProduction"
            placeholder="Ingrese meses de disponibilidad"
            value={dateOfProduction}
            onChange={(e) => setDateOfProduction(e.target.value)}
            isInvalid={Boolean(error?.dateOfProduction)}
            errorInvalid={error?.dateOfProduction}
          />

          <FormTextAreaGroup
            controlId="benefit"
            label="Beneficios"
            name="benefit"
            placeholder="Ej. Ayuda contra el cáncer, presion alta, colesterol, diabetis, etc"
            value={benefit}
            onChange={(e) => setBenefit(e.target.value)}
            isInvalid={Boolean(error?.benefit)}
            errorInvalid={error?.benefit}
          />

          <Col md={12} className="d-flex justify-content-center mt-2">
            <Image
              src={filePreview || imageUpload}
              alt={name}
              fluid
              width={230}
              height={230}
            />
          </Col>
          <div className="text-center">
            <small>Las dimensiones recomendadas son 500x500 px</small>
          </div>
          <FormFileGroup
            controlId="image"
            name="image"
            label="Imagen"
            fileId="image-file"
            fileLabel="Seleccionar Archivo"
            onChangeFile={uploadFileHandler}
            isInvalid={Boolean(error?.file) || Boolean(errorImage)}
            errorInvalid={error?.file || errorImage}
            accept="image/*"
          />

          <Button type="submit" variant="primary" className="mt-3">
            Crear
          </Button>
        </Form>
      </FormContainer >
    </>
  );
};

export default ProductCreateScreen;
