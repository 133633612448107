import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import TableHeader from '../components/TableHeader';
import { addDecimals } from '../utils/utils';
import { deleteAddress, listAddresses } from '../actions/transportActions';

const ITEMS_PER_PAGE = 10;

const headers = [
  { name: 'NOMBRE', field: 'name', sortable: true },
  { name: 'PRECIO (Bs.)', field: 'price', sortable: true },
  { name: 'REGISTRO', field: 'createdAt', sortable: true },
];

const AddressListScreen = ({ match, history }) => {
  const transportId = match.params.id;
  const zoneId = match.params.zoneId;

  const dispatch = useDispatch();

  const addressList = useSelector((state) => state.addressList);
  const { loading, error, addresses } = addressList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const addressDelete = useSelector((state) => state.addressDelete);
  const { success: successDelete, error: errorDelete } = addressDelete;

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.isCoAdmin || userInfo.isAdmin) &&
      transportId &&
      zoneId
    ) {
      dispatch(listAddresses(transportId, zoneId));
    } else {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [dispatch, history, successDelete, userInfo, transportId, zoneId]);

  const itemsData = useMemo(() => {
    if (transportId) {
      let computedItems =
        (addresses &&
          addresses.map((a) => ({
            ...a,
            price: a.price.toString(),
            createdAt: moment(a.createdAt).format('DD/MM/YYYY') || '',
          }))) ||
        [];

      if (search) {
        computedItems = computedItems.filter(
          (comment) =>
            comment.name.toLowerCase().includes(search.toLowerCase()) ||
            comment.price.toLowerCase().includes(search.toLowerCase())
        );
      }

      setTotalItems(computedItems.length);

      // Sorting items
      if (sorting.field && sorting.field !== 'price') {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        computedItems = computedItems.sort((a, b) => {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        });
      }

      // Sorting items numbers field
      if (sorting.field && sorting.field === 'price') {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        if (reversed > 0) {
          computedItems = computedItems.sort((a, b) => {
            return a[sorting.field] - b[sorting.field];
          });
        } else {
          computedItems = computedItems.sort((a, b) => {
            return b[sorting.field] - a[sorting.field];
          });
        }
      }

      // Current Page slice
      return computedItems.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [addresses, currentPage, search, sorting, transportId]);

  const deleteHandler = (addressId) => {
    if (window.confirm('¿Esta seguro de eliminar la dirección?')) {
      dispatch(deleteAddress(transportId, zoneId, addressId));
    }
  };

  return (
    <>
      <Link
        to={`/admin/transport/${transportId}/zonelist`}
        className="btn btn-light"
      >
        Regresar
      </Link>
      {loading ? (
        <Loader />
      ) : error?.error ? (
        <Message className="mt-3" variant="danger">
          {error.error}
        </Message>
      ) : (
        <React.Fragment>
          <Row className="align-items-center">
            <Col>
              <h1>Direcciones</h1>
            </Col>
            {!error?.error && (
              <Col className="text-right">
                <Button
                  className="ms-auto"
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    history.push(
                      `/admin/transport/${transportId}/zone/${zoneId}/addresslist/create`
                    );
                  }}
                >
                  <i className="fas fa-plus"></i> Crear Dirección
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Buscar Nombre, Precio, Fecha"
              />
            </Col>
          </Row>
          {errorDelete?.error && (
            <Message variant="danger" className="mt-3">
              {errorDelete?.error}
            </Message>
          )}
          <Table
            striped
            bordered
            hover
            responsive="sm"
            className="table-sm mt-2"
          >
            <TableHeader
              headers={headers}
              onSorting={(field, order) => {
                setSorting({ field, order });
              }}
            />
            <tbody>
              {itemsData.map((address) => (
                <tr key={address._id}>
                  <td>{address.name}</td>
                  <td>{addDecimals(address.price)}</td>
                  <td>{address.createdAt}</td>
                  <td>
                    {/* Edit */}
                    {userInfo?.isAdmin && (
                      <LinkContainer
                        to={`/admin/transport/${transportId}/zone/${zoneId}/address/${address._id}/edit`}
                      >
                        <Button variant="light" className="btn-sm">
                          <i className="fas fa-edit"></i>
                        </Button>
                      </LinkContainer>
                    )}

                    {/* Details */}
                    <LinkContainer
                      to={`/admin/transport/${transportId}/zone/${zoneId}/address/${address._id}/details`}
                    >
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-eye"></i>
                      </Button>
                    </LinkContainer>

                    {/* Delete */}
                    {userInfo?.isAdmin && (
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={(e) => deleteHandler(address._id)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
};

export default AddressListScreen;
