export const PLACE_LIST_REQUEST = 'PLACE_LIST_REQUEST';
export const PLACE_LIST_SUCCESS = 'PLACE_LIST_SUCCESS';
export const PLACE_LIST_FAIL = 'PLACE_LIST_FAIL';
export const PLACE_LIST_RESET = 'PLACE_LIST_RESET';

export const PLACE_DETAILS_REQUEST = 'PLACE_DETAILS_REQUEST';
export const PLACE_DETAILS_SUCCESS = 'PLACE_DETAILS_SUCCESS';
export const PLACE_DETAILS_FAIL = 'PLACE_DETAILS_FAIL';
export const PLACE_DETAILS_RESET = 'PLACE_DETAILS_RESET';

export const PLACE_DELETE_REQUEST = 'PLACE_DELETE_REQUEST';
export const PLACE_DELETE_SUCCESS = 'PLACE_DELETE_SUCCESS';
export const PLACE_DELETE_FAIL = 'PLACE_DELETE_FAIL';
export const PLACE_DELETE_RESET = 'PLACE_DELETE_RESET';

export const PLACE_CREATE_REQUEST = 'PLACE_CREATE_REQUEST';
export const PLACE_CREATE_SUCCESS = 'PLACE_CREATE_SUCCESS';
export const PLACE_CREATE_FAIL = 'PLACE_CREATE_FAIL';
export const PLACE_CREATE_RESET = 'PLACE_CREATE_RESET';

export const PLACE_UPDATE_REQUEST = 'PLACE_UPDATE_REQUEST';
export const PLACE_UPDATE_SUCCESS = 'PLACE_UPDATE_SUCCESS';
export const PLACE_UPDATE_FAIL = 'PLACE_UPDATE_FAIL';
export const PLACE_UPDATE_RESET = 'PLACE_UPDATE_RESET';