import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import CheckoutSteps from '../components/CheckoutSteps';
import { createOrder } from '../actions/orderActions';
import { ORDER_CREATE_RESET } from '../constants/orderConstants';
import { USER_DETAILS_RESET } from '../constants/userConstants';
import { addDecimals } from '../utils/utils';

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  if (
    (cart.shippingAddress.deliveryMethod ===
      process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1 &&
      (!cart.shippingAddress.transport?._id ||
        !cart.shippingAddress.transport?.zone?._id ||
        !cart.shippingAddress.transport?.zone?.address?._id)) ||
    (cart.shippingAddress.deliveryMethod ===
      process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2 &&
      !cart.shippingAddress.place?._id)
  ) {
    history.push('/shipping');
  } else if (!cart.paymentMethod) {
    history.push('/payment');
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  cart.shippingPrice =
    (cart.shippingAddress.deliveryMethod ===
      process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1 &&
      Number(cart.shippingAddress.transport.zone.address.price).toFixed(2)) ||
    0;
  cart.totalPrice = (
    Number(cart.itemsPrice) + Number(cart.shippingPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
    }
    // eslint-disable-next-line
  }, [history, success]);

  const placeOrderHandler = () => {
    if (
      window.confirm(
        'El proveedor revisará la orden para el envió del pedido. Tenga en cuenta que el proveedor puede contactarse con usted para coordinar la entrega. ¿Esta seguro de enviar el pedido?'
      )
    ) {
      dispatch(
        createOrder({
          orderItems: cart.cartItems.map((item) => ({
            ...item,
            product: item.product?._id,
            provider: item.product?.user?._id,
          })),
          shippingAddress: {
            ...cart.shippingAddress,
            place: cart.shippingAddress.place?._id || '',
            transportDelivery:
              (cart.shippingAddress.deliveryMethod ===
                process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1 && {
                transport: cart.shippingAddress.transport._id,
                zone: cart.shippingAddress.transport.zone.name,
                address: cart.shippingAddress.transport.zone.address.name,
                addressAdditional:
                  cart.shippingAddress.transport.zone.address.addressAdditional,
              }) ||
              {},
          },
          shippingPrice:
            (cart.shippingAddress.deliveryMethod ===
              process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1 &&
              cart.shippingPrice) ||
            0,
          paymentMethod: cart.paymentMethod,
          itemsPrice: cart.itemsPrice,
          totalPrice: cart.totalPrice,
        })
      );
    }
  };

  const navigateProfileUser = (e, userId) => {
    e.preventDefault();
    history.push(`/user/${userId}/profile`, {
      id: userId,
    });
  };

  return (
    <>
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Envio</h2>
              {cart.shippingAddress.deliveryMethod ===
                process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1 && (
                <>
                  <p>
                    <strong>Dirección: </strong>
                    {cart.shippingAddress.transport.zone.address.name}
                    {cart.shippingAddress.transport.zone.address
                      .addressAdditional
                      ? `, ${cart.shippingAddress.transport.zone.address.addressAdditional}, `
                      : ', '}
                    {cart.shippingAddress.transport.zone.name}
                  </p>
                  <p>
                    <strong>Servicio de Delivery: </strong>
                    {cart.shippingAddress.transport.name}
                  </p>
                </>
              )}
              {cart.shippingAddress.deliveryMethod ===
                process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2 && (
                <>
                  <p>
                    <strong>Lugar de entrega: </strong>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${cart.shippingAddress.place?.coords?.latitude},${cart.shippingAddress.place?.coords?.longitude}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {cart.shippingAddress.place?.name}
                    </a>
                  </p>
                  <p>
                    <strong>Dirección: </strong>
                    {cart.shippingAddress.place?.address}
                  </p>
                </>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Método de Pago</h2>
              <strong>Método: </strong>
              {cart.paymentMethod}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Pedido de Productos</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Tu carrito esta vacío</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            onError={(ev) => {
                              ev.target.src = '/images/sample.jpg';
                            }}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product?._id}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x Bs. {addDecimals(item.price)} = Bs.{' '}
                          {addDecimals(item.qty * item.price)}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-2">
                          Proveedor:{' '}
                          <a
                            href="/#"
                            onClick={(e) =>
                              navigateProfileUser(e, item.product?.user?._id)
                            }
                          >
                            {item.product?.user?.name}
                          </a>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Resumen del Pedido</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Productos</Col>
                  <Col>Bs. {cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Envio</Col>
                  <Col>Bs. {cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>Bs. {cart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {error && (
                <ListGroup.Item>
                  <Message variant="danger">{error}</Message>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cart.cartItems.length === 0}
                  onClick={placeOrderHandler}
                >
                  Realizar Pedido
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PlaceOrderScreen;
