import axios from 'axios';
import {
  TRANSPORT_LIST_REQUEST,
  TRANSPORT_LIST_SUCCESS,
  TRANSPORT_LIST_FAIL,
  TRANSPORT_DETAILS_REQUEST,
  TRANSPORT_DETAILS_SUCCESS,
  TRANSPORT_DETAILS_FAIL,
  TRANSPORT_DELETE_REQUEST,
  TRANSPORT_DELETE_SUCCESS,
  TRANSPORT_DELETE_FAIL,
  TRANSPORT_CREATE_REQUEST,
  TRANSPORT_CREATE_SUCCESS,
  TRANSPORT_CREATE_FAIL,
  TRANSPORT_UPDATE_REQUEST,
  TRANSPORT_UPDATE_SUCCESS,
  TRANSPORT_UPDATE_FAIL,
  ZONE_LIST_REQUEST,
  ZONE_LIST_SUCCESS,
  ZONE_LIST_FAIL,
  ZONE_DETAILS_REQUEST,
  ZONE_DETAILS_SUCCESS,
  ZONE_DETAILS_FAIL,
  ZONE_CREATE_REQUEST,
  ZONE_CREATE_SUCCESS,
  ZONE_CREATE_FAIL,
  ZONE_DELETE_REQUEST,
  ZONE_DELETE_SUCCESS,
  ZONE_DELETE_FAIL,
  ZONE_UPDATE_REQUEST,
  ZONE_UPDATE_SUCCESS,
  ZONE_UPDATE_FAIL,
  ADDRESS_LIST_REQUEST,
  ADDRESS_LIST_SUCCESS,
  ADDRESS_LIST_FAIL,
  ADDRESS_DETAILS_REQUEST,
  ADDRESS_DETAILS_SUCCESS,
  ADDRESS_DETAILS_FAIL,
  ADDRESS_CREATE_REQUEST,
  ADDRESS_CREATE_SUCCESS,
  ADDRESS_CREATE_FAIL,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_SUCCESS,
  ADDRESS_DELETE_FAIL,
  ADDRESS_UPDATE_REQUEST,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESS_UPDATE_FAIL,
} from '../constants/transportConstants';
import { handleErrorResponse } from './utils';

export const listTransports = () => async (dispatch) => {
  try {
    dispatch({ type: TRANSPORT_LIST_REQUEST });

    const { data } = await axios.get('/api/transports');

    dispatch({ type: TRANSPORT_LIST_SUCCESS, payload: data });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: TRANSPORT_LIST_FAIL,
      payload: error,
    });
  }
};

export const listTransportDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TRANSPORT_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/transports/${id}`, config);

    dispatch({
      type: TRANSPORT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: TRANSPORT_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const deleteTransport = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSPORT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/transports/${id}`, config);

    dispatch({
      type: TRANSPORT_DELETE_SUCCESS,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: TRANSPORT_DELETE_FAIL,
      payload: error,
    });
  }
};

export const createTransport = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSPORT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/transports`, formData, config);

    dispatch({
      type: TRANSPORT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: TRANSPORT_CREATE_FAIL,
      payload: error,
    });
  }
};

export const updateTransport =
  (transportId, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TRANSPORT_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/transports/${transportId}`,
        formData,
        config
      );

      dispatch({
        type: TRANSPORT_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: TRANSPORT_DETAILS_SUCCESS, payload: data });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: TRANSPORT_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const listZones = (transportId) => async (dispatch) => {
  try {
    dispatch({ type: ZONE_LIST_REQUEST });

    const { data } = await axios.get(`/api/transports/${transportId}/zones`);

    dispatch({ type: ZONE_LIST_SUCCESS, payload: data });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: ZONE_LIST_FAIL,
      payload: error,
    });
  }
};

export const listZoneDetails =
  (transportId, zoneId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ZONE_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/transports/${transportId}/zones/${zoneId}`,
        config
      );

      dispatch({
        type: ZONE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: ZONE_DETAILS_FAIL,
        payload: error,
      });
    }
  };

export const createZone =
  (transportId, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ZONE_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/transports/${transportId}/zones`,
        formData,
        config
      );

      dispatch({
        type: ZONE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: ZONE_CREATE_FAIL,
        payload: error,
      });
    }
  };

export const updateZone =
  (transportId, zoneId, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ZONE_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/transports/${transportId}/zones/${zoneId}`,
        formData,
        config
      );

      dispatch({
        type: ZONE_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: ZONE_DETAILS_SUCCESS, payload: data });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: ZONE_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const deleteZone =
  (transportId, zoneId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ZONE_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `/api/transports/${transportId}/zones/${zoneId}`,
        config
      );

      dispatch({
        type: ZONE_DELETE_SUCCESS,
      });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: ZONE_DELETE_FAIL,
        payload: error,
      });
    }
  };

export const listAddresses = (transportId, zoneId) => async (dispatch) => {
  try {
    dispatch({ type: ADDRESS_LIST_REQUEST });

    const { data } = await axios.get(
      `/api/transports/${transportId}/zones/${zoneId}/addresses`
    );

    dispatch({ type: ADDRESS_LIST_SUCCESS, payload: data });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: ADDRESS_LIST_FAIL,
      payload: error,
    });
  }
};

export const listAddressDetails =
  (transportId, zoneId, addressId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADDRESS_DETAILS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/transports/${transportId}/zones/${zoneId}/addresses/${addressId}`,
        config
      );

      dispatch({
        type: ADDRESS_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: ADDRESS_DETAILS_FAIL,
        payload: error,
      });
    }
  };

export const createAddress =
  (transportId, zoneId, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADDRESS_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/transports/${transportId}/zones/${zoneId}/addresses`,
        formData,
        config
      );

      dispatch({
        type: ADDRESS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: ADDRESS_CREATE_FAIL,
        payload: error,
      });
    }
  };

export const updateAddress =
  (transportId, zoneId, addressId, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADDRESS_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/transports/${transportId}/zones/${zoneId}/addresses/${addressId}`,
        formData,
        config
      );

      dispatch({
        type: ADDRESS_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: ADDRESS_DETAILS_SUCCESS, payload: data });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: ADDRESS_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const deleteAddress =
  (transportId, zoneId, addressId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADDRESS_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.delete(
        `/api/transports/${transportId}/zones/${zoneId}/addresses/${addressId}`,
        config
      );

      dispatch({
        type: ADDRESS_DELETE_SUCCESS,
      });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: ADDRESS_DELETE_FAIL,
        payload: error,
      });
    }
  };
