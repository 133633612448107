import React from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import SearchBox from './SearchBox';
import { logout } from '../actions/userActions';

const styles = {
  navbar: {
    backgroundColor: '#2f3e46',
  },
};

const Header = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      <Navbar
        // bg="dark"
        variant="dark"
        expand="lg"
        collapseOnSelect
        style={styles.navbar}
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img
                alt=""
                src="/images/logo-header.png"
                width="82"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              {/* Emborozú Ecológico */}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Route render={({ history }) => <SearchBox history={history} />} />
            <Nav className="ms-auto">
              <LinkContainer to="/cart">
                <Nav.Link>
                  <i className="fas fa-shopping-cart"></i> Carrito
                </Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Perfil</NavDropdown.Item>
                  </LinkContainer>
                  {(userInfo.isAdmin || userInfo.isEntrepreneur) && (
                    <LinkContainer to="/productlist">
                      <NavDropdown.Item>Productos</NavDropdown.Item>
                    </LinkContainer>
                  )}
                  <LinkContainer to="/orderlist">
                    <NavDropdown.Item>Pedidos</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/configuration">
                    <NavDropdown.Item>Configuración</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/about">
                    <NavDropdown.Item>Acerca de</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Cerrar Sesión
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <i className="fas fa-user"></i> Iniciar Sesión
                  </Nav.Link>
                </LinkContainer>
              )}
              {(userInfo?.isCoAdmin || userInfo?.isAdmin) && (
                <NavDropdown title="Admin" id="adminmenu">
                  <LinkContainer to="/admin/userlist">
                    <NavDropdown.Item>Usuarios</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/placelist">
                    <NavDropdown.Item>Lugares</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/transportlist">
                    <NavDropdown.Item>Transporte</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/admin/categorylist">
                    <NavDropdown.Item>Categorías</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              <NavDropdown title="Ayuda" id="helpmenu">
                <LinkContainer to="/#whatsapp" onClick={e => {
                  e.preventDefault()
                  window.open(`https://api.whatsapp.com/send?phone=${process.env.PHONE_WHATSAPP_ALMA_TIERRA}&text=Hola`)
                }}>
                  <NavDropdown.Item>Contacto Alma Tierra</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/#contactAlmaTierra" onClick={e => {
                  e.preventDefault()
                  window.open('https://forms.gle/1YAmbE3T5EYwfWTj9')
                }}>
                  <NavDropdown.Item>Encuesta de Satisfacción</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
