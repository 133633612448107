import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Image } from 'react-bootstrap';

import Rating from './Rating';
import { addDecimals } from '../utils/utils';

const styles = {
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    height: 230,
    maxWidth: 230,
  },
  discountOverlay: {
    position: "absolute",
    height: 80
  }
};

const Product = ({ product }) => {
  return (
    <Card className="my-3 p-3 rounded">
      <Link to={`/product/${product._id}`}>
        <div style={styles.imageContainer}>
          <div style={styles.image}>
            {product.hasDiscount &&
              <Image
                style={styles.discountOverlay}
                src="/images/product-discount.png"
                alt="producto con descuento"
              />
            }
            <Card.Img
              src={product.image}
              variant="top"
              className="img-responsive mh-100"
              onError={(ev) => {
                ev.target.src = '/images/sample.jpg';
              }}
            />
          </div>
        </div>
      </Link>

      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as="div">
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>

        <Card.Text as="div">
          <Rating
            value={product.rating}
            text={`${product.numReviews} reseñas`}
          />
        </Card.Text>

        <Card.Text as="h4">Bs. {addDecimals(product.price)}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
