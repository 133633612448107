import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import FormFileGroup from '../components/FormFileGroup';
import isImageInput from '../validations/imageInput';
import { createCategory } from '../actions/categoryActions';
import { imageUpload } from '../config/constants';
import { CATEGORY_CREATE_RESET } from '../constants/categoryConstants';

const CreateCategoryScreen = ({ location, history }) => {
  const [name, setName] = useState('');
  const [file, setFile] = useState('');
  const [filePreview, setFilePreview] = useState(false);
  const [errorImage, setErrorImage] = useState('');

  const dispatch = useDispatch();

  const categoryCreate = useSelector((state) => state.categoryCreate);
  const {
    loading,
    error: errorCreate,
    success: successCreate,
  } = categoryCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo?.isAdmin) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: CATEGORY_CREATE_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (successCreate) {
      history.push('/admin/categorylist');
    }

    // eslint-disable-next-line
  }, [successCreate]);

  const uploadFileHandler = async (e) => {
    const fileLoaded = e.target.files[0];

    // Validate input image
    if (fileLoaded) {
      // Compress image
      var options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
        fileType: 'image/jpeg',
      };

      imageCompression(fileLoaded, options)
        .then(function (compressedFile) {
          const { success, message } = isImageInput(compressedFile);
          if (!success) {
            setFilePreview(false);
            setFile('');
            return setErrorImage(message);
          }

          setFilePreview(URL.createObjectURL(compressedFile));
          setFile(compressedFile);
          setErrorImage('');
        })
        .catch(function (error) {
          setFilePreview(false);
          setFile('');
        });
    } else {
      setFilePreview(false);
      setFile('');
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formFile = new FormData();
    formFile.append('image', file);

    if (window.confirm('¿Esta seguro de crear una nueva categoría?')) {
      dispatch(
        createCategory({
          name,
        }, formFile)
      );
    }
  };

  return (
    <React.Fragment>
      <Link to="/admin/categorylist" className="btn btn-light">
        Regresar
      </Link>
      <FormContainer>
        <h1>Crear Categoría</h1>
        {errorCreate?.error && (
          <Message variant="danger">{errorCreate.error}</Message>
        )}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <p>Los campos * son requeridos</p>
          <FormGroup
            controlId="name"
            name="name"
            label="Nombre *"
            placeholder="Ingrese nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={Boolean(errorCreate?.name)}
            errorInvalid={errorCreate?.name}
            required
          />

          <Col md={12} className="d-flex justify-content-center mt-2">
            <Image
              src={filePreview || imageUpload}
              alt={name}
              fluid
              width={230}
              height={230}
            />
          </Col>
          <div className="text-center">
            <small>Las dimensiones recomendadas son 500x500 px</small>
          </div>
          <FormFileGroup
            controlId="image"
            name="image"
            label="Imagen"
            fileId="image-file"
            fileLabel="Seleccionar Archivo"
            onChangeFile={uploadFileHandler}
            isInvalid={Boolean(errorCreate?.file) || Boolean(errorImage)}
            errorInvalid={errorCreate?.file || errorImage}
            accept="image/*"
          />

          <Button type="submit" variant="primary" className="mt-3">
            Crear
          </Button>
        </Form>
      </FormContainer>
    </React.Fragment>
  );
};

export default CreateCategoryScreen;
