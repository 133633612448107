import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import CategoryScreen from './screens/CategoryScreen';
import ProductScreen from './screens/ProductScreen';
import ProductsScreen from './screens/ProductsScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import UserListScreen from './screens/UserListScreen';
import CreateUserScreen from './screens/CreateUserScreen';
import UserEditScreen from './screens/UserEditScreen';
import UserDetailsScreen from './screens/UserDetailsScreen';
import UserEditPasswordScreen from './screens/UserEditPasswordScreen';
import ProfileUserScreen from './screens/ProfileUserScreen';
import CategoryListScreen from './screens/CategoryListScreen';
import CategoryCreateScreen from './screens/CategoryCreateScreen';
import CategoryEditScreen from './screens/CategoryEditScreen';
import CategoryDetailsScreen from './screens/CategoryDetailsScreen';
import TransportListScreen from './screens/TransportListScreen';
import TransportCreateScreen from './screens/TransportCreateScreen';
import TransportEditScreen from './screens/TransportEditScreen';
import TransportDetailsScreen from './screens/TransportDetailsScreen';
import ZoneListScreen from './screens/ZoneListScreen';
import ZoneCreateScreen from './screens/ZoneCreateScreen';
import ZoneEditScreen from './screens/ZoneEditScreen';
import ZoneDetailsScreen from './screens/ZoneDetailsScreen';
import AddressListScreen from './screens/AddressListScreen';
import AddressCreateScreen from './screens/AddressCreateScreen';
import AddressEditScreen from './screens/AddressEditScreen';
import AddressDetailsScreen from './screens/AddressDetailsScreen';
import PlaceListScreen from './screens/PlaceListScreen';
import PlaceCreateScreen from './screens/PlaceCreateScreen';
import PlaceEditScreen from './screens/PlaceEditScreen';
import PlaceDetailsScreen from './screens/PlaceDetailsScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductCreateScreen from './screens/ProductCreateScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import ProductDetailsScreen from './screens/ProductDetailsScreen';
import OrderListScreen from './screens/OrderListScreen';
import AboutScreen from './screens/AboutScreen';
import ConfigScreen from './screens/ConfigScreen';

// Config
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const App = () => {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <Route path="/order/:id" component={OrderScreen} />
          <Route path="/shipping" component={ShippingScreen} />
          <Route path="/payment" component={PaymentScreen} />
          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/login" component={LoginScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/profile" component={ProfileScreen} />
          <Route path="/product/:id" component={ProductScreen} />
          <Route path="/cart/:id?" component={CartScreen} />
          <Route path="/admin/userlist" exact component={UserListScreen} />
          <Route
            path="/admin/userlist/create"
            exact
            component={CreateUserScreen}
          />
          <Route path="/admin/user/:id/edit" component={UserEditScreen} />
          <Route path="/admin/user/:id/details" component={UserDetailsScreen} />
          <Route
            path="/admin/user/:id/editpassword"
            component={UserEditPasswordScreen}
          />
          <Route path="/user/:id/profile" component={ProfileUserScreen} />
          <Route
            path="/admin/categorylist"
            exact
            component={CategoryListScreen}
          />
          <Route
            path="/admin/categorylist/create"
            exact
            component={CategoryCreateScreen}
          />
          <Route
            path="/admin/category/:id/edit"
            exact
            PlaceCreateScreen
            component={CategoryEditScreen}
          />
          <Route
            path="/admin/category/:id/details"
            exact
            component={CategoryDetailsScreen}
          />
          <Route
            path="/admin/transportlist"
            exact
            component={TransportListScreen}
          />
          <Route
            path="/admin/transportlist/create"
            exact
            component={TransportCreateScreen}
          />
          <Route
            path="/admin/transport/:id/edit"
            exact
            PlaceCreateScreen
            component={TransportEditScreen}
          />
          <Route
            path="/admin/transport/:id/details"
            exact
            component={TransportDetailsScreen}
          />
          <Route
            path="/admin/transport/:id/zonelist"
            exact
            component={ZoneListScreen}
          />
          <Route
            path="/admin/transport/:id/zonelist/create"
            exact
            component={ZoneCreateScreen}
          />
          <Route
            path="/admin/transport/:id/zone/:zoneId/edit"
            exact
            component={ZoneEditScreen}
          />
          <Route
            path="/admin/transport/:id/zone/:zoneId/details"
            exact
            component={ZoneDetailsScreen}
          />
          <Route
            path="/admin/transport/:id/zone/:zoneId/addresslist"
            exact
            component={AddressListScreen}
          />
          <Route
            path="/admin/transport/:id/zone/:zoneId/addresslist/create"
            exact
            component={AddressCreateScreen}
          />
          <Route
            path="/admin/transport/:id/zone/:zoneId/address/:addressId/edit"
            exact
            component={AddressEditScreen}
          />
          <Route
            path="/admin/transport/:id/zone/:zoneId/address/:addressId/details"
            exact
            component={AddressDetailsScreen}
          />
          <Route path="/admin/placelist" exact component={PlaceListScreen} />
          <Route
            path="/admin/placelist/create"
            exact
            component={PlaceCreateScreen}
          />
          <Route
            path="/admin/place/:id/edit"
            exact
            PlaceCreateScreen
            component={PlaceEditScreen}
          />
          <Route
            path="/admin/place/:id/details"
            exact
            component={PlaceDetailsScreen}
          />
          <Route path="/productlist" exact component={ProductListScreen} />
          <Route
            path="/productlist/product/create"
            exact
            component={ProductCreateScreen}
          />
          <Route
            path="/productlist/product/:id/edit"
            component={ProductEditScreen}
          />
          <Route
            path="/productlist/product/:id/details"
            component={ProductDetailsScreen}
          />
          <Route path="/orderlist" component={OrderListScreen} />
          <Route path="/search/:keyword" component={ProductsScreen} exact />
          <Route path="/page/:pageNumber" component={HomeScreen} exact />
          <Route path="/category/:id" component={CategoryScreen} exact />
          <Route path="/category/:id/page/:pageNumber" component={CategoryScreen} exact />
          <Route
            path="/search/:keyword/page/:pageNumber"
            component={ProductsScreen}
            exact
          />
          <Route path="/configuration" component={ConfigScreen} exact />
          <Route path="/about" component={AboutScreen} exact />
          <Route path="/" component={HomeScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
