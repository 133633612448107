import { logout } from './userActions';

export const handleErrorResponse = (err, dispatch) => {
  let error = err.response?.data?.message
    ? { error: err.response?.data?.message }
    : err.response?.data?.errors || { error: err.message };

  if (error.error === 'Not authorized, token failed') {
    dispatch(logout());
  }

  return error;
};
