import React, { useEffect, useState, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Search from '../components/Search';
import TableHeader from '../components/TableHeader';
import Pagination from '../components/Pagination';
import {
  listProductsFromAdmin,
  listProductsByUser,
  deleteProduct,
} from '../actions/productActions';
import { addDecimals } from '../utils/utils';

const ITEMS_PER_PAGE = 10;

const headers = [
  { name: 'NOMBRE', field: 'name', sortable: true },
  { name: 'PRECIO (BS.)', field: 'price', sortable: true },
  { name: 'CANTIDAD', field: 'countInStock', sortable: true },
  { name: 'CATEGORIA', field: 'category', sortable: true },
  { name: 'MARCA', field: 'brand', sortable: true },
  { name: 'REGISTRO', field: 'createdAt', sortable: true },
];

const ProductListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!userInfo) {
      return history.push('/login');
    }

    // Admin
    if (userInfo.isCoAdmin || userInfo.isAdmin) {
      dispatch(listProductsFromAdmin('', ''));
    } else {
      dispatch(listProductsByUser());
    }

    // eslint-disable-next-line
  }, [dispatch, history, userInfo, successDelete]);

  const itemsData = useMemo(() => {
    let computedItems =
      (products &&
        products.map((p) => ({
          ...p,
          price: p.price.toString(),
          category: p.category?.name || 'n/a',
          createdAt: moment(p.createdAt).format('DD/MM/YYYY') || '',
        }))) ||
      [];

    if (search) {
      computedItems = computedItems.filter(
        (comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.category.toLowerCase().includes(search.toLowerCase()) ||
          comment.brand.toLowerCase().includes(search.toLowerCase()) ||
          comment.createdAt.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedItems.length);

    // Sorting items
    if (sorting.field && (sorting.field !== 'price' && sorting.field !== "countInStock")) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedItems = computedItems.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // Sorting items numbers field
    if (sorting.field && (sorting.field === 'price' || sorting.field === "countInStock")) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      if (reversed > 0) {
        computedItems = computedItems.sort((a, b) => {
          return a[sorting.field] - b[sorting.field];
        });
      } else {
        computedItems = computedItems.sort((a, b) => {
          return b[sorting.field] - a[sorting.field];
        });
      }
    }

    // Current Page slice
    return computedItems.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [products, currentPage, search, sorting]);

  const deleteHandler = (id) => {
    if (window.confirm('¿Esta seguro de eliminar el producto?')) {
      dispatch(deleteProduct(id));
    }
  };

  const createProductHandler = () => {
    history.push('/productlist/product/create');
  };

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>Productos</h1>
        </Col>
        {!error?.error && (
          <Col className="text-right">
            <Button className="my-3" onClick={createProductHandler}>
              <i className="fas fa-plus"></i> Crear Producto
            </Button>
          </Col>
        )}
      </Row>
      {errorDelete?.error && (
        <Message variant="danger">{errorDelete.error}</Message>
      )}
      {loading || loadingDelete ? (
        <Loader />
      ) : error?.error ? (
        <Message variant="danger">{error.error}</Message>
      ) : (
        <>
          <Row>
            <Col>
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Buscar Nombre, Categoría, Marca, Fecha"
              />
            </Col>
          </Row>
          <Table
            striped
            bordered
            hover
            responsive="sm"
            className="table-sm mt-2"
          >
            <TableHeader
              headers={headers}
              onSorting={(field, order) => {
                setSorting({ field, order });
              }}
            />
            <tbody>
              {itemsData.map((product) => (
                <tr key={product._id}>
                  <td>{product.name}</td>
                  <td>{addDecimals(product.price)}</td>
                  <td>{product.countInStock}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>{product.createdAt}</td>
                  <td>
                    {/* Edit */}
                    <LinkContainer
                      to={`/productlist/product/${product._id}/edit`}
                    >
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    {/* Details */}
                    <LinkContainer
                      to={`/productlist/product/${product._id}/details`}
                    >
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-eye"></i>
                      </Button>
                    </LinkContainer>
                    {/* Delete */}
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductListScreen;
