import React, { useState, useEffect } from 'react';
import { Form, Button, Col, InputGroup, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import FormGroupPassword from '../components/FormGroupPassword';
import FormFileGroup from '../components/FormFileGroup';
import { imageUpload } from '../config/constants';
import isImageInput from '../validations/imageInput';

import { getUserDetails, updateUserProfile } from '../actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';

const ProfileScreen = ({ location, history }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const [phone, setPhone] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tikTok, setTikTok] = useState('');
  const [message, setMessage] = useState(null);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [file, setFile] = useState('');
  const [filePreview, setFilePreview] = useState(false);
  const [image, setImage] = useState('');
  const [errorImage, setErrorImage] = useState('');

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success, error: errorUpdate } = userUpdateProfile;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!user || !user.name) {
        dispatch(getUserDetails('profile'));
      } else {
        const userRole = user.isAdmin
          ? 'Administrador'
          : user.isCoAdmin
            ? 'Coadministrador'
            : user.isEntrepreneur
              ? 'Emprendedor'
              : 'Usuario';

        setName(user.name);
        setEmail(user.email);
        setRole(userRole);
        setPhone(user.phone);
        setFacebook(user.facebook);
        setInstagram(user.instagram);
        setTikTok(user.tikTok);
        setImage(user.image);
      }
    }

    // eslint-disable-next-line
  }, [dispatch, history, userInfo, user]);

  useEffect(() => {
    if (success) {
      dispatch(getUserDetails('profile'));
    }
    // eslint-disable-next-line
  }, [success]);

  useEffect(() => {
    return () => {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
    };
    // eslint-disable-next-line
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    const formFile = new FormData();
    formFile.append('image', file);

    const msg =
      password !== ''
        ? '¿Esta seguro de actualizar los datos del perfil?. Se realizará la actualización de su nueva contraseña'
        : '¿Esta seguro de actualizar los datos del perfil?';

    // Update user profile
    if (window.confirm(msg)) {
      if (password !== confirmPassword) {
        setMessage('Las contraseñas no coinciden');
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        return true;
      }

      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          email,
          password,
          phone,
          facebook,
          instagram,
          tikTok,
        }, formFile)
      );
      setMessage('');
    }
  };

  const handlePhone = (e) => {
    // reg solo number input
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (pattern.test(e.target.value)) {
      setPhone(e.target.value);
    } else if (e.target.value !== '') {
      setPhone(phone);
    } else {
      setPhone('');
    }
  };

  const handleIsChangePassword = (e) => {
    const value = e.target.checked;
    // Reset values password
    if (!value) {
      setPassword('');
      setConfirmPassword('');
    }

    setIsChangePassword(value);
  };

  const handleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleShowPassword2 = (e) => {
    setShowPassword2(!showPassword2);
  };

  const uploadFileHandler = async (e) => {
    const fileLoaded = e.target.files[0];

    // Validate input image
    if (fileLoaded) {
      // Compress image
      var options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      };

      imageCompression(fileLoaded, options)
        .then(function (compressedFile) {
          const { success, message } = isImageInput(compressedFile);
          if (!success) {
            setFilePreview(false);
            setFile('');
            return setErrorImage(message);
          }

          setFilePreview(URL.createObjectURL(compressedFile));
          setFile(compressedFile);
          setErrorImage('');
        })
        .catch(function (error) {
          setFilePreview(false);
          setFile('');
        });
    } else {
      setFilePreview(false);
      setFile('');
    }
  };

  const imgSrc = filePreview ? filePreview : image ? image : imageUpload;

  return (
    <FormContainer>
      <Col md={12}>
        <h2>Perfil del Usuario</h2>
        {success && <Message variant="success">Perfil Actualizado</Message>}
        {message && <Message variant="danger">{message}</Message>}
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate?.error}</Message>
        )}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre *"
              placeholder="Ingrese nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />

            <FormGroup
              controlId="email"
              name="email"
              label="Email *"
              type="email"
              placeholder="Ingrese email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={Boolean(errorUpdate?.email)}
              errorInvalid={errorUpdate?.email}
              required
            />

            <FormGroup
              controlId="role"
              name="role"
              label="Rol"
              value={role}
              readOnly
              disabled
            />

            <Form.Group controlId="phone" className="mt-2">
              <Form.Label>Teléfono WhatsApp *</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={Boolean(errorUpdate?.phone)}
                  placeholder={'Ingrese teléfono'}
                  value={phone}
                  required
                  onChange={handlePhone}
                />
                {Boolean(errorUpdate?.phone) && (
                  <Form.Control.Feedback type="invalid">
                    {errorUpdate.phone}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>

            {(userInfo?.isEntrepreneur ||
              userInfo?.isCoAdmin ||
              userInfo?.isAdmin) && (
                <>
                  <FormGroup
                    controlId="facebook"
                    name="facebook"
                    label="Facebook"
                    placeholder="Ingrese url Facebook"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                    isInvalid={Boolean(errorUpdate?.facebook)}
                    errorInvalid={errorUpdate?.facebook}
                  />

                  <FormGroup
                    controlId="instagram"
                    name="instagram"
                    label="Instagram"
                    placeholder="Ingrese url Instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                    isInvalid={Boolean(errorUpdate?.instagram)}
                    errorInvalid={errorUpdate?.instagram}
                  />

                  <FormGroup
                    controlId="tikTok"
                    name="tikTok"
                    label="TikTok"
                    placeholder="Ingrese url tikTok"
                    value={tikTok}
                    onChange={(e) => setTikTok(e.target.value)}
                    isInvalid={Boolean(errorUpdate?.tikTok)}
                    errorInvalid={errorUpdate?.tikTok}
                  />
                </>
              )}

            <Form.Group controlId="changePassword" className="mt-2">
              <Form.Check
                type="checkbox"
                label="¿Cambiar contraseña?"
                checked={isChangePassword}
                onChange={handleIsChangePassword}
              ></Form.Check>
            </Form.Group>

            {isChangePassword && (
              <>
                <FormGroupPassword
                  controlId="password"
                  name="password"
                  label="Contraseña"
                  type="password"
                  placeholder="Ingrese contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={Boolean(errorUpdate?.password)}
                  errorInvalid={errorUpdate?.password}
                  showPassword={showPassword1}
                  handleShowPassword={handleShowPassword1}
                />

                <FormGroupPassword
                  controlId="confirmPassword"
                  name="confirmPassword"
                  label="Confimar Contraseña"
                  type="password"
                  placeholder="Confirmar contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  isInvalid={Boolean(errorUpdate?.password)}
                  errorInvalid={errorUpdate?.password}
                  showPassword={showPassword2}
                  handleShowPassword={handleShowPassword2}
                />
              </>
            )}

            <Col md={12} className="d-flex justify-content-center mt-2">
              <Image src={imgSrc} alt={name} fluid width={230} height={230} />
            </Col>
            <div className="text-center">
              <small>Las dimensiones recomendadas son 500x500 px</small>
            </div>
            <FormFileGroup
              controlId="image"
              name="image"
              label="Logo"
              fileId="image-file"
              fileLabel="Seleccionar Archivo"
              onChangeFile={uploadFileHandler}
              isInvalid={Boolean(errorUpdate?.file) || Boolean(errorImage)}
              errorInvalid={errorUpdate?.file || errorImage}
              accept="image/*"
            />

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </Col>
    </FormContainer>
  );
};

export default ProfileScreen;
