import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormGroup from '../components/FormGroup';
import FormContainer from '../components/FormContainer';
import FormGroupPassword from '../components/FormGroupPassword';
import { getUserDetails, updatePasswordUser } from '../actions/userActions';
import {
  USER_UPDATE_RESET,
  USER_DETAILS_RESET,
} from '../constants/userConstants';

const UserEditPasswordScreen = ({ match, history }) => {
  const userId = match.params.id;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !(userInfo?.isCoAdmin || userInfo?.isAdmin)) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: USER_UPDATE_RESET });
      dispatch({ type: USER_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isCoAdmin || userInfo?.isAdmin) {
      if (successUpdate) {
        history.push('/admin/userlist');
      } else if (user?._id !== userId) {
        dispatch(getUserDetails(userId));
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, userId, user, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm('¿Esta seguro de actualizar contraseña?')) {
      dispatch(updatePasswordUser(userId, password));
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Link to="/admin/userlist" className="btn btn-light my-3">
        Regresar
      </Link>
      <FormContainer>
        <h1>Editar Contraseña del Usuario</h1>
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate.error}</Message>
        )}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre"
              value={name}
              readOnly
              disabled={true}
            />

            <FormGroup
              controlId="email"
              name="email"
              label="Email"
              value={email}
              readOnly
              disabled={true}
            />

            <FormGroupPassword
              controlId="password"
              name="password"
              type="password"
              label="Contraseña *"
              placeholder="Ingrese nueva contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={Boolean(errorUpdate?.password)}
              errorInvalid={errorUpdate?.password}
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
              required
            />

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default UserEditPasswordScreen;
