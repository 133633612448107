import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { listPlaceDetails } from '../actions/placeActions';
import { PLACE_DETAILS_RESET } from '../constants/placeConstants';

const PlaceDetailsScreen = ({ match, history }) => {
  const placeId = match.params.id;

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [coords, setCoords] = useState({
    latitude: '',
    longitude: '',
  });
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [user, setUser] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const placeDetails = useSelector((state) => state.placeDetails);
  const { loading, error, place } = placeDetails;

  useEffect(() => {
    if (!userInfo || !(userInfo?.isCoAdmin || userInfo?.isAdmin)) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: PLACE_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isCoAdmin || userInfo?.isAdmin) {
      if (place?._id !== placeId) {
        dispatch(listPlaceDetails(placeId));
      } else {
        setName(place.name);
        setAddress(place.address);
        setCoords({
          latitude: place.coords.latitude,
          longitude: place.coords.longitude,
        });
        setCreatedAt(place.createdAt);
        setUpdatedAt(place.updatedAt);
        setUser(place.user?.name);
      }
    }

    // eslint-disable-next-line
  }, [dispatch, history, placeId, place]);

  const handleGoBack = (e) => {
    e.preventDefault();
    history.push('/admin/placelist');
  };

  return (
    <React.Fragment>
      <Link to="#" className="btn btn-light" onClick={handleGoBack}>
        Regresar
      </Link>
      <FormContainer>
        <h1>Detalles del Lugar de Entrega</h1>
        {loading ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error?.error}</Message>
        ) : (
          <>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre"
              value={name}
              readOnly
            />
            <FormGroup
              controlId="address"
              name="address"
              label="Dirección"
              value={address}
              readOnly
            />
            <FormGroup
              controlId="latitude"
              name="latitude"
              label="Latitude"
              value={coords.latitude}
              readOnly
            />
            <FormGroup
              controlId="longitude"
              name="longitud"
              label="Longitud"
              value={coords.longitude}
              readOnly
            />
            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de creación"
              value={moment(createdAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de actualización"
              value={moment(updatedAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="user"
              name="user"
              label="Registro"
              value={user}
              readOnly
            />

            <Button
              type="button"
              variant="secondary"
              className="mt-3"
              onClick={handleGoBack}
            >
              Regresar
            </Button>
          </>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default PlaceDetailsScreen;
