import isEmpty from '../utils/is-empty';

const validateInputs = (data) => {
  const errors = {};

  if (
    data.deliveryMethod === process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1
  ) {
    if (!data.transport) {
      errors.transport = 'Servicio de delivery es requerido';
    }
    if (!data.zone) {
      errors.zone = 'Zona es requerido';
    }
    if (!data.address) {
      errors.address = 'Dirección es requerido';
    }
    if (data.addressAdditional.length > 200) {
      errors.addressAdditional =
        'Dirección adicional no debe superar los 200 carácteres';
    }
  }
  if (
    data.deliveryMethod === process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2
  ) {
    if (!data.place?._id) {
      errors.place = 'Lugar es requerido';
    }
  }
  if (!data.deliveryMethod) {
    errors.deliveryMethod = 'Método es requerido';
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export default validateInputs;
