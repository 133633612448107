import React, { useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';

const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/search/${keyword}`);
    } else {
      history.push('/');
    }
  };

  return (
    <Form onSubmit={submitHandler} inline className="d-flex flex-fill">
      <Col xs={6}>
        <Form.Control
          type="text"
          name="q"
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Buscar Productos..."
          className="mr-sm-2 ml-sm-5 me-2"
        />
      </Col>
      <Button type="submit" variant="outline-success" className="ms-2">
        Buscar
      </Button>
    </Form>
  );
};

export default SearchBox;
