import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Image, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { listCategoryDetails } from '../actions/categoryActions';
import { CATEGORY_DETAILS_RESET } from '../constants/categoryConstants';

const CategoryDetailsScreen = ({ match, history }) => {
  const categoryId = match.params.id;

  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [user, setUser] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const { loading, error, category } = categoryDetails;

  useEffect(() => {
    if (!userInfo || !(userInfo?.isCoAdmin || userInfo?.isAdmin)) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: CATEGORY_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isCoAdmin || userInfo?.isAdmin) {
      if (category?._id !== categoryId) {
        dispatch(listCategoryDetails(categoryId));
      } else {
        setName(category.name);
        setImage(category.image);
        setCreatedAt(category.createdAt);
        setUpdatedAt(category.updatedAt);
        setUser(category.user?.name);
      }
    }

    // eslint-disable-next-line
  }, [dispatch, history, categoryId, category]);

  const handleGoBack = (e) => {
    e.preventDefault();
    history.push('/admin/categorylist');
  };

  return (
    <React.Fragment>
      <Link to="#" className="btn btn-light" onClick={handleGoBack}>
        Regresar
      </Link>
      <FormContainer>
        <h1>Detalles de la Categoría</h1>
        {loading ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre"
              value={name}
              readOnly
            />

            <Col md={12} className="d-flex mt-2 flex-column">
              <Form.Label>Imagen</Form.Label>
              <div className='d-flex justify-content-center'>
                <Image
                  src={image}
                  alt={name}
                  fluid
                  width={250}
                  height={250}
                  onError={(ev) => {
                    ev.target.src = '/images/sample.jpg';
                  }}
                />
              </div>
            </Col>

            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de creación"
              value={moment(createdAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="updatedAt"
              name="updatedAt"
              label="Fecha de actualización"
              value={moment(updatedAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="user"
              name="user"
              label="Registro"
              value={user}
              readOnly
            />

            <Button
              type="button"
              variant="secondary"
              className="mt-3"
              onClick={handleGoBack}
            >
              Regresar
            </Button>
          </>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default CategoryDetailsScreen;
