import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormGroup from '../components/FormGroup';
import FormGroupPassword from '../components/FormGroupPassword';
import FormContainer from '../components/FormContainer';
import { register } from '../actions/userActions';

const RegisterScreen = ({ location, history }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState(null);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Las contraseñas no coinciden');
    } else {
      dispatch(register({ name, email, password, phone: `591${phone}` }));
    }
  };

  const handlePhone = (e) => {
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (pattern.test(e.target.value)) {
      setPhone(e.target.value);
    } else if (e.target.value !== '') {
      setPhone(phone);
    } else {
      setPhone('');
    }
  };

  const handleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleShowPassword2 = (e) => {
    setShowPassword2(!showPassword2);
  };

  return (
    <FormContainer>
      <h1>Registro</h1>
      {message && <Message variant="danger">{message}</Message>}
      {error?.error && <Message variant="danger">{error.error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <p>Los campos * son requeridos</p>
        <FormGroup
          controlId="name"
          name="name"
          label="Nombre *"
          placeholder="Ingrese nombre de usuario"
          value={name}
          onChange={(e) => setName(e.target.value)}
          isInvalid={Boolean(error?.name)}
          errorInvalid={error?.name}
          required
        />
        <FormGroup
          controlId="email"
          name="email"
          label="Email *"
          type="email"
          placeholder="Ingrese email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isInvalid={Boolean(error?.email)}
          errorInvalid={error?.email}
          required
        />

        <FormGroupPassword
          controlId="password"
          name="password"
          label="Contraseña *"
          placeholder="Ingrese contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isInvalid={Boolean(error?.password)}
          errorInvalid={error?.password}
          showPassword={showPassword1}
          handleShowPassword={handleShowPassword1}
          required
        />

        <FormGroupPassword
          controlId="confirmPassword"
          name="confirmPassword"
          label="Confimar Contraseña *"
          placeholder="Confirmar contraseña"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          isInvalid={Boolean(error?.password)}
          errorInvalid={error?.password}
          showPassword={showPassword2}
          handleShowPassword={handleShowPassword2}
          required
        />

        <Form.Group controlId="phone" className="mt-2">
          <Form.Label>Teléfono WhatsApp *</Form.Label>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">591</InputGroup.Text>
            <Form.Control
              isInvalid={Boolean(error?.phone)}
              placeholder={'Ingrese teléfono'}
              value={phone}
              required
              onChange={handlePhone}
            />
            {Boolean(error?.phone) && (
              <Form.Control.Feedback type="invalid">
                {error.phone}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>

        <Button type="submit" variant="primary" className="mt-3">
          Registrarse
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          Tienes una cuenta?{' '}
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            Iniciar Sesión
          </Link>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterScreen;
