import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { createPlace } from '../actions/placeActions';
import { PLACE_CREATE_RESET } from '../constants/placeConstants';

const PlaceCreateScreen = ({ location, history }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [coords, setCoords] = useState({
    latitude: '',
    longitude: '',
  });

  const dispatch = useDispatch();

  const placeCreate = useSelector((state) => state.placeCreate);
  const { loading, error: errorCreate, success: successCreate } = placeCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo?.isAdmin) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: PLACE_CREATE_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (successCreate) {
      history.push('/admin/placelist');
    }

    // eslint-disable-next-line
  }, [successCreate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm('¿Esta seguro de crear un nuevo lugar de entrega?')) {
      dispatch(
        createPlace({
          name,
          address,
          coords,
        })
      );
    }
  };

  const handleLatitude = (e) => {
    const value = e.target.value;
    const pattern = new RegExp(/^[-0-9.\b]+$/);

    if (pattern.test(value)) {
      setCoords({ ...coords, latitude: e.target.value });
    } else if (e.target.value !== '') {
      setCoords({ ...coords, latitude: coords.latitude });
    } else {
      setCoords({ ...coords, latitude: '' });
    }
  };

  const handleLongitude = (e) => {
    const value = e.target.value;
    const pattern = new RegExp(/^[-0-9.\b]+$/);

    if (pattern.test(value)) {
      setCoords({ ...coords, longitude: e.target.value });
    } else if (e.target.value !== '') {
      setCoords({ ...coords, longitude: coords.longitude });
    } else {
      setCoords({ ...coords, longitude: '' });
    }
  };

  return (
    <React.Fragment>
      <Link to="/admin/placelist" className="btn btn-light">
        Regresar
      </Link>
      <FormContainer>
        <h1>Crear Lugar de Entrega</h1>
        {errorCreate?.error && (
          <Message variant="danger">{errorCreate.error}</Message>
        )}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <p>Los campos * son requeridos</p>
          <FormGroup
            controlId="name"
            name="name"
            label="Nombre *"
            placeholder="Ingrese nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={Boolean(errorCreate?.name)}
            errorInvalid={errorCreate?.name}
            required
          />
          <FormGroup
            controlId="address"
            name="address"
            label="Dirección *"
            placeholder="Ingrese dirección Barrio, Av, Nº"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            isInvalid={Boolean(errorCreate?.address)}
            errorInvalid={errorCreate?.address}
            required
          />
          <FormGroup
            controlId="latitude"
            name="latitude"
            label="Latitud *"
            placeholder="Ingrese latitud"
            value={coords.latitude}
            onChange={handleLatitude}
            isInvalid={Boolean(errorCreate?.coords?.latitude)}
            errorInvalid={errorCreate?.coords?.latitude}
            required
          />
          <FormGroup
            controlId="address"
            name="address"
            label="Longitud *"
            placeholder="Ingrese longitud"
            value={coords.longitude}
            onChange={handleLongitude}
            isInvalid={Boolean(errorCreate?.coords?.longitude)}
            errorInvalid={errorCreate?.coords?.longitude}
            required
          />

          <Button type="submit" variant="primary" className="mt-3">
            Crear
          </Button>
        </Form>
      </FormContainer>
    </React.Fragment>
  );
};

export default PlaceCreateScreen;
