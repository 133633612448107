import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  categoryListReducer,
  categoryDetailsReducer,
  categoryDeleteReducer,
  categoryCreateReducer,
  categoryUpdateReducer,
} from './reducers/categoryReducers';
import {
  transportListReducer,
  transportDetailsReducer,
  transportDeleteReducer,
  transportCreateReducer,
  transportUpdateReducer,
  zoneListReducer,
  zoneDeleteReducer,
  zoneDetailsReducer,
  zoneCreateReducer,
  zoneUpdateReducer,
  addressListReducer,
  addressDeleteReducer,
  addressDetailsReducer,
  addressCreateReducer,
  addressUpdateReducer,
} from './reducers/transportReducers';
import {
  placeListReducer,
  placeDetailsReducer,
  placeDeleteReducer,
  placeCreateReducer,
  placeUpdateReducer,
} from './reducers/placeReducers';
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
} from './reducers/productReducers';
import { cartReducer } from './reducers/cartReducers';
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from './reducers/userReducers';
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
  orderCancelReducer,
  orderDeleteReducer,
} from './reducers/orderReducers';
import { configModalReducer } from './reducers/configReducer';

const reducer = combineReducers({
  categoryList: categoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryDelete: categoryDeleteReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
  transportList: transportListReducer,
  transportDetails: transportDetailsReducer,
  transportDelete: transportDeleteReducer,
  transportCreate: transportCreateReducer,
  transportUpdate: transportUpdateReducer,
  zoneList: zoneListReducer,
  zoneDetails: zoneDetailsReducer,
  zoneDelete: zoneDeleteReducer,
  zoneCreate: zoneCreateReducer,
  zoneUpdate: zoneUpdateReducer,
  addressList: addressListReducer,
  addressDetails: addressDetailsReducer,
  addressDelete: addressDeleteReducer,
  addressCreate: addressCreateReducer,
  addressUpdate: addressUpdateReducer,
  placeList: placeListReducer,
  placeDetails: placeDetailsReducer,
  placeDelete: placeDeleteReducer,
  placeCreate: placeCreateReducer,
  placeUpdate: placeUpdateReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderCancel: orderCancelReducer,
  orderDelete: orderDeleteReducer,
  config: configModalReducer,
});

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : [];

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {};

const configModalWelcomeFromStorage =
  localStorage.getItem('modalWelcome') === 'true' ||
  localStorage.getItem('modalWelcome') === 'false'
    ? JSON.parse(localStorage.getItem('modalWelcome'))
    : true;

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
  config: { modalWelcome: configModalWelcomeFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
