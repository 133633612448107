import {
  TRANSPORT_LIST_REQUEST,
  TRANSPORT_LIST_SUCCESS,
  TRANSPORT_LIST_FAIL,
  TRANSPORT_LIST_RESET,
  TRANSPORT_DETAILS_REQUEST,
  TRANSPORT_DETAILS_SUCCESS,
  TRANSPORT_DETAILS_FAIL,
  TRANSPORT_DETAILS_RESET,
  TRANSPORT_DELETE_REQUEST,
  TRANSPORT_DELETE_SUCCESS,
  TRANSPORT_DELETE_FAIL,
  TRANSPORT_DELETE_RESET,
  TRANSPORT_CREATE_REQUEST,
  TRANSPORT_CREATE_SUCCESS,
  TRANSPORT_CREATE_FAIL,
  TRANSPORT_CREATE_RESET,
  TRANSPORT_UPDATE_REQUEST,
  TRANSPORT_UPDATE_SUCCESS,
  TRANSPORT_UPDATE_FAIL,
  TRANSPORT_UPDATE_RESET,
  ZONE_LIST_REQUEST,
  ZONE_LIST_SUCCESS,
  ZONE_LIST_FAIL,
  ZONE_LIST_RESET,
  ZONE_DETAILS_REQUEST,
  ZONE_DETAILS_SUCCESS,
  ZONE_DETAILS_FAIL,
  ZONE_DETAILS_RESET,
  ZONE_CREATE_REQUEST,
  ZONE_CREATE_SUCCESS,
  ZONE_CREATE_FAIL,
  ZONE_CREATE_RESET,
  ZONE_DELETE_REQUEST,
  ZONE_DELETE_SUCCESS,
  ZONE_DELETE_FAIL,
  ZONE_DELETE_RESET,
  ZONE_UPDATE_REQUEST,
  ZONE_UPDATE_SUCCESS,
  ZONE_UPDATE_FAIL,
  ZONE_UPDATE_RESET,
  ADDRESS_LIST_REQUEST,
  ADDRESS_LIST_SUCCESS,
  ADDRESS_LIST_FAIL,
  ADDRESS_LIST_RESET,
  ADDRESS_DETAILS_REQUEST,
  ADDRESS_DETAILS_SUCCESS,
  ADDRESS_DETAILS_FAIL,
  ADDRESS_DETAILS_RESET,
  ADDRESS_CREATE_REQUEST,
  ADDRESS_CREATE_SUCCESS,
  ADDRESS_CREATE_FAIL,
  ADDRESS_CREATE_RESET,
  ADDRESS_DELETE_REQUEST,
  ADDRESS_DELETE_SUCCESS,
  ADDRESS_DELETE_FAIL,
  ADDRESS_DELETE_RESET,
  ADDRESS_UPDATE_REQUEST,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESS_UPDATE_FAIL,
  ADDRESS_UPDATE_RESET,
} from '../constants/transportConstants';

export const transportListReducer = (state = { transports: [] }, action) => {
  switch (action.type) {
    case TRANSPORT_LIST_REQUEST:
      return { loading: true, transports: [] };
    case TRANSPORT_LIST_SUCCESS:
      return {
        loading: false,
        transports: action.payload.transports,
      };
    case TRANSPORT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_LIST_RESET:
      return { transports: [] };
    default:
      return state;
  }
};

export const transportDetailsReducer = (state = { transport: {} }, action) => {
  switch (action.type) {
    case TRANSPORT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case TRANSPORT_DETAILS_SUCCESS:
      return { loading: false, transport: action.payload };
    case TRANSPORT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_DETAILS_RESET:
      return { transport: {} };
    default:
      return state;
  }
};

export const transportDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSPORT_DELETE_REQUEST:
      return { loading: true };
    case TRANSPORT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TRANSPORT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const transportCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSPORT_CREATE_REQUEST:
      return { loading: true };
    case TRANSPORT_CREATE_SUCCESS:
      return { loading: false, success: true, transport: action.payload };
    case TRANSPORT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const transportUpdateReducer = (state = { transport: {} }, action) => {
  switch (action.type) {
    case TRANSPORT_UPDATE_REQUEST:
      return { loading: true };
    case TRANSPORT_UPDATE_SUCCESS:
      return { loading: false, success: true, transport: action.payload };
    case TRANSPORT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TRANSPORT_UPDATE_RESET:
      return { transport: {} };
    default:
      return state;
  }
};

export const zoneListReducer = (state = { zones: [] }, action) => {
  switch (action.type) {
    case ZONE_LIST_REQUEST:
      return { loading: true, zones: [] };
    case ZONE_LIST_SUCCESS:
      return {
        loading: false,
        zones: action.payload.zones,
      };
    case ZONE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ZONE_LIST_RESET:
      return { zones: [] };
    default:
      return state;
  }
};

export const zoneDetailsReducer = (state = { zone: {} }, action) => {
  switch (action.type) {
    case ZONE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ZONE_DETAILS_SUCCESS:
      return { loading: false, zone: action.payload };
    case ZONE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ZONE_DETAILS_RESET:
      return { zone: {} };
    default:
      return state;
  }
};

export const zoneDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ZONE_DELETE_REQUEST:
      return { loading: true };
    case ZONE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ZONE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ZONE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const zoneCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ZONE_CREATE_REQUEST:
      return { loading: true };
    case ZONE_CREATE_SUCCESS:
      return { loading: false, success: true, zone: action.payload };
    case ZONE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ZONE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const zoneUpdateReducer = (state = { zone: {} }, action) => {
  switch (action.type) {
    case ZONE_UPDATE_REQUEST:
      return { loading: true };
    case ZONE_UPDATE_SUCCESS:
      return { loading: false, success: true, zone: action.payload };
    case ZONE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ZONE_UPDATE_RESET:
      return { zone: {} };
    default:
      return state;
  }
};

export const addressListReducer = (state = { addresses: [] }, action) => {
  switch (action.type) {
    case ADDRESS_LIST_REQUEST:
      return { loading: true, addresses: [] };
    case ADDRESS_LIST_SUCCESS:
      return {
        loading: false,
        addresses: action.payload.addresses,
      };
    case ADDRESS_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ADDRESS_LIST_RESET:
      return { addresses: [] };
    default:
      return state;
  }
};

export const addressDetailsReducer = (state = { address: {} }, action) => {
  switch (action.type) {
    case ADDRESS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ADDRESS_DETAILS_SUCCESS:
      return { loading: false, address: action.payload };
    case ADDRESS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ADDRESS_DETAILS_RESET:
      return { address: {} };
    default:
      return state;
  }
};

export const addressDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_DELETE_REQUEST:
      return { loading: true };
    case ADDRESS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADDRESS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ADDRESS_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const addressCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_CREATE_REQUEST:
      return { loading: true };
    case ADDRESS_CREATE_SUCCESS:
      return { loading: false, success: true, address: action.payload };
    case ADDRESS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ADDRESS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const addressUpdateReducer = (state = { address: {} }, action) => {
  switch (action.type) {
    case ADDRESS_UPDATE_REQUEST:
      return { loading: true };
    case ADDRESS_UPDATE_SUCCESS:
      return { loading: false, success: true, address: action.payload };
    case ADDRESS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ADDRESS_UPDATE_RESET:
      return { address: {} };
    default:
      return state;
  }
};
