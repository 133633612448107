import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { resetConfigData } from '../actions/configActions';
import FormContainer from '../components/FormContainer';
import Message from '../components/Message';

const ConfigScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const [displayMessage, setDisplayMessage] = useState(false);

  function handleResetData() {
    if (
      window.confirm(
        '¿Esta seguro de restablecer todos los datos de su configuración?'
      )
    ) {
      if (dispatch(resetConfigData())) {
        setDisplayMessage(true);
      }
    }
  }
  return (
    <FormContainer>
      <h2>Configuración</h2>
      {displayMessage && (
        <Message variant="success">Cambios realizados</Message>
      )}
      <p>Restablecer todos los datos de configuración</p>
      <Button type="button" variant="info" onClick={handleResetData}>
        <i className="fa fa-cog"></i> Restablecer
      </Button>
    </FormContainer>
  );
};

export default ConfigScreen;
