import React from 'react';
import { Form } from 'react-bootstrap';

const FormFileGroup = ({
  controlId,
  className,
  label,
  name,
  fileId,
  fileLabel,
  onChangeFile,
  isInvalid,
  errorInvalid,
  accept,
}) => {
  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>{label}</Form.Label>
      <Form.File
        id={fileId}
        label={fileLabel}
        custom
        name={name}
        onChange={onChangeFile}
        accept={accept}
      />
      {isInvalid && (
        <p className="text-danger" style={{ fontSize: 14 }}>
          {errorInvalid}
        </p>
      )}
    </Form.Group>
  );
};

FormFileGroup.defaultProps = {
  type: 'text',
  className: 'mt-2',
};

export default FormFileGroup;
