import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { createTransport } from '../actions/transportActions';
import { TRANSPORT_CREATE_RESET } from '../constants/transportConstants';

const CreateTransportScreen = ({ location, history }) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const dispatch = useDispatch();

  const transportCreate = useSelector((state) => state.transportCreate);
  const {
    loading,
    error: errorCreate,
    success: successCreate,
  } = transportCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo?.isAdmin) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: TRANSPORT_CREATE_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (successCreate) {
      history.push('/admin/transportlist');
    }

    // eslint-disable-next-line
  }, [successCreate]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      window.confirm('¿Esta seguro de crear un nuevo servicio de transporte?')
    ) {
      dispatch(
        createTransport({
          name,
          pohne: `591${phone}`,
        })
      );
    }
  };

  const handlePhone = (e) => {
    // reg solo number input
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (pattern.test(e.target.value)) {
      setPhone(e.target.value);
    } else if (e.target.value !== '') {
      setPhone(phone);
    } else {
      setPhone('');
    }
  };

  return (
    <React.Fragment>
      <Link to="/admin/transportlist" className="btn btn-light">
        Regresar
      </Link>
      <FormContainer>
        <h1>Crear Servicio de Transporte</h1>
        {errorCreate?.error && (
          <Message variant="danger">{errorCreate.error}</Message>
        )}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <p>Los campos * son requeridos</p>
          <FormGroup
            controlId="name"
            name="name"
            label="Nombre *"
            placeholder="Ingrese nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={Boolean(errorCreate?.name)}
            errorInvalid={errorCreate?.name}
            required
          />

          <Form.Group controlId="phone" className="mt-2">
            <Form.Label>Teléfono WhatsApp *</Form.Label>
            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">591</InputGroup.Text>
              <Form.Control
                isInvalid={Boolean(errorCreate?.phone)}
                placeholder={'Ingrese teléfono'}
                value={phone}
                required
                onChange={handlePhone}
              />
              {Boolean(errorCreate?.phone) && (
                <Form.Control.Feedback type="invalid">
                  {errorCreate.phone}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>

          <Button type="submit" variant="primary" className="mt-3">
            Crear
          </Button>
        </Form>
      </FormContainer>
    </React.Fragment>
  );
};

export default CreateTransportScreen;
