import axios from 'axios';
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
} from '../constants/productConstants';
import { handleErrorResponse } from './utils';

export const listProducts =
  (keyword = '', pageNumber = '', userId = '') =>
    async (dispatch) => {
      try {
        dispatch({ type: PRODUCT_LIST_REQUEST });

        const { data } = await axios.get(
          `/api/products?keyword=${keyword}&pageNumber=${pageNumber}&user=${userId}`
        );

        dispatch({
          type: PRODUCT_LIST_SUCCESS,
          payload: data,
        });
      } catch (err) {
        const error = handleErrorResponse(err, dispatch);
        dispatch({
          type: PRODUCT_LIST_FAIL,
          payload: error,
        });
      }
    };

export const listProductsByCategory =
  (pageNumber = '', categoryId) =>
    async (dispatch) => {
      try {
        dispatch({ type: PRODUCT_LIST_REQUEST });

        const { data } = await axios.get(
          `/api/products?category=${categoryId}&pageNumber=${pageNumber}`
        );

        dispatch({
          type: PRODUCT_LIST_SUCCESS,
          payload: data,
        });
      } catch (err) {
        const error = handleErrorResponse(err, dispatch);
        dispatch({
          type: PRODUCT_LIST_FAIL,
          payload: error,
        });
      }
    };

export const listProductsFromAdmin = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/products/admin`, config);

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload: error,
    });
  }
};

export const listProductsByUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/products/user`, config);

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload: error,
    });
  }
};

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/products/${id}`);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/products/${id}`, config);

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload: error,
    });
  }
};

export const createProduct =
  (formData, formFile) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(`/api/products`, formData, config);

      if (formFile.get('image') instanceof File && data?._id) {
        await dispatch(uploadFileServer(formFile, data._id));
      }

      dispatch({
        type: PRODUCT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: PRODUCT_CREATE_FAIL,
        payload: error,
      });
    }
  };

export const uploadFileServer =
  (fileData, productId) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        `/api/upload/products/${productId}/image`,
        fileData,
        config
      );

      return true;
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: PRODUCT_CREATE_FAIL,
        payload: error,
      });
    }
  };

export const updateProduct =
  (productId, product, formFile) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/products/${productId}`,
        product,
        config
      );

      if (formFile.get('image') instanceof File && data?._id) {
        await dispatch(uploadFileServer(formFile, data._id));
      }

      dispatch({
        type: PRODUCT_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: PRODUCT_UPDATE_FAIL,
        payload: error,
      });
    }
  };

export const createProductReview =
  (productId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(`/api/products/${productId}/reviews`, review, config);

      dispatch({
        type: PRODUCT_CREATE_REVIEW_SUCCESS,
      });
    } catch (err) {
      const error = handleErrorResponse(err, dispatch);
      dispatch({
        type: PRODUCT_CREATE_REVIEW_FAIL,
        payload: error,
      });
    }
  };

export const listTopProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TOP_REQUEST });

    const { data } = await axios.get(`/api/products/top`);

    dispatch({
      type: PRODUCT_TOP_SUCCESS,
      payload: data,
    });
  } catch (err) {
    const error = handleErrorResponse(err, dispatch);
    dispatch({
      type: PRODUCT_TOP_FAIL,
      payload: error,
    });
  }
};
