import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { listTransportDetails } from '../actions/transportActions';
import { TRANSPORT_DETAILS_RESET } from '../constants/transportConstants';

const TransportDetailsScreen = ({ match, history }) => {
  const transportId = match.params.id;

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [user, setUser] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const transportDetails = useSelector((state) => state.transportDetails);
  const { loading, error, transport } = transportDetails;

  useEffect(() => {
    if (!userInfo || !(userInfo?.isCoAdmin || userInfo?.isAdmin)) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: TRANSPORT_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isCoAdmin || userInfo?.isAdmin) {
      if (transport?._id !== transportId) {
        dispatch(listTransportDetails(transportId));
      } else {
        setName(transport.name);
        setPhone(transport.phone);
        setCreatedAt(transport.createdAt);
        setUpdatedAt(transport.updatedAt);
        setUser(transport.user?.name);
      }
    }

    // eslint-disable-next-line
  }, [dispatch, history, transportId, transport]);

  const handleGoBack = (e) => {
    e.preventDefault();
    history.push('/admin/transportlist');
  };

  return (
    <React.Fragment>
      <Link to="#" className="btn btn-light" onClick={handleGoBack}>
        Regresar
      </Link>
      <FormContainer>
        <h1>Detalles del Servicio de Transporte</h1>
        {loading ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre"
              value={name}
              readOnly
            />
            <FormGroup
              controlId="phone"
              name="phone"
              label="Teléfono"
              value={phone}
              readOnly
            />

            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de creación"
              value={moment(createdAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="createdAt"
              name="createdAt"
              label="Fecha de actualización"
              value={moment(updatedAt).format('DD/MM/YYYY [a las] HH:mm')}
              readOnly
            />
            <FormGroup
              controlId="user"
              name="user"
              label="Registro"
              value={user}
              readOnly
            />

            <Button
              type="button"
              variant="secondary"
              className="mt-3"
              onClick={handleGoBack}
            >
              Regresar
            </Button>
          </>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default TransportDetailsScreen;
