import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import TableHeader from '../components/TableHeader';
import { deleteZone, listZones } from '../actions/transportActions';

const ITEMS_PER_PAGE = 10;

const headers = [
  { name: 'NOMBRE', field: 'name', sortable: true },
  { name: 'REGISTRO', field: 'createdAt', sortable: true },
];

const ZoneListScreen = ({ match, history }) => {
  const transportId = match.params.id;

  const dispatch = useDispatch();

  const zoneList = useSelector((state) => state.zoneList);
  const { loading, error, zones } = zoneList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const zoneDelete = useSelector((state) => state.zoneDelete);
  const { success: successDelete, error: errorDelete } = zoneDelete;

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (userInfo && (userInfo.isCoAdmin || userInfo.isAdmin) && transportId) {
      dispatch(listZones(transportId));
    } else {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [dispatch, history, successDelete, transportId, userInfo]);

  const itemsData = useMemo(() => {
    if (transportId) {
      let computedItems = (zones && zones.map(z => ({
        ...z,
        createdAt: moment(z.createdAt).format('DD/MM/YYYY') || '',
      }))) || [];

      if (search) {
        computedItems = computedItems.filter((comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.createdAt.toLowerCase().includes(search.toLowerCase())
        );
      }

      setTotalItems(computedItems.length);

      // Sorting items
      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1;
        computedItems = computedItems.sort(
          (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
      }

      // Current Page slice
      return computedItems.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [zones, currentPage, search, sorting, transportId]);

  const deleteHandler = (zoneId) => {
    if (
      window.confirm(
        'Esta acción eliminará las direcciones de la zona. ¿Esta seguro de continuar?'
      )
    ) {
      dispatch(deleteZone(transportId, zoneId));
    }
  };

  return (
    <>
      <Link to="/admin/transportlist" className="btn btn-light">
        Regresar
      </Link>
      {loading ? (
        <Loader />
      ) : error?.error ? (
        <Message className="mt-3" variant="danger">
          {error.error}
        </Message>
      ) : (
        <React.Fragment>
          <Row className="align-items-center">
            <Col>
              <h1>Zonas</h1>
            </Col>
            {!error?.error && (
              <Col className="text-right">
                <Button
                  className="ms-auto"
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    history.push(
                      `/admin/transport/${transportId}/zonelist/create`
                    );
                  }}
                >
                  <i className="fas fa-plus"></i> Crear Zona
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Buscar Nombre, Fecha"
              />
            </Col>
          </Row>
          {errorDelete?.error && (
            <Message variant="danger" className="mt-3">
              {errorDelete?.error}
            </Message>
          )}
          <Table
            striped
            bordered
            hover
            responsive="sm"
            className="table-sm mt-2"
          >
            <TableHeader
              headers={headers}
              onSorting={(field, order) => {
                setSorting({ field, order });
              }}
            />
            <tbody>
              {itemsData.map((zone) => (
                <tr key={zone._id}>
                  <td>{zone.name}</td>
                  <td>{zone.createdAt}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" variant="secondary">
                        Opciones
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* Edit */}
                        <LinkContainer
                          to={`/admin/transport/${transportId}/zone/${zone._id}/edit`}
                          className="link a"
                        >
                          <Dropdown.Item>
                            <i className="fas fa-edit"></i> Editar
                          </Dropdown.Item>
                        </LinkContainer>
                        {/* Details */}
                        <LinkContainer
                          to={`/admin/transport/${transportId}/zone/${zone._id}/details`}
                        >
                          <Dropdown.Item>
                            <i className="fas fa-eye"></i> Detalles
                          </Dropdown.Item>
                        </LinkContainer>
                        {/* Addresses */}
                        <LinkContainer
                          to={`/admin/transport/${transportId}/zone/${zone._id}/addresslist`}
                        >
                          <Dropdown.Item>
                            <i className="fas fa-car"></i> Direcciones
                          </Dropdown.Item>
                        </LinkContainer>
                        {/* Delete */}
                        {userInfo.isAdmin && (
                          <Dropdown.Item
                            onClick={() => deleteHandler(zone._id)}
                          >
                            <i className="fas fa-trash"></i> Eliminar
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
};

export default ZoneListScreen;
