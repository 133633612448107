import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import { updateAddress, listAddressDetails } from '../actions/transportActions';
import {
  ADDRESS_DETAILS_RESET,
  ADDRESS_UPDATE_RESET,
} from '../constants/transportConstants';

const AddressEditScreen = ({ match, history }) => {
  const transportId = match.params.id;
  const zoneId = match.params.zoneId;
  const addressId = match.params.addressId;

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const addressDetails = useSelector((state) => state.addressDetails);
  const { loading, error, address } = addressDetails;

  const addressUpdate = useSelector((state) => state.addressUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = addressUpdate;

  useEffect(() => {
    if (
      (!userInfo || !userInfo?.isAdmin) &&
      !transportId &&
      !zoneId &&
      !addressId
    ) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: ADDRESS_UPDATE_RESET });
      dispatch({ type: ADDRESS_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isAdmin) {
      if (successUpdate) {
        history.push(
          `/admin/transport/${transportId}/zone/${zoneId}/addresslist`
        );
      } else {
        if (address?._id !== addressId) {
          dispatch(listAddressDetails(transportId, zoneId, addressId));
        } else {
          setName(address.name);
          setPrice(address.price);
        }
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    history,
    transportId,
    zoneId,
    address,
    successUpdate,
    addressId,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (window.confirm('¿Esta seguro de actualizar los datos?')) {
      dispatch(
        updateAddress(transportId, zoneId, addressId, {
          name,
          price,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Link
        to={`/admin/transport/${transportId}/zone/${zoneId}/addresslist`}
        className="btn btn-light"
      >
        Regresar
      </Link>
      <FormContainer>
        <h1>Actualizar Dirección</h1>
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate.error}</Message>
        )}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre *"
              placeholder="Ingrese nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />
            <FormGroup
              controlId="price"
              name="price"
              label="Precio *"
              placeholder="Ingrese precio de pasaje"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default AddressEditScreen;
