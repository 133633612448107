import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import CheckoutSteps from '../components/CheckoutSteps';
import FormGroup from '../components/FormGroup';
import FormSelect from '../components/FormSelect';
import { saveShippingAddress } from '../actions/cartActions';
import { listPlaces } from '../actions/placeActions';
import { listTransports } from '../actions/transportActions';
import validateShipping from '../validations/shippingValidation';

const deliveryListOptions = [
  {
    label: process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1,
    value: process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1,
  },
  {
    label: process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2,
    value: process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2,
  },
];

const ShippingScreen = ({ history }) => {
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [place, setPlace] = useState({
    _id: '',
    name: '',
    address: '',
    coords: {},
  });

  const [placeListOptions, setPlaceListOptions] = useState([]);
  const [transportListOptions, setTransportListOptions] = useState([
    {
      label: 'Seleccione una opción',
      value: '',
    },
  ]);
  const [zoneListOptions, setZoneListOptions] = useState([
    {
      label: 'Seleccione una opción',
      value: '',
    },
  ]);
  const [addressListOptions, setAddressListOptions] = useState([
    {
      label: 'Seleccione una opción',
      value: '',
    },
  ]);
  const [transport, setTransport] = useState('');
  const [zone, setZone] = useState('');
  const [address, setAddress] = useState('');
  const [addressAdditional, setAddressAdditional] = useState('');
  const [errors, setErrors] = useState({
    transport: '',
    zone: '',
    address: '',
    addressAdditional: '',
    deliveryMethod: '',
    place: '',
  });

  const dispatch = useDispatch();

  const placeList = useSelector((state) => state.placeList);
  const { loading: loadingPlace, places, error: errorPlace } = placeList;

  const transportList = useSelector((state) => state.transportList);
  const {
    loading: loadingTransport,
    transports,
    error: errorTransport,
  } = transportList;

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  useEffect(() => {
    if (
      shippingAddress.deliveryMethod ===
      process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2
    ) {
      setDeliveryMethod(shippingAddress.deliveryMethod);
      setPlace(
        shippingAddress.place || {
          _id: '',
          name: '',
          address: '',
          coords: {},
        }
      );
    }

    dispatch(listPlaces());
    dispatch(listTransports());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loadingPlace && places?.length > 0) {
      const items = places.map((place) => ({
        label: place.name,
        value: place._id,
        address: place.address,
      }));

      setPlaceListOptions(items);
    }
    // eslint-disable-next-line
  }, [loadingPlace, places]);

  useEffect(() => {
    if (!loadingTransport && transports?.length > 0) {
      const items = transports.map((place) => ({
        label: place.name,
        value: place._id,
      }));

      setTransportListOptions([
        {
          label: 'Seleccione una opción',
          value: '',
        },
        ...items,
      ]);

      // Load option 1 from local storage
      if (
        shippingAddress.deliveryMethod ===
        process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1
      ) {
        setDeliveryMethod(shippingAddress.deliveryMethod);

        // Load transport
        const transportFounded = transports.find(
          (t) => t._id === shippingAddress.transport._id
        );
        if (transportFounded) {
          setTransport(transportFounded._id);

          const items = transportFounded.zones.map((z) => ({
            label: z.name,
            value: z._id,
          }));
          // Load zones options
          setZoneListOptions([
            {
              label: 'Seleccione una opción',
              value: '',
            },
            ...items,
          ]);

          // Load zone
          const zoneFounded = transportFounded.zones.find(
            (z) => z._id === shippingAddress.transport.zone._id
          );
          if (zoneFounded) {
            setZone(zoneFounded._id);

            // Load addresses options
            const items = zoneFounded.addresses.map((a) => ({
              label: a.name,
              value: a._id,
            }));

            // Load addresses options
            setAddressListOptions([
              {
                label: 'Seleccione una opción',
                value: '',
              },
              ...items,
            ]);

            // Load address
            const addressFounded = zoneFounded.addresses.find(
              (z) => z._id === shippingAddress.transport.zone.address._id
            );
            if (addressFounded) {
              setAddress(addressFounded._id);
              // Load address additional 
              setAddressAdditional(
                shippingAddress.transport.zone.address.addressAdditional
              );
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [loadingTransport, transports]);

  const submitHandler = (e) => {
    e.preventDefault();

    // Validations
    const { isValid, errors } = validateShipping({
      transport,
      zone,
      address,
      addressAdditional,
      deliveryMethod,
      place,
    });
    if (!isValid) {
      return setErrors(errors);
    }

    if (
      deliveryMethod === process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1
    ) {
      // Find transport, zone, address
      const transportItem = transports.find((t) => t._id === transport);
      const zoneItem = transportItem.zones.find((z) => z._id === zone);
      const addressItem = zoneItem.addresses.find((a) => a._id === address);

      dispatch(
        saveShippingAddress({
          deliveryMethod,
          place,
          transport: {
            _id: transportItem._id,
            name: transportItem.name,
            zone: {
              _id: zoneItem._id,
              name: zoneItem.name,
              address: {
                _id: addressItem._id,
                name: addressItem.name,
                price: addressItem.price,
                addressAdditional,
              },
            },
          },
        })
      );
    }

    if (
      deliveryMethod === process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2
    ) {
      dispatch(
        saveShippingAddress({
          deliveryMethod,
          place,
          transport: {},
        })
      );
    }

    history.push('/payment');
  };

  const handleDeliveryMethod = (e) => {
    const value = e.target.value;
    setDeliveryMethod(value);

    if (value === process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1) {
      setPlace({
        _id: '',
        name: '',
        address: '',
        coords: '',
      });
    }

    if (value === process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2) {
      setTransport('');
      setZone('');
      setAddress('');
      setAddressAdditional('');
    }
  };

  const handlePlace = (e) => {
    const value = e.target.value;

    const placeItem = places.find((item) => item._id === value);

    setPlace({
      _id: placeItem._id,
      name: placeItem.name,
      address: placeItem.address,
      coords: placeItem.coords,
    });
  };

  const handleTransport = (e) => {
    setTransport(e.target.value);

    // Find transport
    const transportItem = transports.find((t) => t._id === e.target.value);

    if (transportItem) {
      const items = transportItem.zones.map((z) => ({
        label: z.name,
        value: z._id,
      }));
      // Load zones
      setZoneListOptions([
        {
          label: 'Seleccione una opción',
          value: '',
        },
        ...items,
      ]);
    } else {
      // Load zones
      setZoneListOptions([
        {
          label: 'Seleccione una opción',
          value: '',
        },
      ]);
    }

    setZone('');
    setAddress('');
  };

  const handleZone = (e) => {
    setZone(e.target.value);

    // Find transport
    const transportItem = transports.find((t) => t._id === transport);

    // Find zone
    const zoneItem = transportItem.zones.find((t) => t._id === e.target.value);

    if (zoneItem) {
      const items = zoneItem.addresses.map((a) => ({
        label: a.name,
        value: a._id,
      }));

      // Load addresses
      setAddressListOptions([
        {
          label: 'Seleccione una opción',
          value: '',
        },
        ...items,
      ]);
    } else {
      // Reset addresses
      setAddressListOptions([
        {
          label: 'Seleccione una opción',
          value: '',
        },
      ]);
    }

    setAddress('');
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Envio</h1>
      {errorTransport?.error && (
        <Message variant="danger">{errorTransport?.error}</Message>
      )}
      <Form onSubmit={submitHandler}>
        <Form.Group className="mt-2">
          <Form.Label as="legend">Seleccione Método</Form.Label>
          {deliveryListOptions.map((delivery, i) => {
            return (
              <Form.Check
                className="mt-2"
                key={i}
                type="radio"
                label={delivery.label}
                id="deliveryMethod"
                name="deliveryMethod"
                value={delivery.value}
                checked={delivery.value === deliveryMethod}
                onChange={handleDeliveryMethod}
              />
            );
          })}
        </Form.Group>
        {deliveryMethod ===
          process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_1 && (
          <>
            <FormSelect
              controlId="transport"
              name="transport"
              label="Servicio de transporte"
              value={transport}
              onChange={handleTransport}
              isInvalid={Boolean(errors?.transport)}
              errorInvalid={errors?.transport}
              options={transportListOptions}
            />
            <FormSelect
              controlId="zone"
              name="zone"
              label="Zona"
              value={zone}
              onChange={handleZone}
              isInvalid={Boolean(errors?.zone)}
              errorInvalid={errors?.zone}
              options={zoneListOptions}
            />
            <FormSelect
              controlId="address"
              name="address"
              label="Dirección"
              value={address}
              onChange={handleAddress}
              isInvalid={Boolean(errors?.address)}
              errorInvalid={errors?.address}
              options={addressListOptions}
            />
            <FormGroup
              controlId="description"
              name="addressAdditional"
              label="Dirección adicional"
              placeholder="Ingrese información adicional nº de casa, referencias"
              value={addressAdditional}
              onChange={(e) => setAddressAdditional(e.target.value)}
              isInvalid={Boolean(errors?.addressAdditional)}
              errorInvalid={errors?.addressAdditional}
            />
          </>
        )}
        {deliveryMethod ===
          process.env.REACT_APP_DELIVERY_METHOD_NAME_OPTION_2 && (
          <div className="mt-2">
            <p>Seleccione un destino de entrega</p>
            {errorPlace?.error && (
              <Message variant="danger" className="mt-3">
                {errorPlace.error}
              </Message>
            )}
            {placeListOptions.map((placeItem, i) => {
              return (
                <div key={i}>
                  <Form.Check
                    className="mt-2"
                    type="radio"
                    label={placeItem.label}
                    id="place"
                    name="place"
                    isInvalid={Boolean(errors.place)}
                    value={placeItem.value}
                    checked={placeItem.value === place._id}
                    onChange={(e) => handlePlace(e, placeItem)}
                  />
                  <div>
                    <small className="mx-4 text-muted">
                      {placeItem.address}
                    </small>
                  </div>
                </div>
              );
            })}
            {errors.place && (
              <div>
                <small className="text-danger">{errors.place}</small>
              </div>
            )}
          </div>
        )}

        <Button type="submit" variant="primary" className="mt-3">
          Continuar
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ShippingScreen;
