import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import FormFileGroup from '../components/FormFileGroup';
import isImageInput from '../validations/imageInput';
import { imageUpload } from '../config/constants';
import {
  updateCategory,
  listCategoryDetails,
} from '../actions/categoryActions';
import {
  CATEGORY_DETAILS_RESET,
  CATEGORY_UPDATE_RESET,
} from '../constants/categoryConstants';

const CategoryEditScreen = ({ match, history }) => {
  const categoryId = match.params.id;

  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [file, setFile] = useState('');
  const [filePreview, setFilePreview] = useState(false);
  const [errorImage, setErrorImage] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const { loading, error, category } = categoryDetails;

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = categoryUpdate;

  useEffect(() => {
    if (!userInfo || !userInfo?.isAdmin) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: CATEGORY_UPDATE_RESET });
      dispatch({ type: CATEGORY_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isAdmin) {
      if (successUpdate) {
        history.push('/admin/categorylist');
      } else {
        if (category?._id !== categoryId) {
          dispatch(listCategoryDetails(categoryId));
        } else {
          setName(category.name);
          setImage(category.image);
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, categoryId, category, successUpdate]);

  const uploadFileHandler = async (e) => {
    const fileLoaded = e.target.files[0];

    // Validate input image
    if (fileLoaded) {
      // Compress image
      var options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      };

      imageCompression(fileLoaded, options)
        .then(function (compressedFile) {
          const { success, message } = isImageInput(compressedFile);
          if (!success) {
            setFilePreview(false);
            setFile('');
            return setErrorImage(message);
          }

          setFilePreview(URL.createObjectURL(compressedFile));
          setFile(compressedFile);
          setErrorImage('');
        })
        .catch(function (error) {
          setFilePreview(false);
          setFile('');
        });
    } else {
      setFilePreview(false);
      setFile('');
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formFile = new FormData();
    formFile.append('image', file);

    if (window.confirm('¿Esta seguro de actualizar los datos?')) {
      dispatch(
        updateCategory(categoryId, {
          name,
        }, formFile)
      );
    }
  };

  const imgSrc = filePreview ? filePreview : image ? image : imageUpload;

  return (
    <React.Fragment>
      <Link to="/admin/categorylist" className="btn btn-light">
        Regresar
      </Link>
      <FormContainer>
        <h1>Actualizar Categoría</h1>
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate.error}</Message>
        )}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre *"
              placeholder="Ingrese nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />

            <Col md={12} className="d-flex justify-content-center mt-2">
              <Image src={imgSrc} alt={name} fluid width={230} height={230} />
            </Col>
            <div className="text-center">
              <small>Las dimensiones recomendadas son 500x500 px</small>
            </div>
            <FormFileGroup
              controlId="image"
              name="image"
              label="Imagen"
              fileId="image-file"
              fileLabel="Seleccionar Archivo"
              onChangeFile={uploadFileHandler}
              isInvalid={Boolean(errorUpdate?.file) || Boolean(errorImage)}
              errorInvalid={errorUpdate?.file || errorImage}
              accept="image/*"
            />

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default CategoryEditScreen;
