import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const FormGroup = ({
  controlId,
  className,
  name,
  label,
  placeholder,
  value,
  onChange,
  isInvalid,
  errorInvalid,
  readOnly,
  disabled,
  required,
  showPassword,
  handleShowPassword,
}) => {
  return (
    <Form.Group controlId={controlId} className={className}>
      <Form.Label>{label}</Form.Label>

      <InputGroup>
        <Form.Control
          disabled={disabled}
          isInvalid={isInvalid}
          name={name}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          required={required}
          onChange={onChange}
          readOnly={readOnly}
        />

        <InputGroup.Text id="inputGroupPrepend" onClick={handleShowPassword}>
          <i className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
        </InputGroup.Text>
        {isInvalid && (
          <Form.Control.Feedback type="invalid">
            {errorInvalid}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
};

FormGroup.defaultProps = {
  className: 'mt-2',
  showPass: false,
};

export default FormGroup;
