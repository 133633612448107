import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import FormGroup from '../components/FormGroup';
import {
  updateTransport,
  listTransportDetails,
} from '../actions/transportActions';
import {
  TRANSPORT_DETAILS_RESET,
  TRANSPORT_UPDATE_RESET,
} from '../constants/transportConstants';

const TransportEditScreen = ({ match, history }) => {
  const transportId = match.params.id;

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const transportDetails = useSelector((state) => state.transportDetails);
  const { loading, error, transport } = transportDetails;

  const transportUpdate = useSelector((state) => state.transportUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = transportUpdate;

  useEffect(() => {
    if (!userInfo || !userInfo?.isAdmin) {
      return history.push('/login');
    }

    return () => {
      dispatch({ type: TRANSPORT_UPDATE_RESET });
      dispatch({ type: TRANSPORT_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userInfo?.isAdmin) {
      if (successUpdate) {
        history.push('/admin/transportlist');
      } else {
        if (transport?._id !== transportId) {
          dispatch(listTransportDetails(transportId));
        } else {
          setName(transport.name);
          setPhone(transport.phone);
        }
      }
    }
    // eslint-disable-next-line
  }, [dispatch, history, transportId, transport, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (window.confirm('¿Esta seguro de actualizar los datos?')) {
      dispatch(
        updateTransport(transportId, {
          name,
          phone,
        })
      );
    }
  };

  const handlePhone = (e) => {
    // reg solo number input
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (pattern.test(e.target.value)) {
      setPhone(e.target.value);
    } else if (e.target.value !== '') {
      setPhone(phone);
    } else {
      setPhone('');
    }
  };

  return (
    <React.Fragment>
      <Link to="/admin/transportlist" className="btn btn-light">
        Regresar
      </Link>
      <FormContainer>
        <h1>Actualizar Servicio de Transporte</h1>
        {errorUpdate?.error && (
          <Message variant="danger">{errorUpdate.error}</Message>
        )}
        {loading || loadingUpdate ? (
          <Loader />
        ) : error?.error ? (
          <Message variant="danger">{error.error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <p>Los campos * son requeridos</p>
            <FormGroup
              controlId="name"
              name="name"
              label="Nombre *"
              placeholder="Ingrese nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={Boolean(errorUpdate?.name)}
              errorInvalid={errorUpdate?.name}
              required
            />

            <Form.Group controlId="phone" className="mt-2">
              <Form.Label>Teléfono WhatsApp *</Form.Label>
              <InputGroup>
                <Form.Control
                  isInvalid={Boolean(errorUpdate?.phone)}
                  placeholder={'Ingrese teléfono'}
                  value={phone}
                  required
                  onChange={handlePhone}
                />
                {Boolean(errorUpdate?.phone) && (
                  <Form.Control.Feedback type="invalid">
                    {errorUpdate.phone}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>

            <Button type="submit" variant="primary" className="mt-3">
              Actualizar
            </Button>
          </Form>
        )}
      </FormContainer>
    </React.Fragment>
  );
};

export default TransportEditScreen;
