import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const styles = {
  text: {
    textAlign: 'justify',
    textJustify: 'inter-word',
  },
};

const WelcomeModal = ({ show, onHideModal }) => {
  const [notRememberModal, setNotRememberModal] = useState(false);

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Bienvenido</Modal.Title>
        <Button
          className="btn-sm"
          variant="outline-light"
          onClick={() => onHideModal(notRememberModal)}
        >
          <i className="fa fa-times"></i>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex justify-content-center">
          <div
            style={{
              height: 100,
            }}
          >
            <img
              src="/images/logo.png"
              className="img-fluid mh-100"
              alt="logo-emborozu-ecologico"
            ></img>
          </div>
        </div>
        <h2 className="text-primary text-center">Emborozú Ecológico</h2>
        <p style={styles.text}>
          Es una herramienta de comercialización del proyecto "dinamización del
          emprendimiento productivo agroecológico con equidad de género
          vinculado al consumidor" desarrollada por el CETHA Emborozú financiado
          por MISEREOR con la finalidad apoyar los emprendimientos agroecológicos
          de productores de comunidades del municipio de Padcaya y de otros
          municipios a través de alianzas con organizaciones de productores
          ecológicos que son jóvenes y sus familias que están relacionadas con
          el Instituto Tecnológico Emborozú, Educación de Personas Jóvenes y Adultas,
          EDUPER, así también asociaciones como: Asociación Bien te fue,
          SPG-APAT; espacios de comercialización Tienda Alma Tierra, vinculado a
          consumidores como Comida Consciente e instituciones de respaldo como
          son los miembros de la Plataforma de suelos regional Tarija y el
          Movimiento Agroecológico Boliviano regional Tarija.
        </p>
        <p style={styles.text}>
          Bienvenido a una alternativa de promoción y difusión de la producción
          ecológica del campo a la mesa!!!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Form.Group controlId="notRememberModal" className="mt-2">
          <Form.Check
            type="checkbox"
            label="No recordar este mensaje"
            onChange={(e) => setNotRememberModal(e.target.checked)}
          />
        </Form.Group>
        <Button onClick={() => onHideModal(notRememberModal)}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal;
