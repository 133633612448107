import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from 'react-bootstrap';
import moment from 'moment';

import Rating from '../components/Rating';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Meta from '../components/Meta';
import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions';
import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_DETAILS_RESET,
} from '../constants/productConstants';
import { addDecimals } from '../utils/utils';

const styles = {
  discountOverlay: {
    position: "absolute",
    height: 100,
  },
  containerLogo: {
    position: "absolute",
    height: 70,
    marginTop: -70,
    backgroundColor: "#fff",
  },
  nameLogoContainer: {
    marginLeft: 10
  }
}

const ProductScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate;

  useEffect(() => {
    if (successProductReview) {
      setRating(0);
      setComment('');
    }
    if (!product?._id || product?._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id));
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }

    // eslint-disable-next-line
  }, [dispatch, match, successProductReview]);

  useEffect(() => {
    return () => {
      dispatch({ type: PRODUCT_DETAILS_RESET });
    };
    // eslint-disable-next-line
  }, []);

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    );
  };

  const navigateProfileUser = (e, userId) => {
    e.preventDefault();
    history.push(`/user/${userId}/profile`, {
      id: userId,
    });
  };

  return (
    <>
      <Meta title={product?.name} description={product?.description} />
      <Link
        className="btn btn-light my-3"
        to="#"
        onClick={() => history.goBack()}
      >
        Regresar
      </Link>
      {loading ? (
        <Loader />
      ) : error?.error ? (
        <Message className="mt-3" variant="danger">
          {error.error}
        </Message>
      ) : (
        <>
          <Row>
            <Col md={6}>
              {product.hasDiscount &&
                <Image
                  style={styles.discountOverlay}
                  src="/images/product-discount.png"
                  alt="producto con descuento"
                />
              }
              <Col>
                <Image
                  src={product.image}
                  alt={product.name}
                  fluid
                  onError={(ev) => {
                    ev.target.src = '/images/sample.jpg';
                  }}
                />
              </Col>
              <div style={styles.containerLogo}>
                <Image
                  style={{
                    height: "100%",
                  }}
                  src={product.user?.image || '/images/sample.jpg'}
                  alt={product.user?.name}
                  onError={(ev) => {
                    ev.target.src = '/images/sample.jpg';
                  }}
                />
              </div>

            </Col>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>{product.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`${product.numReviews} reseñas`}
                  />
                </ListGroup.Item>
                <ListGroup.Item>
                  Descripción: {product.description}
                </ListGroup.Item>
                {product.benefit && (
                  <ListGroup.Item>
                    Beneficios: {product.benefit}
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  Categoría: {product.category?.name || 'n/a'}
                </ListGroup.Item>
                {product.dateOfProduction && (
                  <ListGroup.Item>
                    Meses de disponibilidad: {product.dateOfProduction}
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  Proveedor:{' '}
                  {product.user ? (
                    <a
                      href="/#"
                      onClick={(e) => navigateProfileUser(e, product.user._id)}
                    >
                      {product.user.name}
                    </a>
                  ) : (
                    'n/a'
                  )}
                </ListGroup.Item>
              </ListGroup>
              <Card className="mt-3">
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>Precio:</Col>
                      <Col>
                        <strong>Bs. {addDecimals(product.price)}</strong>
                        {product.hasDiscount && <span style={{ fontSize: 12 }}><br />Aplicado con descuento de {product.discountPercentage}%</span>}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Estado:</Col>
                      <Col>
                        {product.countInStock > 0
                          ? 'En Stock'
                          : 'Fuera de Stock'}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Cantidad</Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item>
                    <Button
                      onClick={addToCartHandler}
                      className="btn-block"
                      type="button"
                      disabled={product.countInStock === 0}
                    >
                      Añadir al carrito
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h2>Reseñas</h2>
              {product.reviews.length === 0 && <Message>Sin reseñas</Message>}
              <ListGroup variant="flush">
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{moment(review.createdAt).format('DD/MM/YYYY')}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>Escribir una reseña</h2>
                  {successProductReview && (
                    <Message variant="success">
                      Reseña enviada con éxito
                    </Message>
                  )}
                  {loadingProductReview && <Loader />}
                  {errorProductReview?.error && (
                    <Message variant="danger">
                      {errorProductReview?.error}
                    </Message>
                  )}
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="rating" className="mt-2">
                        <Form.Label>Valoración</Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="">Seleccionar...</option>
                          <option value="1">1 - Pobre</option>
                          <option value="2">2 - Correcto</option>
                          <option value="3">3 - Bueno</option>
                          <option value="4">4 - Muy Bueno</option>
                          <option value="5">5 - Excelente</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="comment" className="mt-2">
                        <Form.Label>Comentario</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        className="mt-3"
                        disabled={loadingProductReview}
                        type="submit"
                        variant="primary"
                      >
                        Enviar
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Por favor <Link to="/login">Inicia Sesión</Link> para
                      escribir una reseña{' '}
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductScreen;
