// file, 1mb
const isImageInput = (file) => {
  const res = {};
  const allowTypes = ['image/jpeg', 'image/png'];

  // Check extension image
  if (!allowTypes.includes(file.type)) {
    res.success = false;
    res.message = 'Solo se admiten archivos .jpeg y .png';

    return res;
  }

  // Check image size less than maxSize mb
  if (file.size > 1024 * 1024 * process.env.REACT_APP_IMAGE_MAX_SIZE) {
    res.success = false;
    res.message = 'La imagen es demasiada grande';

    return res;
  }

  res.success = true;
  res.message = '';

  return res;
};

export default isImageInput;
