import {
  MODAL_DISPLAY_WELCOME,
  MODAL_HIDE_WELCOME,
  RESET_MODAL_WELCOME,
} from '../constants/configConstants';

export const setModalWelcome = (state) => (dispatch) => {
  if (state) {
    return dispatch({
      type: MODAL_DISPLAY_WELCOME,
      payload: state,
    });
  }
  dispatch({
    type: MODAL_HIDE_WELCOME,
    payload: state,
  });
};

export const setRememberModalWelcome = (state) => (dispatch, getState) => {
  if (state) {
    dispatch({
      type: MODAL_DISPLAY_WELCOME,
      payload: state,
    });

    return localStorage.setItem(
      'modalWelcome',
      JSON.stringify(getState().config.modalWelcome)
    );
  }
  dispatch({
    type: MODAL_HIDE_WELCOME,
    payload: state,
  });

  localStorage.setItem(
    'modalWelcome',
    JSON.stringify(getState().config.modalWelcome)
  );
};

export const resetConfigData = () => (dispatch) => {
  dispatch({
    type: RESET_MODAL_WELCOME,
  });
  localStorage.setItem('modalWelcome', JSON.stringify(true));
  return true;
};
