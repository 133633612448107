import React, { useEffect, useState, useMemo } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Message from '../components/Message';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';
import Search from '../components/Search';
import TableHeader from '../components/TableHeader';
import { listUsers, deleteUser } from '../actions/userActions';

const ITEMS_PER_PAGE = 10;

const headers = [
  { name: 'NOMBRE', field: 'name', sortable: true },
  { name: 'EMAIL', field: 'email', sortable: true },
  { name: 'ROL', field: 'role', sortable: true },
  { name: 'REGISTRO', field: 'createdAt', sortable: true },
];

const UserListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete, error: errorDelete } = userDelete;

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (userInfo && (userInfo.isCoAdmin || userInfo.isAdmin)) {
      dispatch(listUsers());
    } else {
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [dispatch, history, successDelete, userInfo]);

  const itemsData = useMemo(() => {
    let computedItems =
      (users &&
        users.map((user) => ({
          ...user,
          role: user.isAdmin
            ? 'Administrador'
            : user.isCoAdmin
              ? 'Coadministrador'
              : user.isEntrepreneur
                ? 'Emprendedor'
                : 'Usuario',
          createdAt: moment(user.createdAt).format('DD/MM/YYYY') || '',
        }))) ||
      [];

    if (search) {
      computedItems = computedItems.filter(
        (comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase()) ||
          comment.createdAt.toLowerCase().includes(search.toLowerCase()) ||
          comment.role.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedItems.length);

    // Sorting items
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedItems = computedItems.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedItems.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [users, currentPage, search, sorting]);

  const deleteHandler = (id) => {
    if (
      window.confirm(
        'Esta acción eliminará el usuario y sus productos registrados. ¿Esta seguro de continuar?'
      )
    ) {
      dispatch(deleteUser(id));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <React.Fragment>
          <Row className="align-items-center">
            <Col>
              <h1>Usuarios</h1>
            </Col>
            {!error && (
              <Col className="text-right">
                <Button
                  className="ms-auto"
                  type="button"
                  variant="primary"
                  onClick={(e) => {
                    history.push('/admin/userlist/create');
                  }}
                >
                  <i className="fas fa-plus"></i> Crear Usuario
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Buscar Nombre, Email, Rol, Fecha"
              />
            </Col>
          </Row>
          {errorDelete && (
            <Message variant="danger" className="mt-3">
              {errorDelete}
            </Message>
          )}
          <Table
            striped
            bordered
            hover
            responsive="sm"
            className="table-sm mt-2"
          >
            <TableHeader
              headers={headers}
              onSorting={(field, order) => {
                setSorting({ field, order });
              }}
            />
            <tbody>
              {itemsData.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>{user.role}</td>
                  <td>{user.createdAt}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic" variant="secondary">
                        Opciones
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* Edit */}
                        <LinkContainer
                          to={`/admin/user/${user._id}/edit`}
                          className="link a"
                        >
                          <Dropdown.Item
                            disabled={userInfo?.isCoAdmin && user.isAdmin}
                          >
                            <i className="fas fa-edit"></i> Editar
                          </Dropdown.Item>
                        </LinkContainer>
                        {/* Details */}
                        <LinkContainer to={`/admin/user/${user._id}/details`}>
                          <Dropdown.Item>
                            <i className="fas fa-eye"></i> Detalles
                          </Dropdown.Item>
                        </LinkContainer>
                        {/* Edit password */}
                        <LinkContainer
                          to={`/admin/user/${user._id}/editpassword`}
                          disabled={user.isAdmin && user._id !== userInfo._id}
                        >
                          <Dropdown.Item>
                            <i className="fas fa-key"></i> Editar Contraseña
                          </Dropdown.Item>
                        </LinkContainer>
                        {/* Delete */}
                        {userInfo.isAdmin && (
                          <Dropdown.Item
                            onClick={() => deleteHandler(user._id)}
                            disabled={user.isAdmin}
                          >
                            <i className="fas fa-trash"></i> Eliminar
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row>
            <Col>
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
};

export default UserListScreen;
